<template>
    <div class="wrapper">
        <div class="orders">
            <div class="no-order" v-show='status == 0'>
                <div class="banner"></div>
                    <h2 class="title">
                        Loan History
                    </h2>
                    <p class="caption">
                        No order history go get your first loan
                    </p>
                    <div class="btn-container">
                        <router-link class="btn-submit" to="/" >Apply</router-link>
                    </div>
            </div>
            <div class="order-element" v-show='status == 1' v-for="(element,ind) in orders.list" :key="ind" :class="{'valid': ['0', '5', '10', '30', '40' ,'50'].indexOf(element.orderState) != -1, 'invalid': ['0', '5', '10', '30', '40' ,'50'].indexOf(element.orderState) == -1}">
                    <div class="tag" :class="{'valid': ['0', '5', '10', '30', '40' ,'50'].indexOf(element.orderState) != -1, 'invalid': ['0', '5', '10', '30', '40' ,'50'].indexOf(element.orderState) == -1}">
                        <span class="order_state" v-text="getStateText(element.orderState)"></span>
                    </div>
                    <div class="order-id" v-text="`ID: ${element.orderNo}`"></div>                
                    <ul class="order-info-list">
                        <li class="oi-element">
                            <span class="left">Product Name</span>
                            <span class="right" v-text="`${element.productName}`"></span>
                        </li>
                        <li class="oi-element">
                            <span class="left">Loan Amount</span>
                            <span class="right" v-text="`₹ ${element.loanAmount}`"></span>
                        </li>
                        <li class="oi-element">
                            <span class="left">Tenure</span>
                            <span class="right" v-text="`${element.period} days`"></span>
                        </li>
                        <li class="oi-element">
                            <span class="left">Received</span>
                            <span class="right" v-text="`₹ ${element.arriveAmount}`"></span>
                        </li>
                        <li class="oi-element">
                            <span class="left">Repayment Amount</span>
                            <span class="right" v-text="`₹ ${element.repaymentAmount}`"></span>
                        </li>                        
                    </ul>
                    <router-link v-show="element.url != ''" class='wrapper-link' :to="`${element.url}`" ></router-link>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive, onMounted, ref } from 'vue'
import { getOrders } from '@api/index'
import { useLoading } from 'vue-loading-overlay'
export default {
    setup () {
        const status = ref(2)
        const dict = {           
           "0": "Auditing",          
           "5": "Auditing",
           "10": "Pass",
           "15": "Denied", 
           "20": "Lending",
           "25": "Failed",
           "30": "Loaned",           
           "50": "Overdued",
           "40": "Settled"           
        }
        const orders = reactive({
            list:[]
        })
        const loading = useLoading()
        onMounted(()=>{
            const loader = loading.show({
                container: null
            })
            getOrders().then((res)=>{
                loader.hide()
                if(res.code == 0) {
                    orders.list = res.data.map((element)=>{
                        if(['0','5'].indexOf(element.orderState) != -1 ) {
                            element.url = `/orders/${element.id}/audit`
                        } else if ( element.orderState == '10' ) {
                            element.url = `/orders/${element.id}/confirm`
                        } else if (element.orderState == '30' || element.orderState == '50') {
                            element.url = `/orders/${element.id}/detail`
                        } else {
                            element.url = ''
                        }
                        return element
                    })
                    if(orders.list.length) {
                        status.value = 1
                    } else {
                        status.value = 0
                    }
                }
            })
        })
        const getStateText = (state) => {
            return dict[state]
        }
        return {
            orders,
            getStateText,
            status
        }
    }
}
</script>
<style lang="less" scoped>
    .wrapper{
        padding:50px;
        .orders{
            .no-order{
                .banner{
                    width: 236px;
                    height: 204px;
                    background:url("~assets/images/orders/order-banner.png") no-repeat;
                    background-size: 100% 100%;
                    margin: 0 auto
                }
                .title{
                    text-align: center;
                    font-size: 44px;
                    color:#000;
                    margin: 60px 0 30px 0;
                }
                .caption{
                    padding:30px 50px;
                    color:#A59BB3;
                    font-size: 32px;
                    line-height: 1.5;
                    white-space: nowrap;
                }
                .btn-container {
                    margin-top: 100px;            
                    width: 100%;
                    height: 96px;
                    .btn-submit {
                    display: block;
                    width: 670px;
                    height: 96px;
                    line-height: 96px;
                    text-align: center;
                    border-radius: 12px;
                    background: #6d20d3;
                    font-size: 32px;
                    margin: 0 auto;
                    color: #fff;
                    &.red{
                        background:#C5340B;
                    }
                    }
                }
            }
            .order-element{
                width: 654px; 
                background: #FFFFFF;
                box-shadow: 0px 22px 80px 0px rgba(0,0,0,0.0800);
                border-radius: 16px;
                position: relative;
                margin-bottom: 50px;
                .wrapper-link{
                    position: absolute;
                    z-index:999;
                    width: 654px;
                    height:100%;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                }
                &.valid{

                }
                &.invalid{
                    .order-id{
                        color:#A59BB3;                        
                    }
                    .oi-element{
                        span.left{color:#A59BB3;}
                        span.right{color:#A59BB3;}
                    }
                }
                .tag{
                    position: absolute;
                    top:0;
                    right:0;
                    width: 194px;
                    height: 106px;
                    
                    .order_state{
                        display: block;
                        width: 150px;
                        color:#fff;
                        font-size: 32px;
                        top:27px;
                        right:-5px;
                        position: absolute;  
                        text-align: center;                                              
                        transform: rotate(31deg);
                    }
                    &.invalid{
                        background:url("~assets/images/orders/invalid.png") no-repeat;
                        background-size: 100% 100%;
                    }
                    &.valid{
                        background:url("~assets/images/orders/valid.png") no-repeat;
                        background-size: 100% 100%;
                    }
                }         
                .order-id{
                    padding:30px 40px;
                    border-bottom:2px solid #EFEEF1;
                    color:#6D20D3;
                    font-size: 30px;
                    height:46px;
                    line-height: 46px;
                    text-align: left;
                }
                .order-info-list{
                    padding:30px;
                    .oi-element{
                        overflow:hidden;
                        margin-bottom: 30px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                        .left{
                            float:left;
                            color:#160544;
                            font-size: 32px;
                        }
                        .right{
                            float:right;
                            color:#160544;
                            font-size: 32px;
                        }
                    }
                }
            }
        }
    }
</style>