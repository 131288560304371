<template>    
    <div>
        <div class="element-wrapper" @click="showMenu" v-show="state_id" :class="{'is_error': is_error}">
            <div class="element-title">
                <h3 v-text="title"></h3>
                <i class="icon" :class="{'icon-exclaim':is_error }"></i>
                <i class="icon icon-arrow-down" :class="{'icon-arrow-up':is_show}"></i>
            </div>
            <ul class="choice-container" v-show="is_show">
                <li class="choice-element" :class="{'active': selected_ind === ind}" v-for="(item, ind) in state" :key="ind" @click="selected($event, ind)" v-text="item.name"></li>
            </ul>            
            <div class="element-content-default" v-show='selected_ind == null' v-text="label"></div>
            <div class="element-content" v-show='selected_ind != null' v-text="selected_ind == null ?'':state[selected_ind].name"></div>
        </div>        
    </div>
    
</template>
<script>
import { mapDistrict } from '@api/index'
export default {
    props:{
        component_id:{
            type:Number
        },
        title:{
            type:String
        },
        is_show:{
            type:Boolean
        },
        is_error:{
            type:Boolean
        },
        keys:{
            type:String
        },
        label:{
            type:String
        },
        state_id:{
            type:Number
        }
    },
    async mounted () {
        
    },
    data () {
        return {     
            selected_ind:null,       
            state: [] 
        }        
    },
    watch : {
        state_id (cv, pv) {            
            if(cv != pv) {
                this.selected_ind = null
                this.$emit("setValue",this.keys, null)            
                this.requestForData()
            }            
        }
    },
    methods:{
        selected (e, ind) {
            e.stopPropagation();            
            this.selected_ind = ind
            this.$emit("setValue",this.keys, this.state[ind].id)            
            this.$emit('modify', this.component_id, false)  
            this.$emit("modifyError", this.component_id, false)                      
        },
        showMenu () {
            this.$emit('modify', this.component_id, true)
        },
        async requestForData() {            
            mapDistrict(this.state_id).then((res)=>{                
                this.state = res.data
            })
            
        }
    }
}
</script>
<style lang="less" scoped>
@import "assets/less/icon.less";
.element-wrapper{
    padding-bottom: 30px;
    border-bottom: 2px solid #EFEEF1;
    position: relative;
    margin-bottom: 30px;
    &.is_error{
        border-bottom:  2px solid #C5340B;       
    }
    .element-title{
        color:#73698F;
        font-size: 26px;  
        overflow: hidden;
        padding:15px 0;      
        h3{
            float:left;
        }
        .icon-exclaim{
            float:left;
            margin-left: 20px;
        }
        .icon-arrow-down{
            float:right
        }
    }
    .element-content{
        margin-top: 30px;
        color:#000;
        font-size: 36px;
        height:40px;
        line-height: 40px;
    }
    .element-content-default{
        margin-top: 30px;
        color:#D0CDDA;
        font-size: 36px;
        height:40px;
        line-height: 40px;
    }
    .choice-container{  
        // display: none;      
        position: absolute;
        top:-525px;
        width: 575px;
        max-height: 500px;
        padding:0 30px;      
        background:#fff; 
        z-index:99; 
        box-shadow: 0px 0px 44px 0px rgba(9, 14, 118, 0.08);
        // top:50px;
        overflow: auto;
        .choice-element{            
            border-bottom: 2px solid #EFEEF1;
            padding:40px;            
            color:#000;
            font-size: 32px;
            &.active{
                color:#6D20D3;
            }
        }
    }
}
    
</style>