<template>
    <div class="wrapper">
        <div class="error" v-show="error_show">
            <i class="icon icon-exclaim"></i>
            <p class="error-text" v-text="error_txt"></p>
        </div>
        <div class="element-wrapper">
            <div class="element-title">
                <h3>IFSC Code</h3>                
            </div>
            <div class="element-content">
                <input type="text"  v-model="obj.ifscCode" placeholder="Please Input">
            </div>
        </div>
        <div class="element-wrapper">
            <div class="element-title">
                <h3>Bank Account  No.</h3>                    
            </div>
            <div class="element-content">
                <input type="text" @input="textInput($event, 'bank_account')" id='bank_account' placeholder="Please Input">
            </div>
        </div>
        <div class="element-wrapper">
            <div class="element-title">
                    <h3>Confirm Account No.</h3>                    
                </div>
            <div class="element-content">
                <input type="text" @input="textInput($event, 'bank_account_confirm')" id="account_confirm" placeholder="Please Input">
            </div>
        </div>
        <div class="forget">
            <router-link :to="`/bank/ifsc?pid=${$route.query.pid}`">Forget your IFSC ?</router-link>
        </div>
        <div class="btn-container">
            <a class="btn-submit" @click.prevent="submit">Confirm</a>
        </div>
    
    </div>
</template>
<script>
import { reactive,ref,getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { bindCard } from '@api/index'
import { useLoading } from 'vue-loading-overlay'
export default {
    setup () {
        const states = reactive([])
        const error_show = ref(false)
        const error_txt = ref('')
        const router = useRouter()
        const route = router.currentRoute
        const $loader = useLoading()
        const clicked = ref(false)
        const obj = reactive({
            ifscCode: route.value.query.ifsc_code || '',
            bank_account:'',
            bank_account_confirm:'',
        })
        const proxy  = getCurrentInstance().proxy        
        const textInput = (e, name) => {
            const v = e.target.value
            if(/\D+/.test(v)) {
                e.target.value = e.target.value.substring(0, e.target.value.length - 1 )
            } else if (v.length >= 22) {
                e.target.value = e.target.value.substring(0, e.target.value.length - 1 )
            } 
            obj[name] = e.target.value
            
        }
        const validate = () => {
            let res = true
            if(!obj.ifscCode.length) {
                error_show.value = true
                error_txt.value = 'Please input Ifsc Code'
                res = false
            } else if (!obj.bank_account.length) {
                error_show.value = true
                error_txt.value = 'Please input Bank Account'
                res = false
            } else if (!obj.bank_account_confirm.length) {
                error_show.value = true
                error_txt.value = 'Please input Confirm Account'
                res = false
            } else if (obj.bank_account != obj.bank_account_confirm) {
                error_show.value = true
                error_txt.value = 'Please Confirm Account'
                res = false
            } 
            return res
        }
        const submit = () => {
            if(validate() && !clicked.value) {
                clicked.value = true
                const loader = $loader.show({
                    container:null
                })
                bindCard({
                    bankCode: obj.ifscCode,
                    cardNo:obj.bank_account,
                    productId: route.value.query.pid
                }).then((res)=>{
                    clicked.value = false
                    loader.hide()
                    if(res.code == 0) {
                        router.push({
                            path:'/bank',
                            query:{
                                pid: route.value.query.pid
                            }
                        })
                    } else {
                        proxy.$toast.show(res.msg)
                    }
                })
            }
        }
        return {
            states,
            obj,
            textInput,
            submit,
            error_show,
            error_txt
        }
    }
}
</script>
<style lang="less" scoped>
@import "assets/less/icon.less";
.wrapper{
    padding: 50px 65px;
    .error{      
      height:30px;  
      font-size: 30px;
      display: flex;
      .icon{
        flex:0 0 auto;
        margin-right: 15px;
      }
      .error-text{
        flex:1 0 auto;
        color:#C5340B;
      }
    }
}
.forget{
    width: 536px;
    height: 96px;
    background: #F5F3FF;
    border-radius: 12px;
    text-align: center;
    color:#6D20D3;
    font-size: 32px;
    line-height: 96px;
    margin:60px auto;
}
.btn-container{
        position:fixed;
        bottom:50px;
        left:0;
        width: 100%;
        height: 96px;
        .btn-submit{
            display: block;
            width: 670px;
            height: 96px;
            line-height: 96px;
            text-align: center;
            border-radius: 12px;
            background:#6D20D3;
            font-size: 32px;
            margin:0 auto;
            color:#fff;
        }
    }
.element-wrapper{
    padding-bottom: 30px;
    border-bottom: 2px solid #EFEEF1;
    position: relative;
    margin-bottom: 30px;
    &.is_error{
        border-bottom:  2px solid #C5340B;       
    }
    .element-title{
        color:#73698F;
        font-size: 26px;  
        overflow: hidden;
        padding:15px 0;      
        h3{
            float:left;
        }
        .icon-exclaim{
            float:left;
            margin-left: 20px;
        }
        .icon-arrow-down{
            float:right
        }
    }
    .element-content{
        margin-top: 30px;
        color:#000;
        font-size: 36px;
        height:40px;
        line-height: 40px;
        input{
            display:block;
            width: 100%;
            font-size: 32px;
            border:none;
            &::placeholder{
                color:#D0CDDA;
            }
        }
    }
}    
</style>