<template>
    <div class="wrapper">
        <p class="guide">
           Step <em>{{ route.query.step }}</em> / {{ route.query.as }}
        </p>
        <!-- <Dropdonw 
            title="Name"
            :choics=[]
        >

        </Dropdonw> -->
        <h1 class="title">Basic Information</h1>
        <div class="content">
            <div class="element-container" v-for="(item, ind) in obj.select_types" :key="ind">
                <template v-if="item.dataType=='0'">
                    <Dropdonw
                        :title="item.key"
                        :required="item.checkReq"
                        :choices="item.dictionaryVoList"
                        :keys="item.value"    
                        :is_show="item.is_show" 
                        :is_error="item.is_error"
                        :label="item.label"              
                        :component_id = "ind"                    
                        @modify="modify_normal"
                        @modifyError="modify_error"
                        @setValue="setValue"                    
                    >
                    </Dropdonw>        
                </template>
                <template  v-if="item.dataType=='1'">
                    <MyInput
                        :title="item.key"
                        :component_id = ind
                        :required="item.checkReq"
                        :keys="item.value"
                        :label="item.label"
                        :checkType="item.checkType"
                        :is_error="item.is_error"
                        :maxlength="item.lengthLimitMax"
                        @modifyError="modify_error"
                        @setValue="setValue"
                    >
                    </MyInput>
                </template>
                <template  v-if="item.dataType=='4'">
                    <State
                        :title="item.key"
                        :required="item.checkReq"
                        :component_id = ind
                        :keys="item.value"
                        :is_error="item.is_error"
                        :is_show="item.is_show"
                        :label="item.label"
                        @setValue="setValue"
                        @modify="modify_normal"
                        @modifyError="modify_error"
                    >
                    </State>
                </template>
                <template  v-if="item.dataType=='5'">
                    <Location
                        :title="item.key"
                        :required="item.checkReq"
                        :component_id = ind
                        :keys="item.value"   
                        :label="item.label"   
                        :is_error="item.is_error"                  
                        :is_show="item.is_show"
                        :state_id = "state_id"
                        @setValue="setValue"
                        @modify="modify_normal" 
                        @modifyError="modify_error"                   
                    >
                    </Location>
                </template>
                
            </div>            
        </div>        
        <div class="placeholder"></div>
        <div class="btn-container">            
            <a class='btn-submit' :class='{"unactive":!btnStatus}' @click="submit">CONFIRM TO NEXT</a>
        </div>
    </div>
</template>
<style lang="less" scoped>
    .wrapper{
        padding:40px;
        .guide{
            color:#A59BB3;
            font-size: 30px;
            em{
                color:#6D20D3;
                font-weight: 700;
            }
        }
        .title{
            color:#6D20D3;
            font-size: 40px;
            margin: 50px 0;  
        }
    }
    .btn-container{
        position: fixed;
        left:0;
        bottom: 0;        
        background:#fff;
        padding:40px;
        // padding:40px;
        .btn-submit{
            display: block;
            width: 670px;
            height: 96px;
            line-height: 96px;
            text-align: center;
            border-radius: 12px;
            background:#6D20D3;
            font-size: 32px;
            margin:0 auto;
            color:#fff;
            &.unactive{
                background:#f0f0f0;
                color:#999
            }
        }
    }
    .placeholder{
        height: 176px;        
    }
</style>
<script>

import { reactive, onMounted, computed, ref , getCurrentInstance} from 'vue'
import { base_info, base_submit } from '@api/index'
import Dropdonw from '@/components/business/kyc/dropdown.vue'
import MyInput from '@/components/business/kyc/input.vue'
import State from '@/components/business/kyc/state.vue'
import Location from '@/components/business/kyc/location.vue'
import { useRouter } from 'vue-router'
import { useLoading } from 'vue-loading-overlay'
export default {
    components:{
        Dropdonw,
        MyInput,
        State,
        Location
    },    
    setup () {
        const router = useRouter()
        const route = router.currentRoute
        const clicked = ref(false)
        const btnStatus = ref(false)
        const proxy  = getCurrentInstance().proxy
        let obj = reactive({
            select_types:[],
            input_types:[],
            state_types:[],
            district_types:[],            
            params:{}
        })
        const state_id = computed(()=>{
            return obj.params['liveState'].value
        })
        const validate = () => {
            let res = true
            for(let x in obj.params){
                if (obj.params[x].value == '') {
                    obj.select_types[obj.params[x].ind].is_error = true
                    res = false
                }
            }
            btnStatus.value = res
            return res
        }
        const $loading = useLoading()
        const submit = () => {            
            if(validate() && !clicked.value){
                clicked.value = true
                let loader = $loading.show({
                    container:null
                })
                let newParams = {}
                for(let i in obj.params) {
                    newParams[i] = obj.params[i].value
                }
                base_submit(newParams).then((res)=>{
                    if(res.code == 0) {
                        router.replace({
                            "path":"/verify/index/" + route.value.query.pid
                        })                       
                    } else {
                        proxy.$toast.show(res.msg)
                    }
                    loader.hide()
                    clicked.value = false
                }).catch(()=>{
                    clicked.value = false
                })
            }
        }
        onMounted(async () => {
            let data = await base_info()
            let select_type = data.data.map((ele)=>{
                ele.is_show = false
                ele.is_error = false
                if(ele.dataType == '0'|ele.dataType == '4'|ele.dataType == '5') {
                    ele.label = ele.label?ele.label:"Please select"
                } else if (ele.dataType == '1') {
                    ele.label = ele.label?ele.label:"Please input"
                } 
                
                return ele
            })
            // .filter(element => {
            //     return element.dataType == '0'            
            // })
            obj.select_types = select_type.map((ele, ind)=>{
                ele.is_show = false                
                ele.is_error = false
                obj.params[ele.value] = {
                    "value":'',
                    "ind": ind
                }
                return ele
            })                        
        })
        const modify_normal = (k, v) => {
            for (let x in obj.select_types){                
                obj.select_types[x].is_show = false
            }
            obj.select_types[k]['is_show'] = v            
        }
        const modify_error = (k, v) =>{
            obj.select_types[k]['is_error'] = v
        }
        const setValue = (k, v) => {               
            obj.params[k].value = v
            validate()
        }                
        return {
            obj, setValue ,modify_normal,state_id,submit,modify_error,btnStatus,route
        }
    }
}
</script>
