<template>
  <div class="wrapper blue" >
    <div class="banner">
      Important Anti-fraud Tips
    </div>
    <div class="content">
      <div class="title">
        <i class="icon-alert"></i>
        <h1 class="main-title">lmportant Anti-fraud Tips</h1>
        <i class="icon-alert"></i>
      </div>
      <div class="graph-content">
        <p class="graph">
          Dear users:
        </p>
        <p class="graph">
          Beware of fraudsters offering loans on behalf of InstaRupee and do not transfer any money to any personal account.
        </p>
        <p class="graph">
          Never pay to personal account as we never enhance your credit limit by that way.
        </p>
        <p class="graph">
          Never reveal your OTP to anyone, as we never ask you for any OTP.
        </p>
        <p class="graph">
          InstaRupee powered by NBFC,security compliance, please rest assured loan.
        </p>
        <p class="graph">
          Note: All improper collection or fraud are not done by our company. Please pay attention to prevention!!
        </p>
        <p class="graph">
          For any queries,please contact us:
          <br>
          Phone: +91 9289458622
          <br>
          Email: snjezanapavlovasnjezana@gmail.com
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {}
  }
}
</script>
<style lang="less" scoped>
.wrapper{
  position:fixed;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  bottom:0;
  right:0;
}
.logo {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 10px;
  left: 10px;
  background-repeat: no-repeat;
  background-size: 100% 100%;  
}
.icon-alert {
  background: url("../../assets/images/notify/alert.png") no-repeat;
  background-size: 100% 100%;
  width: 42px;
  height: 44px;
  margin-top: -15px;
}
.wrapper {
  width: 100%;
  // height: 1707px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  &.red{
      background-image: url("../../assets/images/notify/red.jpg");
      background-color: #F63E04;
  }
  &.blue{
      background-image: url("../../assets/images/notify/blue.jpg");
  }
  &.green{
      background-image: url("../../assets/images/notify/green.jpg");
  }
  .banner {
    margin: 0 auto;
    padding: 110px 0 50px 0;
    width: 656px;
    // height: 211px;
    font-size: 40px;
    color:#fff;
    font-weight: 700;
    text-align: center;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .title {
    width: 728px;
    height: 95px;
    background: url("../../assets/images/notify/title-bg.png") no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    color: #ff0000;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    position: relative;
    z-index: 2;
    .main-title {
      margin: -15px 10px 0 10px;
      font-size:28px;
    }
  }
  .graph-content {
    width: 530px;
    // height: 1135px;
    background: url("../../assets/images/notify/content-bg.png") no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    padding: 60px 50px 30px 50px;
    position: relative;
    top: -20px;
    .graph {
      font-size: 20px;
      line-height: 1.5;
      margin-bottom: 45px;
      word-break: break-all;
    }
  }
}
</style>
