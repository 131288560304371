<template>
    <div class="wrapper">
        <ul class="choice-list">
            <li class="choice-element" @click="requestFor('bank', 'bankName')">
                <span class="text" v-show="obj.bank.id != ''" v-text="obj.bank.label"></span>
                <span class="text no-select" v-show="obj.bank.id == ''">Please Select Bank</span>
                <i class="icon icon-arrow-right"></i>
            </li>
            <li class="choice-element" @click="requestFor('state','state')" v-show='obj.bank.id != ""'>
                <span class="text" v-show="obj.state.id != ''" v-text="obj.state.label"></span>
                <span class="text no-select" v-show="obj.state.id == ''">Please Select State</span>
                <i class="icon icon-arrow-right"></i>
            </li>
            <li class="choice-element" @click="requestFor('city','city')"  v-show='obj.state.id != ""'>
                <span class="text" v-show="obj.city.id != ''" v-text="obj.city.label"></span>
                <span class="text no-select" v-show="obj.city.id == ''">Please Select City</span>
                <i class="icon icon-arrow-right"></i>
            </li>
            <li class="choice-element" @click="requestFor('branch','branch')"  v-show='obj.city.id != ""'>
                <span class="text" v-show="obj.branch.id != ''" v-text="obj.branch.label"></span>
                <span class="text no-select" v-show="obj.branch.id == ''">Please Select Branch</span>
                <i class="icon icon-arrow-right"></i>
            </li>
        </ul>
        <div class="dialog" v-show="dialog_show">
            <div class="dialog-content">
                <div class="search">                
                    <i class="icon icon-search"></i>
                    <div class="input-container">
                        <input type="text" v-model="text" placeholder="Search">
                    </div>
                    
                </div>
                <div class="result-list">
                    <ul class="list" v-for="(element,ind) in active_list_computed" :key=ind>
                        <li class="l-element" v-text="element[obj.element_name]" @click="selected(ind)"></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="dialog-wrapper" v-show="confirm_show">
        <div class="dialog-confirm">
            <div class="dialog-title">Is this yor IFSC Code?</div>
            <div class="dialog-content">
                <div class="important">
                    <div class="little-title">
                        IFSC Code:
                    </div>
                    <div class="code" v-text="obj.ifsc_code"></div>
                </div>
                <div class="element">
                    <div class="element-l">State: </div>
                    <div class="element-r" v-text="obj.state.label"></div>
                </div>
                <div class="element">
                    <div class="element-l">Distrt: </div>
                    <div class="element-r" v-text="obj.city.label"></div>
                </div>
                <div class="element">
                    <div class="element-l">Branch: </div>
                    <div class="element-r" v-text="obj.branch.label"></div>
                </div>
            </div>
            <div class="dialog-footer">
                <a href="javascript:void(0);" class="btn-submit" @click.prevent="confirm">Yes</a>
                <a href="javascript:void(0);" class="btn-cancel" @click.prevent="cancel">RE-FIND</a>
            </div>
        </div>
    </div>
    
</template>
<style lang="less" scoped>
    @import "assets/less/icon.less";
    .dialog-wrapper{
        position: fixed;
        top:0;
        left:0;
        right:0;
        bottom:0;
        width: 100%;
        height: 100%;
        background:rgba(0,0,0,0.8);
        .dialog-confirm{           
            width: 500px;
            margin:0 auto;
            background:#fff;
            border-radius: 15px;
            position:absolute;
            left:50%;
            top:50%;
            transform: translate(-50%, -50%);
            padding:50px 80px;
            .dialog-title{
                font-size: 44px;
                text-align:center;
                color:#000;
                margin-bottom: 50px;
                font-weight: 700;
            }
            .dialog-content{
                .important{
                    .little-title{
                        font-size: 32px;
                        color:#000;
                        padding-left: 15px;
                    }
                    .code{
                       width: 485px;
                       height: 106px;
                       line-height: 106px;
                       color:#000;
                       font-weight: 700;
                       padding-left: 15px;
                       background:#F9F9FB;
                       font-weight: 700;
                       margin:30px 0;
                       text-align:center;
                    }
                }
                .element{
                    overflow:hidden;
                    padding-left: 15px;
                    margin-bottom: 30px;
                    .element-l{
                        float:left;
                        width:150px;
                        font-size: 32px;
                        color:#000;
                        margin-right: 15px;
                    }
                    .element-r{
                        float:left;
                        color:#000;
                        font-size: 32px;
                        height:35px;
                        line-height: 35px;
                    }
                }
            }
            .dialog-footer{
                width: 100%;
                margin-top: 80px;
                .btn-submit{
                    display:block;
                    width: 500px;
                    height: 96px;
                    background: #6D20D3;
                    border-radius: 12px;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 96px;
                    font-size: 32px;
                    margin:0 auto;
                }
                .btn-cancel{
                    display: block;
                    width: 500px;
                    text-align: center;
                    font-size: 30px;
                    color:#6D20D3;
                    padding:30px 0;
                    margin:0 auto;
                }
            }
        }
    }
    .wrapper{
        padding:50px;
    }
    .choice-list{
        .choice-element{
            width: 570px;
            padding:30px;
            margin-bottom: 50px;
            background:#F9F9FB;          
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 8px;            
            .text{
                font-size:32px ;
                color:#000;
                flex:1 1 auto;
                line-height: 1.5;
                &.no-select{
                    color:#D0CDDA;
                }
            }
        }
    }
    .dialog{
        position: fixed;
        width: 100%;
        height: 100%;
        left:0;
        top:0;   
        background:#fff;
        div.dialog-content{            
            .search{       
                background:#F5F3FF;
                height: 40px;
                border-radius: 8px;
                padding:30px;
                display: flex;
                justify-content: center;
                align-items: center;
                .input-container{
                    width: 600px;
                    height:40px;
                    input{
                        display: block;
                        width: 100%;
                        height: 100%;
                        border: none;
                        background: transparent;
                        color:#000;
                        font-size: 40px;
                    }
                }
                .icon{
                    flex:0 0 auto;
                }
            }
            .result-list{
                padding: 0 50px;
                .list{
                   font-size:32px;
                   color:#000;
                   padding:30px 0;
                   border-bottom:2px solid #EFEEF1;
                   .l-element{
                    line-height: 1.5;
                   }
                }
            }
        }             
    }
</style>
<script>
import { reactive, onMounted, ref, computed, watch } from 'vue'
import { getIfscBank, getIfscState, getIfscCity, getIfscBranch } from '@api/index'
import { useRouter } from 'vue-router'
import { useLoading } from 'vue-loading-overlay'

export default {
    setup () {
        const loading = useLoading()
        const router = useRouter()
        const route = router.currentRoute
        const text = ref('')
        const dialog_show = ref(false)
        const confirm_show = ref(false)
        const active_list_computed = computed(()=>{
            return obj.active_list.filter((element)=>{
                return element[obj.element_name].toLowerCase().indexOf(text.value.toLowerCase()) > -1
            })
        })        
        const obj = reactive({
            ifsc_code:'',
            active_list:[],
            element_name: 'bankName',
            active_type:'',
            bank:{
                label:'',
                id:'',
                list:[]
            },
            state:{
                label:'',
                id:'',
                list:[]
            },
            city:{
                label:'',
                id:'',
                list:[]
            },
            branch:{
                label:'',
                id:'',
                list:[]
            }
        })
        watch(() => obj.bank.id, (id, prev_id) => {
            if(id != prev_id && id != '') {
                const loader = loading.show({
                    "container":null
                })
                getIfscState({
                    pId:id
                }).then((res)=>{
                        loader.hide()
                        if(res.code == 0) {
                            obj.state.list = res.data
                            obj['state'].id =''
                            obj['city'].id =''
                            obj['branch'].id =''
                        }
                }) 
            } else if (id == '') {
                obj['state'].id =''
                obj['city'].id =''
                obj['branch'].id =''
            }
        })
        watch(() => obj.state.id, (id, prev_id) => {
            if(id != prev_id && id != '') {
                const loader = loading.show({
                    "container":null
                })
                getIfscCity({
                    pId:id
                }).then((res)=>{
                    loader.hide()
                    if(res.code == 0) {
                        obj.city.list = res.data                            
                        obj['city'].id =''
                        obj['branch'].id =''
                    }
                }) 
            }
        })
        watch(() => obj.city.id, (id, prev_id) => {
            if(id != prev_id && id != '') {
                const loader = loading.show({
                    "container":null
                })
                getIfscBranch({
                    pId: obj.city.id
                }).then((res)=>{
                    loader.hide()
                    if(res.code == 0) {
                        obj.branch.list = res.data                            
                        obj['branch'].id =''
                    }
                }) 
            }
        })        
        const requestFor = (name, element_name) => {
            obj.active_list = [].concat(obj[name].list)
            obj.active_type = name
            obj.element_name = element_name
            dialog_show.value = true
        }
        onMounted(() => {
           getIfscBank().then((res)=>{
                if(res.code == 0) {
                    obj.bank.list = res.data
                    // obj.active_list = obj.bank.list
                }
           }) 
        })
        function findIndexfromOrigin(ind) {
            const tid = active_list_computed.value[ind].id
            const target = obj[obj.active_type].list.filter((element)=>{
                return element.id == tid
            })
            return target[0]
        }
        const selected = (ind)=>{
            let target = findIndexfromOrigin(ind)
            obj[obj.active_type].value = target.id
            obj[obj.active_type].id = target.id
            obj[obj.active_type].label = target[obj.element_name]
            dialog_show.value = false
            if (obj.active_type == 'branch') {                
                obj.ifsc_code = target.ifsc
                confirm_show.value = true
            }
            else if (obj[obj.active_type].id != target.id) {
                if(obj.active_type == 'bank') {
                    obj['state'].id =''
                    obj['city'].id =''
                    obj['branch'].id =''
                } else if (obj.active_type == 'state') {
                    obj['city'].id =''
                    obj['branch'].id =''
                } else if (obj.active_type == 'city') {
                    obj['branch'].id =''
                }
            }
        }
        const confirm = () =>{
            router.replace({
                path:"/bank/add/",
                query:{
                    pid:route.value.query.pid,
                    ifsc_code: obj.ifsc_code
                }
            })
            confirm_show.value = false
        }
        const cancel = () => {
            obj.bank.id = ''
            confirm_show.value = false
        }
        return {
            text,
            obj,
            dialog_show,
            confirm_show,
            active_list_computed,
            requestFor,
            selected,
            confirm,
            cancel
        }
    }
}
</script>