<template>
    <div class="wrapper">
        <div class="banner">
            <!-- <div class="logo"></div> -->
            <p class="content">
                Your Loan<br/>
                amount has arrived
            </p>
            <p class="money">
                ₹200,000
            </p>
        </div>
        <div class="info">
            <dl class='require'>
                <dt>Eligibility</dt>
                <dd>· Indian citizen</dd>
                <dd>· 18 years above</dd>
            </dl>
            <div class="fense"></div>
            <dl class='require'>
                <dt>Required Information</dt>
                <dd>· KYC Documents</dd>
                <dd>· Basic Information</dd>
                <dd>· Work Information</dd>
                <dd>· Contact Information</dd>
            </dl>
        </div>
        <div class="btn-container">
            <router-link to="/" v-if="is_ok == 1"  class="link">APPLY NOW</router-link>
            <a v-if="is_ok == 2" href="http://www.instarupee.in"  class="link">APPLY NOW</a>
            <a v-if="is_ok == 0" href="http://www.instarupee.in"  class="link">APPLY NOW</a>
        </div>
        <div class="tl1"></div>
        <div class="tl2"></div>
        <div class="tl3"></div>
        <div class="tl4"></div>
        <div class="tr1"></div>
        <div class="tr2"></div>
        <div class="tr3"></div>
        <div class="pointer"></div>
        <div class="triangel"></div>
        <div class="info2">
            <p>Product Information</p>
            <p>Interest Rate: 30% per annum.</p>
            <p>GST: 18% of Processing Fee</p>
            <p>Loan Tenure: 91 days and with a maximum of 360days</p>
            <p>Representative Example:</p>
            <p>For a loan with principal amount of ₹1,0000 payable in 91 days, with interest rate @33% per annum, a user would pay:</p>
            <p>Interest = ₹810</p>
            <p>Processing fee = ₹2360 (including 18% GST of processing fee, which is ₹360)</p>
            <p>EMI = ₹4,390</p>
            <p>The total amount due would be ₹1,3170.</p>
        </div>
        <div class="info2">
            If you have any questions, please contact us
            CompanyName:NIMISHA FINANCE INDIA PRIVATE LIMITED
            Email:nimishafinance@outlook.com
            Phone:+91 9311225303
            Address:645A/057, Plot 15, Janki Vihar, Sector-1, Jankipuram Locknow-226021
        </div>
    </div>
</template>
<script>
import { onMounted, ref } from 'vue'
import { getCountry } from '@api/index'
import { getOs } from '../../utils/tool'
export default {
    setup () {
        const is_ok = ref(0)
        onMounted(async ()=>{
            document.body.style.height = window.screen.height + 'px'
            const data = await getCountry()
            const os = getOs()
            if(data.code== 0){                
                if(data.data.accordWith && os == 'ios-h5') {
                    is_ok.value = 1
                } else {
                    is_ok.value = 2
                }
            }            
            // document.body.style.overflow = "hidden"
        }) 
        return {
            is_ok
        }
    }
}
</script>
<style lang="less" scoped>
.info2{
    padding:30px;
    width:612px;
    font-size: 24px;
    line-height: 1.2;
    background:rgba(235, 234, 242, 0.8);
    margin: 0 auto;
    margin-bottom: 30px;
}
@keyframes scale {
    0% {
        transform: scale(.9,.9);
    }
    50%{
        transform: scale(1,1);
    }
    100%{
        transform: scale(.9,.9);
    }
}
    .tr1{
        background:url("~assets/images/download/tr1.png") no-repeat;
        background-size: 100% 100%;
        width: 198px;
        height: 352px;
        position: absolute;
        top:-100px;        
        right:0px;

    }
    .tr2{
        background:url("~assets/images/download/tr2.png") no-repeat;
        background-size: 100% 100%;
        width: 198px;
        height: 352px;
        position: absolute;
        top:300px;        
        right:0;
    }
    .tr3{
        background:url("~assets/images/download/tr3.png") no-repeat;
        background-size: 100% 100%;
        width: 198px;
        height: 352px;
        position: absolute;
        top:555px;        
        right:50px;        
    }
    .pointer{
        background:url("~assets/images/download/pointer.png") no-repeat;
        background-size: 100% 100%;
        width: 198px;
        height: 352px;
        position: absolute;
        top:1215px;        
        right:50px;
        animation: scale .5s linear infinite ;
    }
    .tl1{
        background:url("~assets/images/download/tl1.png") no-repeat;
        background-size: 100% 100%;
        width: 198px;
        height: 352px;
        position: absolute;
        top:65px;
        left:-30px;
    }
    .tl2{
        background:url("~assets/images/download/tl2.png") no-repeat;
        background-size: 100% 100%;
        width: 198px;
        height: 352px;
        position: absolute;
        top:315px;
        left:200px;
    }
    .tl3{
        background:url("~assets/images/download/tl3.png") no-repeat;
        background-size: 100% 100%;
        width: 198px;
        height: 352px;
        position: absolute;
        top:1015px;
        left:20px;
    }
    .tl4{
        background:url("~assets/images/download/tl4.png") no-repeat;
        background-size: 100% 100%;
        width: 198px;
        height: 352px;
        position: fixed;
        bottom:-190px;
        left:20px;
        display:none;
    }
    .triangel{
        position:fixed;
        width:100%;
        height:1800px;
        top:0;
        background:url("~assets/images/download/triangel.png") no-repeat;
        background-size: 100% 100%;
        z-index: -1;
    }
    .wrapper{        
        position: relative;
        padding-bottom: 30px;
        // overflow: hidden;
        .banner{
            background:url("~assets/images/download/banner.png") no-repeat;
            background-size: 100% 100%;
            // background-color:#4B39C6;
            width: 100%;
            height: 600px;
            position:relative;
            .logo{
                width: 88px;
                height: 88px;
                background: url("~assets/images/download/logo.png") no-repeat;
                background-size:100% 100%;                
                position: absolute;
                top:30px;
                left:30px;
            }
            .content{
                text-align: center;
                color:#fff;
                margin-bottom: 50px;
                padding-top: 200px;
                font-size: 50px;
            }
            .money {
                font-weight: 700;
                font-size: 110px;
                text-align: center;
                color:#fff;
            }
        }
        .info{   
            background: RGBA(235, 234, 242, 0.8);
            width: 368px;
            padding:30px;
            margin: 50px auto 0 auto;
            border-radius: 16px;
            .require{
                dt{
                    color:#6B6B6B;
                    font-size: 32px;
                    margin-bottom: 20px;
                }
                dd{
                    font-size: 24px;
                    color:#6B6B6B;
                    text-indent: 2em;
                    margin-bottom: 20px;
                }
            }
            .fense{
                width: 100%;
                margin: 0 auto;
                height:2px;
                background:url("~assets/images/download/line.png") no-repeat;
                background-size: 100% 100%;
                margin:30px 0;
            }
        }
        .btn-container{
            margin-top: 30px;
            .link{
                display: block;
                width: 615px;
                height:210px;
                line-height: 190px;
                text-align:center;
                font-size: 58px;
                color:#fff;
                background:url("~assets/images/download/button-bg.png") no-repeat;
                background-size: 100% 100%;
                margin: 0 auto;
            }
        }

    }
</style>