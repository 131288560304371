<template>
    <div class='wrapper'>
        <p>This product is only available to IOS users. </p>
        <p>Please use the IOS browser to open this page. </p>
        <p>Copy the address <b>https://h5.instarupee.in</b></p>
    </div>
</template>
<style lang="less" scoped>
    div.wrapper{
        padding:50px;
    }
    p{
        color:#999;
        margin-bottom: 20px;
        font-size: 28px;
        // font-weight: 700;
        b{
            color:#909090;
            font-weight: 700;
        }
    }
</style>