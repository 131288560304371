<style lang="less">
    .promotion-login-wrapper{
        width: 100%;
        height: 100%;
        position: fixed;
        top:0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background:rgba(0,0,0,0.6);
        z-index: 2;
        .dialog{
            width: 590px;
            height: 590px;
            background:#fff;
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%);
            border-radius: 30px;
            padding:40px;
            z-index: 6;
            .dialog-head{
                overflow: hidden;
                margin-bottom: 20px;
                .icon-close{
                    display:block;
                    background: url("~assets/images/promotion/recruit/icon-dialog-close.png") no-repeat;
                    width: 30px;
                    height: 30px;
                    background-size:100%;
                    float:right
                }
            }
            .login-content{                
                .login-caption{
                    color:#525252;
                    text-align: left;
                    line-height: 1.5;
                    font-size: 30px;
                }
                .login-element{
                    margin-top: 30px;                    
                    .input-container{
                        display:flex;
                        background:#f3f3f3;
                        width: 530px;
                        height: 92px;
                        border-radius: 46px;
                        justify-content: space-around;
                        align-items: center;
                        padding:0 30px;
                        .prefix{
                            font-size: 26px;
                            color:#525252;
                            border-right:2px solid #525252;
                            padding:14px 24px 14px 0;
                        }
                        .get_code{
                            width: 168px;
                            height: 68px;
                            background:linear-gradient(#FF3D3D,#FF9898);
                            border-radius: 34px;
                            border: none;
                            color:#fff;
                            text-align: center;
                            line-height:68px;
                        }
                        .input-content-container{
                            width:400px;
                            input{
                                display:block;
                                font-size: 26px;
                                border:none;
                                background:transparent;
                                height:92px;
                                width: 100%;
                                line-height: 92px;
                                &::placeholder{
                                    font-size: 26px;
                                    color:#A6A5A5;
                                }
                            }
                        }
                    }
                }
                .btn-group{
                    margin-top: 30px;
                    .btn-login{
                        width: 590px;
                        height: 100px;
                        line-height: 100px;
                        color:#fff;
                        text-align: center;
                        font-size: 28px;
                        background:linear-gradient(#FF3D3D,#FF9898);
                        border-radius: 50px;
                        border:none;
                    }
                    .text-link{
                        display: block;
                        text-align: center;
                        color:#151515;
                        font-size: 24px;
                        text-decoration: underline;
                        margin-top: 30px;
                    }
                }
            }
        }
    }
</style>
<template>
     <div class="promotion-login-wrapper" v-show="loginShow">
        <div class="dialog">
            <div class="dialog-head">
                    <i class="icon icon-close" @click="closeLoginDialog"></i>
                </div>
                <div class="login-content">
                    <p class="login-caption">
                        Please log in with the phone number you used to register on Instarupee.
                    </p>
                    <div class="login-element">
                        <div class="input-container">
                            <div class="prefix">+91</div>
                            <div class="input-content-container">
                                <input type="tel" v-model="obj.phone" placeholder="Enter your  phone number here!" @input="phoneInput">
                            </div>
                        </div>               
                    </div>
                    <div class="login-element">
                        <div class="input-container">                            
                            <div class="input-content-container">
                                <input type="tel" v-model="obj.code" placeholder="Enter the verification code" @input="codeInput">
                            </div>
                            <button class="get_code" @click="sendCodeFn">{{ code_text }}</button>
                        </div>
                    </div>
                    <div class="btn-group">
                        <button class='btn-login' @click="login">Log In</button>
                        <router-link to="/login" class="text-link">No account? Register now</router-link>
                    </div>
                </div>
        </div>
    </div>
</template>
<script>
import { reactive,ref,getCurrentInstance } from 'vue'
import { sendCode } from '@api/index.js'
import { useStore } from 'vuex'
export default {
    props:['loginShow'],
    setup (props, context) {
        const store = useStore()
        const proxy  = getCurrentInstance().proxy
        const obj = reactive({
            phone:"",
            code:"",
            sec:0
        })
        const code_text = ref("Get OTP")
        const sendCodeState = ref(false)
        const loginState = ref(false)
        let st = ref(0)
        const closeLoginDialog = () => {
            context.emit("closeLoginDialog", false)
        }
        const phoneInput = () => {
            if(/\D/.test(obj.phone) | obj.phone.length > 10) {                
                obj.phone = obj.phone.substring(0, obj.phone.length - 1)                
            }

            
        }  
        const sendCodeFn = async () => {
            if(sendCodeState.value)return
            sendCodeState.value = true
            let data = await sendCode({
                phone: "91"+obj.phone
            })
            if(data.code == 0) {
                obj.secs = 60
                st.value = setInterval(()=>{
                    obj.secs = obj.secs - 1
                    code_text.value = `${obj.secs}s`
                    if(obj.secs <= 0) {
                        code_text.value = 'Resend'
                        clearInterval(st.value)
                        sendCodeState.value = false
                    }
                },1000)
            } else {
                proxy.$toast.show(data.msg)
                sendCodeState.value = false
            }
        }

        const codeInput = () => { 
        
            if(/\D/.test(obj.code) | obj.code.length > 4) {                
                obj.code = obj.code.substring(0, obj.code.length - 1)                
            }
            
        }

        const login = ()=>{
            if(loginState.value)return
            loginState.value = false
            store.dispatch('promotionLogin',{
                phone:obj.phone,
                code:obj.code
            }).then(()=>{
                context.emit("closeLoginDialog", false)
                window.location.reload()
            }).catch((msg)=>{                    
                proxy.$toast.show(msg)
            })
        }

        return {
            obj,
            code_text,
            sendCodeFn,
            closeLoginDialog,
            phoneInput,
            codeInput,
            login
        }
    }
}
</script>