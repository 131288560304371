import { createRouter, createWebHistory } from 'vue-router'
import { getToken, getTokenFromStorage } from '../utils/tool'
import Layout from '@/layout/index.vue';
import AppLayout from '../src/layout/home.vue'
import Index from '../src/pages/index.vue'
// import Order from '../src/pages/order/index.vue'
import Personal from '../src/pages/personal/index.vue'
import Login from '../src/pages/login.vue'
import Product from '../src/pages/product/index.vue'
import VerifyIndex from '../src/pages/verify/index.vue'
import Kyc from '../src/pages/verify/kyc.vue'
import Work from '../src/pages/verify/work.vue'
import Face from '../src/pages/verify/active.vue'
import Base from '../src/pages/verify/base.vue'
import Contact from '../src/pages/verify/contact.vue'
import Contact2 from '../src/pages/verify/contact2.vue'
import Callback from '../src/pages/verify/callback.vue'
import ProductDetail from '../src/pages/product/detail.vue'
import Bank from '../src/pages/bank/index.vue'
import bankAdd from '../src/pages/bank/add.vue'
import Ifsc from '../src/pages/bank/isfc.vue'
import Orders from '../src/pages/orders/index.vue'
import orderDetail from '../src/pages/orders/detail.vue'
import orderAudit from '../src/pages/orders/audit.vue'
import orderAudit2 from '../src/pages/orders/audit2.vue'
import orderConfirm from '../src/pages/orders/confirm.vue'
import Aboutus from '../src/pages/personal/about.vue'
import Help from '../src/pages/personal/help.vue'
import Download from '../src/pages/download.vue'
import Alert from '../src/pages/alert.vue'
import Notify from '../src/pages/promotion/notify.vue'
import Recruit from '../src/pages/promotion/recruit.vue'

const routes = [
    {
      path:'/promotion',
      name: 'Promotion',
      component: Layout,
      children:[
        {
          path:'notify',
          name: 'notify',
          component: Notify,
          meta:{ title:'Index', bgcolor:'#F7F7F7' }
        } ,
        {
          path:'recruit',
          name: 'recruit',
          component: Recruit,
          meta:{ title:'Recruit', bgcolor:'#F7F7F7' }
        }        
      ]
    },
    {
        path:'/',
        name: 'Index',
        component: AppLayout,
        children:[
            {
                path:'',
                name: 'Home',
                component: Index,
                meta:{ title:'Index', bgcolor:'#F7F7F7' }
            },
            {
                path:'orders',
                name: 'Order',
                component: Orders,
                meta:{ title:'Orders', bgcolor:'#F7F7F7' }
            },
            {
                path:'personal',
                name: 'Personal',
                component: Personal,
                meta:{ title:'Personal', bgcolor:'#fff' }
            }
        ]        
    },{
      path: '/login',
      name: 'login',
      component: Login,
      meta:{ title:'Login', bgcolor:'#fff' }
    },
    {
      path: '/verify',
      name:"Verify",
      component: Layout,
      children:[
        {
          path:'index/:pid',
          name:"index",
          component: VerifyIndex,
          meta:{ title:'Index', bgcolor:'#F7F7F7' }
        },
        {
          path:"face",
          name: "Face",
          component: Face,
          meta:{ title:'Face', bgcolor:'#F7F7F7' }
        },
        {
          path:"kyc",
          name: "Kyc",
          component: Kyc,
          meta:{ title:'Kyc', bgcolor:'#F7F7F7' }
        },
        {
          path:"base",
          name: "Base",
          component: Base,
          meta:{ title:'Base', bgcolor:'#fff' }
        },
        {
          path:"contact",
          name: "Contact",
          component: Contact,
          meta:{ title:'Contact', bgcolor:'#F7F7F7' }
        },{
          path:"work",
          name: "Work",
          component: Work,
          meta:{ title:'Work Information', bgcolor:'#fff' }
        },
        {
          path:"contact2",
          name: "Contact2",
          component: Contact2,
          meta:{ title:'Contact', bgcolor:'#F7F7F7' }
        },
        {
          path: "callback",
          name: "callback",
          component: Callback,
          meta:{ title:'Callback', bgcolor:'#fff' }
        }
      ]
    },
    {
      path: '/product/:pid',
      name: 'Product',
      component: Product,
      meta:{ title:'Product', bgcolor:'#fff' }
    },    
    {
      path: '/product/:pid/detail',
      name: 'Product-Detail',
      component: ProductDetail,
      meta:{ title:'Detail', bgcolor:'#fff' }
    },    
    {
      path: '/bank',
      name: 'Bank',   
      component: Layout,   
      children:[
        {
          path:"",
          name: "Bank",
          component:Bank,
          meta:{ title:'Bank', bgcolor:'#fff' }
        },
        {
          path:"add",
          name: "Bank_Add",
          component: bankAdd,
          meta:{ title:'Bank', bgcolor:'#fff' }
        },
        {
          path:"ifsc",
          name: "IFSC",
          component: Ifsc,
          meta:{ title:'Bank', bgcolor:'#fff' }
        }         
      ]
    },    
    {
      path: '/orders',
      name: 'Orders',
      component:Layout,
      children:[
        {
          path:":id/detail",
          name:'Order_Detail',
          component: orderDetail,
          meta:{ title:'Detail', bgcolor:'#fff'}
        },{
          path:":id/audit",
          name:'Order_audit',
          component: orderAudit,
          meta:{ title:'Auditing', bgcolor:'#fff'}
        },{
          path:"audit",
          name:'Order_auditt',
          component: orderAudit2,
          meta:{ title:'Auditing', bgcolor:'#fff'}
        },{
          path:":id/confirm",
          name:'Order_confirm',
          component: orderConfirm,
          meta:{ title:'Confirm', bgcolor:'#fff'}
        }
      ]
    },
    {
      path: '/personal',
      name: 'personal',
      component:Layout,
      children:[
        {
          path:"aboutus",
          name:'aboutus',
          component: Aboutus,
          meta:{ title:'About Us', bgcolor:'#fff'}
        },{
          path:"help",
          name:'help',
          component: Help,
          meta:{ title:'Help', bgcolor:'#fff'}
        }
      ]
    },{
      path:"/download",
      name:'Download',
      component: Download,
      meta:{ title:'Download', bgcolor:'#fff'}
    },{
      path:"/alert",
      name:'Alert',
      component: Alert,
      meta:{ title:'Alert', bgcolor:'#fff'}
    }
]
const router  = createRouter({
    history: createWebHistory('/'),
    routes
})
const whiteList = ['/login', '/download', '/alert', '/verify/contact2', '/orders/audit', '/promotion/notify', '/promotion/recruit']
router.beforeEach((to, from, next) => {
    document.title = to.meta.title ? to.meta.title : 'Index'        
    document.body.style.background = to.meta.bgcolor ? to.meta.bgcolor : '#fff'    
    const hasToken = getToken() || getTokenFromStorage()   
    // alert(hasToken)   
    if (hasToken) {
      if (to.path === '/login') {        
        // next({ path: '/' })        
        next()
      } else {
        next()
      }
        
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
          } else {            
            next(`/login?redirect=${to.path}`)
            // NProgress.done()
          }
    }
  })
export default router