import axios from 'axios'
import router from '../routes/index'
import { getToken, getOs, getTokenFromStorage, removeToken, removeTokenFromStorage} from './tool'
import qs from 'qs'
const request = axios.create({
    baseURL: "/api1",
    timeout: 100000
})
const whiteList = ['/verify/contact2',"/promotion/recruit",'"/promotion/recruit"']
request.interceptors.request.use(function (config)  {
    console.log(config.data)
    if(config.method!='get' && !config.needForm){
        console.log(1)
        config.data=qs.stringify(config.data);
    }  
    config.headers['os'] = `${getOs()}`
    let token = getToken()
    if(!token) {
        token = getTokenFromStorage()
    }
    if(!token) {
        token = null
    }     
    if(config.token) {
        token = config.token
    }
    if (token) {                
        config.headers['token'] = token        
    } 
      return config
})
request.interceptors.response.use(function (data) {
    // debugger
    // const os = getOs()
    // if(os == 'other') {
    //     router.replace({
    //         path:"/alert"
    //     })
    // }        
    if(data.data.code == -4) {                  
        if( whiteList.indexOf(router.currentRoute.value.path) == -1 ) {
            removeToken()
            removeTokenFromStorage()     
            router.replace({
                path:"/login"
            })
        }                
    }
    return data.data
})
export default request