import request from "../utils/request.js";

export function home() {
  return request({
    url: "/home/route",
    method: "get",
  });
}
export function index() {
  return request({
    url: "/home/index",
    method: "get",
  });
}
export function sendCode(data) {
  return request({
    url: "/sms/sendCode",
    method: "post",
    data,
  });
}

export function login(data) {
  return request({
    url: "/user/login",
    method: "post",
    data,
  });
}
export function productInfo(data) {
  return request({
    url: "/product/info",
    method: "post",
    data,
  });
}
export function handheld(data) {
  return request({
    url: "authen/upload",
    method: "post",
    needForm: true,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
}
export function ocr(data) {
  return request({
    url: "authen/ocrSeparate",
    method: "post",
    needForm: true,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
}

export function key_submit(data) {
  return request({
    url: "/authen/kycInfo",
    method: "post",
    data,
  });
}
export function base_info(data) {
  return request({
    url: "/auth/pageInfo?type=baseinfo",
    method: "get",
    data,
  });
}
// 地区
export function mapDistrict(stateId) {
  return request({
    url: "/map-state/district?stateId=" + stateId,
    method: "get",
  });
}
// 地区
export function mapState() {
  return request({
    url: "/map-state/list",
    method: "get",
  });
}

// 基本信息提交
export function base_submit(data) {
  return request({
    url: "/auth/v4/baseInfo",
    method: "post",
    data,
  });
}
// 基本信息提交

//
export function contact_relation() {
  return request({
    url: "/auth/pageInfo?type=contactInfo",
    method: "get",
  });
}

export function contact_relation2(token) {
  return request({
    url: "/auth/pageInfo?type=contactInfo",
    method: "get",
    token,
  });
}
// 提交联系人信息
export function submit_contact(data) {
  return request({
    url: "/authen/contacts",
    method: "post",
    data,
  });
}
export function submit_contact2(data, token) {
  return request({
    url: "/authen/contacts",
    method: "post",
    data,
    token,
  });
}
// 查询认证情况
export function getAuthInfo(data) {
  return request({
    url: "/authen/findUserAuth",
    method: "post",
    data,
  });
}
// 活体获取地址
export function getActiveUrl() {
  return request({
    url: "authen/h5/face-token",
    method: "get",
  });
}
// 活体获取结果
export function getActiveResult() {
  return request({
    url: "authen/h5/face-result",
    method: "get",
  });
}
// 试算
export function calculate(data) {
  return request({
    url: "/api/order/calculate",
    method: "post",
    data,
  });
}
// 查询银行卡
export function getBank(data) {
  return request({
    url: "/bank/findUserBankCard",
    method: "post",
    data,
  });
}
// ifsccode 银行
export function getIfscBank(data) {
  return request({
    url: "/bankInfo/getBankInfo",
    method: "post",
    data,
  });
}
// ifsccode 州
export function getIfscState(data) {
  return request({
    url: "/bankInfo/getStateListByBankId",
    method: "post",
    data,
  });
}
// ifsccode 城市
export function getIfscCity(data) {
  return request({
    url: "/bankInfo/getCityListByStateId",
    method: "post",
    data,
  });
}
// ifsccode branch
export function getIfscBranch(data) {
  return request({
    url: "/bankInfo/getBranchListByCityId",
    method: "post",
    data,
  });
}
// 绑卡
export function bindCard(data) {
  return request({
    url: "/bank/bindCard",
    method: "post",
    data,
  });
}
// 申请
export function apply(data) {
  return request({
    url: "/api/order/apply",
    method: "post",
    data,
  });
}
// 订单详情
export function productDetailInfo(data) {
  return request({
    url: "/api/order/apply",
    method: "post",
    data,
  });
}
// 订单列表
export function getOrders() {
  return request({
    url: "/api/order/list",
    method: "post",
  });
}
// 申请订单详情
export function orderDetail(data) {
  return request({
    url: "/api/order/query",
    method: "post",
    data,
  });
}
// 还款
export function repay(data) {
  return request({
    url: "/api/order/repay",
    method: "post",
    data,
  });
}
// 确认用款
export function confirm(data) {
  return request({
    url: "/api/order/loan",
    method: "post",
    data,
  });
}
// 问答
export function qa() {
  return request({
    url: "/system/faq",
    method: "post",
  });
}
// 关于我们
export function aboutus() {
  return request({
    url: "/system/aboutUs",
    method: "post",
  });
}
// 提交gps
export function gps(data) {
  return request({
    url: "/device/h5/upload/base_info",
    method: "post",
    data,
    needForm: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
}
// 活体
export function newFace(data) {
  return request({
    url: "/authen/h5/face",
    method: "post",
    data,
    needForm: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function faceConfig() {
  return request({
    url: "/system/living-config",
  });
}
export function liveBody(data) {
  return request({
    url: "/authen/v3/livingBody",
    method: "post",
    data,
    needForm: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
}
// 获取
export function getCountry() {
  return request({
    url: "/system/get-country",
  });
}
export function work_info() {
  return request({
    url: "/auth/v3/pageInfo?type=workInfo",
    method: "get",
  });
}

export function work_submit(data) {
  return request({
    url: "/auth/v2/workInfo",
    method: "post",
    data,
  });
}
export function bury(data) {
  return request({
    url: "/buryingPoint/burying-point",
    method: "post",
    data: [data],
    needForm: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// promotion recruit 
export function promotionInviteLink() {
  return request({
    url: "/invitation/link",
    method: "get",    
  });
}

// promotion recruit login 
export function promotionLogin(data) {
  return request({
    url: "/user/v1/activity-login",
    method: "post",    
    data    
  });
}
export function promotionMarquee () {
  return request({
    url:"/invitation/marquee",
    method: "get"
  })
}
export function promotionStatic () {
  return request({
    url:"/invitation/statistics",
    method: "get"
  })
  
}
export function promotionRecord () {
  return request({
    url:"/invitation/record",
    method: "get"
  }) 
}

export function showActivity () {
  return request({
    url:"/user/show-activity",
    method: "get"
  }) 
}

