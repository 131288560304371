<template>
    <div class="wrapper">
        <div class="success" v-show='status == 1'></div>
        <div class="failed" v-show='status == 0'></div>
        <div class="btn-container">            
            <router-link class='btn-submit' to="/verify/kyc" replace v-show="status == 1">NEXT</router-link>
            <router-link class='btn-submit' to="/verify/face" replace v-show='status == 0' >NEXT</router-link>
        </div>
    </div>
</template>
<script>
import { getActiveResult } from '@api/index';
export default {
    data () {
        return {
            status : 2
        }
    },
    mounted () {
        const loader = this.$loading.show({
            container:null
        })
        getActiveResult().then((res)=>{
            loader.hide()
            if(res.data.status == 1) {
                this.status = 1
            } else {
                this.status = 0
            }
        })
    }
}
</script>
<style lang="less" scoped>
    .wrapper{
        padding:30px 15px;
        .success{
            width: 720px;
            height:550px;
            background:url("~assets/images/callback/success.jpg") no-repeat;
            background-size: 100% 100%;            
        }
        .failed{
            width: 720px;
            height:550px;
            background:url("~assets/images/callback/fail.jpg") no-repeat;
            background-size: 100% 100%;            
        }
        .btn-container{            
            width: 100%;
            height: 96px;
            margin-top: 50px;
            .btn-submit{
                display: block;
                width: 670px;
                height: 96px;
                line-height: 96px;
                text-align: center;
                border-radius: 12px;
                background:#6D20D3;
                font-size: 32px;
                margin:0 auto;
                color:#fff;
            }
        }
    }
</style>