<template>
  <div class="wrapper">
    <div class="order-element">
      <div class="tag">
        <span class="order_state"></span>
      </div>
      <div class="order-id" :class="{'red': obj.info.overdueDays  > 0 }" v-text="`ID: ${obj.info.orderNo}`"></div>
      <ul class="order-info-list">
        <li class="oi-element red" v-if="obj.info.overdueDays > 0 ">
          <span class="left">Days Overdue</span>
          <span class="right" v-text="`${obj.info.overdueDays}`"></span>
        </li>
        <li class="oi-element red"  v-if="obj.info.overdueDays  > 0">
          <span class="left">Overdue Fees</span>
          <span class="right" v-text="`₹ ${obj.info.overdueFee}`"></span>
        </li>

        <li class="oi-element">
          <span class="left">Loan Amount</span>
          <span class="right" v-text="`₹ ${obj.info.loanAmount}`"></span>
        </li>
        <li class="oi-element">
          <span class="left">Tenure</span>
          <span class="right" v-text="`${obj.info.period} days`"></span>
        </li>
        <li class="oi-element">
          <span class="left">Received</span>
          <span class="right" v-text="`₹ ${obj.info.arriveAmount}`"></span>
        </li>
        <li class="oi-element">
          <span class="left">Repayment Amount</span>
          <span class="right" v-text="`₹ ${obj.info.repaymentAmount}`"></span>
        </li>
      </ul>
    </div>
    <div class="detail-info">
      <ul class="detail-list">
        <li class="detail-list-element">
          <span class="left-text">Processing Fee</span
          ><span class="right-text" v-text="`₹${obj.info.processFee}`"></span>
        </li>
        <li class="detail-list-element">
          <span class="left-text">GST18% on Processing Fee</span
          ><span class="right-text" v-text="`₹${obj.info.gstFee}`"></span>
        </li>
        <li class="detail-list-element">
          <span class="left-text">Interest Fee</span
          ><span class="right-text" v-text="`₹${obj.info.interestFee}`"></span>
        </li>
        <li class="detail-list-element"><span class="left-text">Application Date</span><span class="right-text" v-text="obj.info.applyTime"></span></li>
      </ul>    
    </div>
    <div class="btn-container">
        <a class="btn-submit" :class='{"red": obj.info.overdueDays  > 0}' @click.prevent="submit" v-text="`Repayment ₹ ${obj.info.repaymentAmount}`"></a>
    </div>
  </div>
</template>
<style lang="less" scoped>
.wrapper {
  padding-top: 100px;
  .order-element {
    width: 654px;
    background: #ffffff;
    box-shadow: 0px 22px 80px 0px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    .tag {
      position: absolute;
      top: 0;
      right: 0;
      width: 194px;
      height: 106px;
      .order_state {
        display: block;
        width: 150px;
        color: #fff;
        font-size: 32px;
        top: 20px;
        right: 0px;
        position: absolute;
        text-align: center;
        transform: rotate(31deg);
      }
    }
    .order-id {
      padding: 30px 40px;
      border-bottom: 2px solid #efeef1;
      color: #fff;
      font-size: 30px;
      height: 46px;
      line-height: 46px;
      background: #6d20d3;
      &.red{
        background:#C5340B;
      }      
      font-weight: 700;
    }
    .order-info-list {
      padding: 30px;
      .oi-element {
        overflow: hidden;
        margin-bottom: 30px;
        &.red{
            .left{
                color:#C5340B;
            }                        
        }
        &:last-child {
          margin-bottom: 0;
        }
        .left {
          float: left;
          color: #160544;
          font-size: 32px;
        }
        .right {
          float: right;
          color: #160544;
          font-size: 32px;
        }
      }
    }
  }
  .detail-info {
    background: #f5f4fa;
    padding: 40px;
    width: 564px;    
    margin: -20px auto 0 auto;
    .detail-list {
      margin-top: 60px;
      .detail-list-element {
        overflow: hidden;
        margin-bottom: 30px;
        .left-text {
          float: left;
          color: #a59bb3;
          font-size: 28px;
        }
        .right-text {
          float: right;
          color: #a59bb3;
          font-size: 28px;
        }
      }
    }
  }
  .btn-container {
    position: fixed;
    bottom: 50px;
    left: 0;
    width: 100%;
    height: 96px;
    .btn-submit {
      display: block;
      width: 670px;
      height: 96px;
      line-height: 96px;
      text-align: center;
      border-radius: 12px;
      background: #6d20d3;
      font-size: 32px;
      margin: 0 auto;
      color: #fff;
      &.red{
        background:#C5340B;
      }
    }
  }
}
</style>
<script>
import { orderDetail, repay } from "@api/index.js";
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const route = router.currentRoute.value;
    const obj = reactive({
      info: {
        id: "",
        loanAmount: "",
        period: "",
        arriveAmount: "",
        repaymentAmount: "",
        loanDate: "",
        gtsFee: "",
        serviceFee: "",
        repaymentDate: "",
        orderNo: "",
        processFee:'', 
        gstFee:'',
        interestFee: '',
        applyTime:''
      },
    });
    onMounted(() => {
      document.body.style.background = '#fff'
      orderDetail({
        orderId: route.params.id,
      }).then((res) => {
        if (res.code == 0) {
          obj.info = {
            arriveAmount: res.data.arriveAmount,
            loanAmount: res.data.loanAmount,
            loanDate: res.data.loanDate,
            overdueDays: res.data.overdueDays,
            gtsFee: res.data.gtsFee,
            serviceFee: res.data.serviceFee,
            repaymentDate: res.data.repaymentDate,
            repaymentAmount: res.data.repaymentAmount,
            period: res.data.period,
            overdueFee: res.data.overdueFee,
            orderState: res.data.orderState,
            orderNo: res.data.orderNo,
            id: res.data.id,
            processFee: res.data.serviceFee,
            gstFee: res.data.gtsFee,
            interestFee: res.data.interest,
            applyTime:res.data.applyTime
          };
        }
      });
    });
    const submit = () => {
        repay({
            orderId: obj.info.id
        }).then((res)=>{
            if(res.code == -21) {
               window.location.href = res.url
            }
        })
    }
    return {
      obj,
      submit
    };
  },
};
</script>