<template>
    <div>
        <Single v-if='obj.state == 0' :imgList="obj.imgList" :money='obj.money' :logined="obj.logined" :product="obj.product_list[0]"></Single>          
        <Multiple v-if='obj.state == 1' :imgList="obj.imgList" :list="obj.product_list" :logined="obj.logined"></Multiple>   
        <div class="float" @click="jumpTo" v-if="showActivityValue == 1"></div>               
    </div>    
</template>
<style lang="less" scoped>
    @keyframes scale {
        0%{
            transform: scale(1.1);
        }
        50%{
            transform: scale(1);
        }
        100%{
            transform: scale(1.1);
        }
    }
    .float{
        width: 100px;
        height: 100px;
        position: fixed;
        bottom:200px;
        right:0;
        background:url("../assets/images/promotion/recruit/float.png") no-repeat;
        background-size:100% 100%;
        animation: scale 1s linear infinite;
    }
</style>
<script>
import Single from '@/components/business/index/single.vue'
import Multiple from '@/components/business/index/multiple.vue'
import { reactive , onMounted, ref} from 'vue'
import { home,index } from '../../api/index'
import { getToken,getTokenFromStorage } from '../../utils/tool'
import { useLoading } from 'vue-loading-overlay'
import { useRouter } from "vue-router"
import { showActivity } from '@api/index.js'
export default {
    components:{
        Single,
        Multiple
    },
    setup () {  
        const loading = useLoading()   
        const router = useRouter()  
        const showActivityValue = ref(0) 
        const obj = reactive({
            state: 2,
            imgList: [],
            money: '',
            product_list:[],
            logined: getTokenFromStorage() || getToken()
        })
        const jumpTo = () => {
            router.push({
                "path":"/promotion/recruit"
            })
        }
        onMounted(async () => { 
            showActivity().then(res => {
                if(res.code == 0) {
                    if(res.data.showActivity) {
                        showActivityValue.value = 1    
                    }                    
                }
            })                  
            const loader = loading.show({
                container: null
            })                        
            const data = await home()
            const data_2 = await index()            
            loader.hide()
            if(data.code == 0 ) {
                if(data.data.page === 0) {
                    obj.state = 0
                    if(data_2.data.newBanners) {
                        obj.imgList = data_2.data.newBanners
                    }
                    obj.money = data_2.data.products[0].maxAmount
                    obj.product_list = data_2.data.products
                } else {
                    obj.state = 1
                    obj.imgList = data_2.data.newBanners
                    obj.product_list = data_2.data.products
                }
            }                    
        })
        return {obj,jumpTo,showActivityValue}
    }
}
</script>
