<template>
    <div class="wrapper" @click="capture">
        <div class="top-content">
            <div class="top-content-element top1" @click="showMenu">
                <div class="inner-title">Loan Amount(₹)</div>
                <div class="top-content-inner">
                    <span v-text="obj.selectAmount"></span>
                    <i class="icon icon-white-arrow-up"></i>  
                </div>
                <ul class="amount-list" v-show='list_show'>
                    <li class="amount-element" @click.prevent="selected($event, ind)" :class="{'active': ind === selected_ind}" v-for="(ele, ind) in obj.amountList" :key="ind" v-text="ele"></li>
                </ul>
            </div>            
            <!-- <div class="top-content-element">
                <div class="inner-title">Tenure(days)</div>
                <div class="top-content-inner" v-text="obj.period"></div>
            </div> -->
            <div class="top-content-element">
                <div class="inner-title">Repayment Amount</div>
                <div class="top-content-inner small-font" v-text="obj.repaymentAmount"></div>
            </div>
            <div class="top-content-element">
                <div class="inner-title">Interests rate</div>
                <div class="top-content-inner small-font" v-text="obj.interestRate + '%/day'"></div>
            </div>
        </div>  
        <div class="detail-info">
            <ul class="detail-list">
                <!-- <li class="detail-list-element"><span class="left-text">Processing Fee</span><span class="right-text" v-text="`₹${obj.processFee}`"></span></li> -->
                <!-- <li class="detail-list-element"><span class="left-text">GST18% on Processing Fee</span><span class="right-text" v-text="`₹${obj.gstFee}`"></span></li> -->
                <li class="detail-list-element"><span class="left-text">Interest Fee</span><span class="right-text" v-text="`₹${obj.interestFee}`"></span></li>
                <!-- <li class="detail-list-element"><span class="left-text">Application Date</span><span class="right-text" v-test="applicationDate"></span></li> -->
            </ul>
        </div>    
        <ul class="other-info">
            <li class="oi-element">
                <router-link :to="`/bank?pid=${$route.params.pid}`">
                    <div class="left-content">
                        <h4 class="oi-title">Bank Account</h4>
                        <div class="oi-content" :class="{'no-choice': !bank_info.have_bank}" v-text="bank_info.have_bank? bank_info.card_no : 'Click To Select Bankcard'"></div>                        
                    </div>
                    <div class="right-content">
                        <i class="icon icon-arrow-right"></i>
                    </div>
                </router-link>
            </li>
            <!-- <li class="oi-element"  @click="showUseage">
                <div class="left-content">
                    <h4 class="oi-title">Usage of Loan</h4>
                    <div class="oi-content no-choice" v-show='useage_info.selected_ind == null'>Please Select Usage of Loan</div>
                    <div class="oi-content" v-show='useage_info.selected_ind != null' v-text='useage_info.choices[useage_info.selected_ind]'></div>
                </div>
                <div class="right-content">
                    <i class="icon icon-arrow-right"></i>
                </div>
                <ul class="choice-container" v-show="useage_info.is_show">
                    <li class="choice-element" :class="{'active': useage_info.selected_ind === ind}" v-for="(item, ind) in useage_info.choices" :key="ind" @click="useage_selected($event,ind)" v-text="item"></li>
                </ul>
            </li> -->
        </ul>
        <div class="auto">
            <div class="auto-content">
                <h3 class='auto-title'>Automatically get money?</h3>
                <p class="auto-text">
                  Please note that the amount approved may be differentfrom the amount applied for.  
                </p>                
            </div>
            <div :class="['e__switch',{'e_is-checked': auto_get}]">
                    <span class="e__switch-core" @click="toggle"></span>
                </div>
        </div>
        <div class="placeholder"></div>
        <div class="btn-container">
            <a class="btn-submit" @click.prevent="submit">APPLY NOW</a>
        </div>
    </div>
</template>
<style lang="less" scoped>
@import "assets/less/icon.less";
.placeholder{
    height: 150px;
}
.e__switch {
    position:absolute;
    top:40px;
    right:40px;
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        line-height: 20px;
        height: 20px;
        vertical-align: middle;
        cursor: pointer;
    }

    .e__switch>input {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
        margin: 0;
    }

    .e__switch-core {
        margin: 0;
        position: relative;
        width: 100px;
        height: 50px;
        border: 1px solid #DCDFE6;
        outline: 0;
        border-radius: 50px;
        box-sizing: border-box;
        background: #DCDFE6;
        transition: border-color .3s, background-color .3s;
        vertical-align: middle;
    }

    .e__switch-core::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 100%;
        transition: all .3s;
        width: 50px;
        height: 50px;
        background-color: #fff;
    }
    
    .e__switch.e_is-checked .e__switch-core {
        border-color: #8a7ef7;
        background-color: #8a7ef7;
    }
    
    .e__switch.e_is-checked .e__switch-core::after {
        left: 100%;
        margin-left: -49px;
    }
.auto{
    width: 590px;
    padding:40px;
    height: 136px;
    background: linear-gradient(180deg, #fff 0%, #EFEEF8 100%);
    border-radius: 20px;
    margin:30px auto;
    position:relative;
    .auto-content{        
        .auto-title{
            color:#8a7ef7;
            font-weight: 700;
            margin-bottom: 30px;
            font-size: 28px;
        }
        .auto-text{
            font-size: 24px;
            color:#8a7ef7;
            line-height: 1.5;
            font-weight: 300;
        }
    }
}    
.choice-container{  
        // display: none;      
        position: absolute;
        width: 575px;
        padding:0 30px;      
        background:#fff; 
        z-index:99; 
        box-shadow: 0px 0px 44px 0px rgba(9, 14, 118, 0.08);
        top:250px;        
        .choice-element{            
            border-bottom: 2px solid #EFEEF1;
            padding:40px;            
            color:#000;
            font-size: 32px;
            &.active{
                color:#6D20D3;
            }
        }
    }
.other-info{
    
    .oi-element{
        padding:40px;
        border-bottom: 2px solid #EFEEF1;
        overflow: hidden;        
        .left-content{
            float:left;
            .oi-title{
                color:#A59BB3;
                font-size: 26px;
            }
            .oi-content{
                font-size: 30px;
                color:#000;
                margin-top: 30px;
                &.no-choice{
                    color:#D0CDDA;
                }
            }
        }
        .right-content{
            float:right;
            position: relative;
            margin-top: 40px;
        }
    }
}
.wrapper{
    padding: 50px;    
    .top-content{
        position:relative;
        width: 564px;
        height: 320px;
        background:url("~assets/images/product_bg.png") no-repeat;
        background-size: 100% 100%;
        padding:40px; 
        // overflow:hidden;
        .top-content-element{
            width:50%;
            float:left;
            margin-bottom: 60px;
            &.top1{
                width: 100%;
                .inner-title{
                    text-align: center;
                }  
                .top-content-inner{
                    justify-content: center;
                    .icon{
                        flex:0 0 auto;
                        margin:0;
                        margin-left:20px
                    }
                }  
            }
            .inner-title{
                color:#D9CCFF;
                font-size: 24px;
                margin-bottom: 15px;
            }
            .top-content-inner{
                color:#fff;
                font-size: 64px;
                font-weight: 700;
                display: flex;
                align-items: center;
            }
            .small-font{
                font-size: 48px;
            }
            
        }       

    }
    .detail-info{
        background:#F5F4FA;
        padding:40px 40px 20px 40px;
        width: 564px;
        margin-top: -20px;
        .detail-list{
            margin-top: 20px;
            .detail-list-element{
                overflow:hidden;
                margin-bottom: 30px;
                .left-text{
                   float:left;
                   color:#A59BB3;
                   font-size: 28px; 
                }
                .right-text{
                    float:right;
                    color:#A59BB3;
                    font-size: 28px;
                }
            }
        }
    }
    .btn-container{
        position:fixed;
        bottom:50px;
        left:0;
        width: 100%;
        height: 96px;
        background:#fff;
        .btn-submit{
            display: block;
            width: 670px;
            height: 96px;
            line-height: 96px;
            text-align: center;
            border-radius: 12px;
            background:#6D20D3;
            font-size: 32px;
            margin:0 auto;
            color:#fff;
        }
    }
    .amount-list{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background:rgba(255,255,255,1);
        border-radius: 15px;;
        z-index: 99999;
        padding:0 30px;
        .amount-element{
            text-align:center;
            width: 150px;
            padding:30px;
            color:#A59BB3;
            font-size: 28px;   
            border-bottom: 2px solid #e0e0e0;   
            &:last-child{
                border-bottom: none;
            }      
            &:hover{
                color:#6D20D3;
                border-bottom-color:#e0e0e0;   
            }
        }
        
    }
}
</style>
<script>
import { productInfo, calculate, getBank, apply, getAuthInfo, faceConfig} from '@api/index'
import { onMounted,reactive,ref, getCurrentInstance } from 'vue'
import {useRouter} from 'vue-router'
import { useLoading } from 'vue-loading-overlay'
export default {
    setup () {                
        const router = useRouter()
        const route = router.currentRoute        
        const $loader = useLoading()
        const clicked = ref(false)
        const list_show = ref(false)
        const selected_ind = ref(0)
        const proxy  = getCurrentInstance().proxy
        const auto_get = ref(1)
        let useage_info = reactive({
            choices:['Shopping','Medical treatment','Education','Tourism','Living expenses','Purchase of vehicles'],
            selected_ind: null,
            value:null,
            is_show:false
        })
        const toggle = () => {
            if(auto_get.value == 0) {
                auto_get.value = 1
            } else {
                auto_get.value = 0
            }
        }
        let obj = reactive({
            amountList:[],
            selectAmount:'',
            serviceRate:'',
            interestRate: '',
            processFee:'',
            gstFee:'',
            interestFee:'',
            period: ''
        })
        let bank_info = reactive({
            card_no:'',
            bank_id:route.value.query.bank_id || '',
            have_bank:''
        })                
        onMounted(async ()=>{
            const loader = $loader.show({
                container:null
            })
            const data = await productInfo({
                id: route.value.params.pid
            })             
            const data2 = await calculate({
               productId: route.value.params.pid, 
               amount: data.data.amountList[data.data.amountList.length - 1],
               period: 7
            })
            obj.amountList = data.data.amountList
            obj.period = data.data.periodList[0]
            obj.selectAmount = data.data.amountList[data.data.amountList.length - 1]
            selected_ind.value = data.data.amountList.length - 1
            obj.interestRate = data.data.interestRate
            obj.repaymentAmount = data2.data.repaymentAmount
            obj.processFee = data2.data.serviceFee
            obj.gstFee = data2.data.gstFee
            obj.interestFee = data2.data.interest
            const data3 = await getBank({
                productId:route.value.params.pid
            })
            if(data3.code == 0) {
                if(data3.data.length > 0) {
                    if(bank_info.bank_id.length) {
                        const target = data3.data.filter((ele)=>{
                            return ele.id == bank_info.bank_id
                        })
                        bank_info.card_no = target[0].cardNo
                        bank_info.bank_id = target[0].id
                        
                    } else {
                        bank_info.card_no = data3.data[0].cardNo
                        bank_info.bank_id = data3.data[0].id                        
                    }
                    bank_info.have_bank = true
                } else {
                    bank_info.have_bank = false
                }
            }
            loader.hide()
        })
        const showMenu = (e) => {
            e.stopPropagation();
            list_show.value = true
        }
        const validate = () => {
            let res = true
            if(bank_info.bank_id == '') {
                proxy.$toast.show('Please Select or Add Bank Account')
                res = false
            } 
            // else if (useage_info.value == ''||useage_info.value == null) {
            //     proxy.$toast.show('Please Select Usage of Loan')
            //     res = false
            // }
            return res
        }
        const submit = async () => {
            if(clicked.value)return
            if(!validate())return            
            clicked.value = true
            const loader = $loader.show({
                container: null
            })
            
            const data_2 = await getAuthInfo({
                productId:route.value.params.pid
            })
            loader.hide()
            clicked.value = false
            if (data_2.data.kyc === 0) {
                const config = await faceConfig()
                if(config.data['living-config'] == 2) {
                    router.replace({
                        path:"/verify/kyc"
                    })
                } else {
                    router.push({
                        path:'/verify/face'
                    })
                }
            } else if (data_2.data.basic === 0) {
                router.push({
                    path:'/verify/base'
                })                
            } else if (data_2.data.contact === 0) {
                router.push({
                    path:'/verify/contact'
                })
            } else {
                apply({
                    // loanUse:useage_info.value,
                    cardId:bank_info.bank_id,
                    borrowAmount:obj.selectAmount,
                    periods: obj.period,
                    productId: route.value.params.pid,
                    checkEmail:0,
                    autoLoan: auto_get.value
                }).then((res)=>{
                    loader.hide()
                    if(res.code == 0) {
                        router.replace({
                            path:"/orders"
                        })
                    } else {                    
                        proxy.$toast.show(res.msg)
                    }    
                    clicked.value = false       
                })   
            }
                     
        }
        const capture = () => {
            list_show.value = false
        }
        const selected = async (e, ind) => {
            e.stopPropagation()
            obj.selectAmount = obj.amountList[ind]
            selected_ind.value = ind
            list_show.value = false
            const data2 = await calculate({
               productId: route.value.params.pid, 
               amount: obj.selectAmount,
               period: 7
            })            
            obj.repaymentAmount = data2.data.repaymentAmount
            obj.processFee = data2.data.serviceFee
            obj.gstFee = data2.data.gstFee
            obj.interestFee = data2.data.interest
        }
        const showUseage = () => {
            useage_info.is_show = true
        }
        const useage_selected = (e,ind) => {
            e.stopPropagation()
            useage_info.selected_ind = ind 
            useage_info.is_show = false
            useage_info.value = ind
        }
        return {
            obj, 
            selected_ind,           
            list_show,
            bank_info,
            useage_info,
            showUseage,
            submit,
            showMenu,
            capture,
            selected,
            useage_selected,toggle,auto_get
        }
    }
}
</script>