<template>
    <div class="wrapper">
        <router-link class="add" :to="`/bank/add?pid=${$route.query.pid}`" ></router-link>
        <div class="bank-wrapper">
            <ul class="bank-list">
                <li class="bank-element" :class="{
                    'red': ind % 4 == 0,
                    'pupple': ind % 4 == 1,
                    'blue': ind % 4 == 2,
                    'orange': ind % 4 == 3,
                }" v-for="(element, ind) in bank_info.list" :key=ind @click="selected(ind)">
                    <div class="bank-no" v-text="element.cardNo"></div>
                    <div class="ifsc-code" v-text="element.bankCode"></div>
                </li>
            </ul>
        </div>
    </div>
</template>
<style lang="less" scoped>
    .wrapper{
        padding:50px 60px;
        .add{
            display: block;
            background:url("~assets/images/bank/bank-bg.png") no-repeat;
            background-size: 100% 100%;
            width: 630px;
            height: 200px;
            margin-bottom: 30px;
        }
        .bank-list{
            .bank-element{
                background-size:100% 100%;
                background-repeat: no-repeat;
                width: 550px;
                height: 116px;
                padding:40px;
                margin-top: 50px;
                &.red{
                    background-image:url("~assets/images/bank/red.png");
                }
                &.blue{
                    background-image:url("~assets/images/bank/blue.png");
                }
                &.pupple{
                    background-image:url("~assets/images/bank/pupple.png");
                }
                &.orange{
                    background-image:url("~assets/images/bank/orange.png");
                }
                .bank-no{
                    color:#fff;
                    font-size: 56px;
                    font-weight: 700;
                    margin-bottom: 30px;
                }
                .ifsc-code{
                    color:#fff;
                    font-size: 30px;
                }
            }
        }
    }
</style>
<script>
import { reactive, onMounted } from 'vue'
import { getBank } from '@api/index'
import { useLoading } from 'vue-loading-overlay'
import {useRouter} from 'vue-router'
export default {
    setup () {
        const router = useRouter()
        const route = router.currentRoute        
        const $loader = useLoading()
        const bank_info = reactive({
            list:[]
        })

        onMounted(async ()=>{
            const loader = $loader.show({
                container:null
            })
            const data3 = await getBank({
                productId:route.value.query.pid
            })
            if(data3.code == 0) {
                if(data3.data.length > 0) {                    
                    bank_info.list = data3.data
                } 
            }
            loader.hide()
        })
        
        const selected = (ind) => {
            router.push({
                path:"/product/" + route.value.query.pid + '/detail',
                query:{
                    bank_id : bank_info.list[ind].id
                }
            })
        }
        return {
            bank_info,
            selected
        }
    }
}
</script>