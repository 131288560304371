<template>
    <div class="login-container" v-show="state == 0">
        <h1 class='title'>
            Let's get started
        </h1>
        <p class="caption">
            Please enter your cell phone number. Using the cell phone number associated with the Aadhaar card will help increase the lending speed.
        </p>
        <div class="form">
            <div class="form-container">
                <label class='label' for="">Mobile No.</label>
                <div class="input-container">
                    <div class="prefix">+91 &nbsp;—</div>
                    <div class="input-content-container">
                        <input type="tel" v-model="phone" placeholder="Enter phone number" @input="phoneInput">
                    </div>
                </div>
                <div class="error" v-show='is_error'>
                    <i class="icon icon-exclaim"></i> 
                    <p class="error-text" v-text="error_text"></p>
                </div>                
            </div>
        </div>        
    </div>
</template>
<style lang="less" scoped>
    .login-container{
        padding: 0 48px;
        .title{
            font-size: 44px;
            color:#000;
            text-align: center;
            padding:40px 0
        }
        .caption{
            color:#A59BB3;
            font-size: 30px;
            line-height: 1.5;
        }  
        .form{
            .form-container{
                .label{
                   display: block;
                   color:#A59BB3;
                   font-size: 30px; 
                   margin: 50px 0 15px 0;
                }
            }
            .input-container{
                padding:30px 35px 30px 30px;
                display:flex;
                justify-content: flex-start;
                align-items: center;
                border:2px solid #6D20D3;
                border-radius: 15px;;
                .prefix{
                    color:#000;
                    font-size: 32px;
                }
                .input-content-container{
                    flex:1;
                    margin-left: 20px;
                    input{
                        display:block;
                        width: 100%;
                        height: 100%;
                        border:none;
                        font-size: 32px;
                    }
                }
            }
            .error{
                display:flex;
                justify-content: flex-start;
                margin-top: 10px;
                .icon{
                    margin-right: 20px;
                    flex: 1 0 auto;
                }
                .error-text{
                    flex:1 0 auto;
                    font-size: 24px;
                    color:#C5340B;
                }
            }            
        }
        .btn-container{
            margin-top: 80px;
            .btn-submit{
                width: 670px;
                height: 96px;
                line-height: 96px;
                text-align: center;
                color:#fff;
                font-size: 32px;
                border-radius: 15px;
                border: none;
                background:#A59BB3;
                &.active{
                    background:#6D20D3;
                }
            }
        }      
    }
</style>
<script>
export default {
    props:{
        // phone:String,
        // checked:Boolean,
        error_text: String,
        is_error: Boolean,
        state: Number
    },
    data () {
        return {
            phone: '',
            checked: false
        }
    } ,
    methods:{
        phoneInput () {
            if(/\D/.test(this.phone) | this.phone.length > 10) {                
                this.phone = this.phone.substring(0, this.phone.length - 1)                
            } else {
                this.$emit("setPhone", this.phone)
            }

            
        }        
    }

    // setup (props, { emit }) {
    //     const toggleCheck = () => {
    //         emit('toggleCheck')
    //     }
    //     const submit = () => {
    //         emit('submit')
    //     }
    //     return {
    //         props, toggleCheck,submit
    //     }
    // }
}
</script>