<template>
    <div class="upload-wrapper">
        <div class="uw-title" v-text="title"></div>
        <slot />
        <div class="upload-operation">
            <div class="no-img" @click='uploadClick' v-show='!isShow'>
                <i class="icon icon-add"></i>
            </div>
            <div class="have-img"  @click='uploadClick' v-show="isShow">
                <i class="icon icon-finish"></i>
                <p class="img-cap">Finished</p>
            </div>
        </div>
        <div class="img-info">
            <div class="img-info-ele" v-for = "(item, ind) in needShowList" :key="ind">
                <div class="img-info-title" v-text="item.keys"></div>
                <div class="img-info-content" v-text="item.value"></div>
            </div>
        </div>
        <input v-if="invoke_type!='5'" ref='uploadInstance' type="file" :id="title" @change="change" accept="image/*" style='display:none'>
        <input v-if="invoke_type=='5'" ref='uploadInstance' type="file" :id="title" @change="change" accept="image/*" capture="camera" style='display:none'>
    </div>
</template>
<script>
import { ref, getCurrentInstance } from 'vue'
//newFace
import { ocr,handheld,liveBody } from '@api/index'
import { useLoading } from 'vue-loading-overlay'
import ImageCompressor from 'image-compressor.js'
export default {
    props:{
        title:{
            type:String
        },
        needShowList:{
            type:Object
        },
        invoke_type:{
            type:String
        },
        type_txt:{
            type:String
        }
    },
    setup(props, ctx) {      
        const isShow = ref(false)
        const uploadInstance = ref()
        const $loading = useLoading()
        const proxy  = getCurrentInstance().proxy
        const change = async (e) => {
            if (e.target.files[0].type.indexOf('image') > -1) {
                let file = e.target.files[0]
                let file_size = file.size / 1024 / 1024
                if (file_size < 10){                    
                            const loader = $loading.show({
                                container:null
                            })
                            let formData = new FormData()
                            if(props.invoke_type == 4) {
                                formData.append('type', props.invoke_type)
                                formData.append('file', file, file.name)                        
                                let data = await handheld(formData)                    
                                loader.hide()
                                if(data.code == 0) {                                    
                                    setvv(props.type_txt,[1] ) 
                                    isShow.value = true
                                } else {
                                    proxy.$toast.show(data.msg)
                                }
                            } else if (props.invoke_type == 5) {                        
                                formData.append('image', file,  file.name)                        
                                let data = await liveBody(formData)                    
                                loader.hide()
                                if(data.code == 0) {                                    
                                    setvv(props.type_txt,[1] ) 
                                    isShow.value = true
                                } else {
                                    e.target.value = ''
                                    proxy.$toast.show(data.msg)
                                }
                            } else {
                                formData.append('type', props.invoke_type)
                                formData.append('image', file,  file.name)
                                formData.append('notNeed', true)
                                let data = await ocr(formData)                    
                                loader.hide()
                                if(data.code == 0) {
                                    let temp = props.needShowList.map((ele)=>{
                                        if(data.data[ele.value_keys]){
                                            ele.value = data.data[ele.value_keys]
                                        }
                                        return ele
                                    })                            
                                    setvv(props.type_txt,temp.length ? temp : [1]  ) 
                                    isShow.value = true
                                } else {
                                    proxy.$toast.show(data.msg)
                                }
                            }    
                } else {                
                    const loader = $loading.show({
                        container:null
                    })
                    new ImageCompressor(file, {                    
                    quality: .5,
                    async success (new_file) {
                        const size = new_file.size / 1024 / 1024                    
                        const file = new File([new_file], 'test.jpeg', {type:'Blob'})                        
                        if(size > 10) {
                            loader.hide()
                            proxy.$toast.show('Image size is too big, limit 10MB')
                            return 
                        } else {                            
                            let formData = new FormData()
                            if(props.invoke_type == 4) {
                                formData.append('type', props.invoke_type)
                                formData.append('file', file, file.name)                        
                                let data = await handheld(formData)                    
                                loader.hide()
                                if(data.code == 0) {                                    
                                    setvv(props.type_txt,[1] ) 
                                    isShow.value = true
                                } else {
                                    proxy.$toast.show(data.msg)
                                }
                            } else if (props.invoke_type == 5) {                        
                                formData.append('image', file,  file.name)                        
                                let data = await liveBody(formData)                    
                                loader.hide()
                                if(data.code == 0) {                                    
                                    setvv(props.type_txt,[1] ) 
                                    isShow.value = true
                                } else {
                                    e.target.value = ''
                                    proxy.$toast.show(data.msg)
                                }
                            } else {
                                formData.append('type', props.invoke_type)
                                formData.append('image', file,  file.name)
                                formData.append('notNeed', true)
                                let data = await ocr(formData)                    
                                loader.hide()
                                if(data.code == 0) {
                                    let temp = props.needShowList.map((ele)=>{
                                        if(data.data[ele.value_keys]){
                                            ele.value = data.data[ele.value_keys]
                                        }
                                        return ele
                                    })                            
                                    setvv(props.type_txt,temp.length ? temp : [1]  ) 
                                    isShow.value = true
                                } else {
                                    e.target.value = ''
                                    proxy.$toast.show(data.msg)
                                }
                            }  
                        }
                    }
                })           
                }
                     
            }
        }
        const uploadClick = () => {
            uploadInstance.value.click()
        }
        const setvv = (k, v) => {
            ctx.emit("set_value", k, v)
        }
        return {
            change,
            uploadClick,
            isShow,
            uploadInstance
        }
    }
}
</script>
<style lang="less" scoped>
@import "assets/less/icon.less";
    .upload-wrapper{
        width: 574px;
        margin:0 auto 50px auto;
        border-radius: 15px;
        background:#fff;
        padding:25px 40px;
        .uw-title{
            font-size: 32px;
            color:#000;
            margin-bottom: 30px;
        }
        .upload-operation{
            .no-img{
                width: 276px;
                height:164px;
                background:#F5F3FF;
                display:flex;
                justify-content: center;
                align-items: center;
                border-radius: 15px;
            }
            .have-img{
                width: 276px;
                height:114px;
                background:#6D20D3;
                padding-top: 50px;                                
                border-radius: 15px;
                .img-cap{
                    color:#fff;
                    text-align: center;
                    margin-top: 10px;
                    font-size: 24px;
                }
            }
        }
        .img-info{
            margin-top: 30px;
            .img-info-ele{
                padding:25px 0;
                border-bottom:2px solid #EFEEF1;
                .img-info-title{
                    color:#73698F;
                    font-size: 26px;
                }
                .img-info-content{
                    color:#000;
                    font-size: 30px;
                    margin-top: 30px;
                }
            }
        }
    }
</style>