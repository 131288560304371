<template>
    <div class="wrapper">
         <!-- <p class="guide">
            Step <em>3</em> / 3
        </p>        -->
        <h1 class="title">Contact Information</h1>
        <p class="caption">
            In order to pass your loan review, please fill in the real and valid contact person, the incorrect contact person will cause the loan failure. 
        </p>
        <ContactElement
            title="Urgent Contact"
            :choices="parents"
            :is_show = "data['one'].is_show"
            :data = "data['one']"
            key_name="one"
            @setValue="setValue"
        ></ContactElement>
        <ContactElement
            title="Urgent Contact"
            :choices="brothers"
            :is_show = "data['two'].is_show"
            :data = "data['two']"
            key_name="two"
            @setValue="setValue"
        ></ContactElement>
        <ContactElement
            title="Urgent Contact"
            :choices="friends"
            :is_show = "data['third'].is_show"
            :data = "data['third']"
            key_name="third"
            @setValue="setValue"
        ></ContactElement>
        <p class="caption">
            Fill out the following information to get a higher credit
        </p>
        <div class="contact-element">
            <div class="ce-title">Social App</div>
            <div class="ce-content">                
                <div class="element-wrapper">
                    <div class="element-title">
                            <h3>FaceBook</h3>
                            <i class="icon"></i>                
                        </div>
                    <div class="element-content">
                        <input type="text"  v-model="facebook"  placeholder="Please Input">
                    </div>
                </div>
                <div class="element-wrapper">
                    <div class="element-title">
                            <h3>Whatapp</h3>
                            <i class="icon"></i>                
                        </div>
                    <div class="element-content">
                        <input type="text" v-model="whatapp" placeholder="Please Input">
                    </div>
                </div>
                <div class="element-wrapper">
                    <div class="element-title">
                            <h3>Instagram</h3>
                            <i class="icon"></i>                
                        </div>
                    <div class="element-content">
                        <input type="text" v-model="instagram" placeholder="Please Input">
                    </div>
                </div>                
            </div>    
        </div>
        <div class="placeholder"></div>
        <div class="btn-container">            
            <a class='btn-submit' @click.prevent="submit">CONFIRM TO NEXT</a>
        </div>
    </div>
    
</template>
<script>
import ContactElement from '@/components/business/kyc/contact_element.vue'
import { submit_contact2 } from '@api/index'
export default {
    components:{
        ContactElement
    },
    data () {
        return {
            data:{
                "one":{
                    "name":{
                        value:"",
                        error:false
                    },
                    "mobile":{
                        value:"",
                        error:false
                    },
                    "relation":{
                        value:"",
                        error:false
                    },
                    "is_show":false
                },
                "two":{
                    "name":{
                        value:"",
                        error:false
                    },
                    "mobile":{
                        value:"",
                        error:false
                    },
                    "relation":{
                        value:"",
                        error:false
                    },
                    "is_show":false
                },
                "third":{
                    "name":{
                        value:"",
                        error:false
                    },
                    "mobile":{
                        value:"",
                        error:false
                    },
                    "relation":{
                        value:"",
                        error:false
                    },
                    "is_show":false
                },
                instagram:"",
                whatapp:"",
                facebook:""
            },
            parents:[
                {
                    "dictLabel": "Father",
                    "dictValue": "1",
                    "dictType": "one"
                },
                {
                    "dictLabel": "Mother",
                    "dictValue": "2",
                    "dictType": "one"
                },
                {
                    "dictLabel": "Spouse",
                    "dictValue": "3",
                    "dictType": "one"
                }
            ],
            brothers:[
                {
                    "dictLabel": "brother",
                    "dictValue": "1",
                    "dictType": "twoRelation"
                },
                {
                    "dictLabel": "sisters",
                    "dictValue": "2",
                    "dictType": "twoRelation"
                },
                {
                    "dictLabel": "Children",
                    "dictValue": "3",
                    "dictType": "twoRelation"
                }
            ],
            friends: [
                {
                    "dictLabel": "Friends",
                    "dictValue": "1",
                    "dictType": "third"
                }
            ],
            clicked:false,
            token:this.$route.query.token
        }
    },
    methods: {
        setValue(obj_name,key_name,v) {
            if(typeof (this.data[obj_name][key_name].value) != 'undefined'){
                this.data[obj_name][key_name].value = v 
                this.data[obj_name][key_name].error = false
            } else {
                this.data[obj_name][key_name] = v
            }
            
        },
        validate () {
            let res = true
            for(let x in this.data) {
                for(let y  in this.data[x]) {
                    if(y=='is_show')continue
                    if(this.data[x][y].value === '') {
                        this.data[x][y].error = true
                        res = false
                    }
                }
            }            
            return res
        },
        submit () {    
            if(!this.$route.query.token){
                this.$toast.show("Token illegal")     
                return
            }                
            if(this.validate() && !this.clicked) {
                this.clicked = true
                const loader = this.$loading.show({
                    container: null
                })
                submit_contact2({
                    "oneName":this.data['one'].name.value,
                    "oneMobile": this.data['one'].mobile.value,
                    "oneRelation":this.data['one'].relation.value,
                    "twoName":this.data['two'].name.value,
                    "twoMobile": this.data['two'].mobile.value,
                    "twoRelation":this.data['two'].relation.value,
                    "thirdName":this.data['third'].name.value,
                    "thirdMobile": this.data['third'].mobile.value,
                    "thirdRelation":this.data['third'].relation.value,
                    "whatapp":this.whatapp,
                    "facebook":this.facebook,
                    "instagram":this.instagram,
                }, this.token).then((res)=>{
                    if(res.code == 0) {
                        window.JavascriptChannel.postMessage('{"action": "finish"}')
                    }
                    loader.hide()
                    this.clicked = false
                })    
            }                        
        }
    },
    mounted () {
        document.body.style.background = "#F7F7F7"
        document.documentElement.scrollTop = 0
        if(!this.$route.query.token){
           this.$toast.show("Please login")     
           return
        }        
        // 不从后端加载，先写死在前端
        // contact_relation2(this.token).then((data)=>{
        //     this.parents = data.data[0].dictionaryVoList
        //     this.brothers = data.data[2].dictionaryVoList
        //     this.friends = data.data[1].dictionaryVoList
        // })
        
    }
}
</script>
<style lang="less" scoped>
    .wrapper{
        padding:40px;
        .guide{
            color:#A59BB3;
            font-size: 30px;
            em{
                color:#6D20D3;
                font-weight: 700;
            }
        }
        .title{
            color:#6D20D3;
            font-size: 40px;
            margin: 0 0 10px 0;  
        }
    }
    .caption{
        background:#E3D4FC;
        font-size: 26px;
        padding:15px;
        margin-bottom: 15px;
        line-height: 1.5;
        color:#6d20d3;
        border-radius: 18px;
    }
    .btn-container{
        position: fixed;
        left:0;
        bottom: 0;        
        background:#fff;
        padding:40px;
        // padding:40px;
        .btn-submit{
            display: block;
            width: 670px;
            height: 96px;
            line-height: 96px;
            text-align: center;
            border-radius: 12px;
            background:#6D20D3;
            font-size: 32px;
            margin:0 auto;
            color:#fff;
        }
    }
    .placeholder{
        height: 176px;        
    }
    .contact-element{
    // width: 654px;
    border-radius: 15px;
    overflow: hidden;
    background: #fff;;
    margin-bottom: 10px;
    .ce-title{
        height:30px;
       line-height: 30px;
       color:#fff;
       font-size: 32px;
       font-weight: 700;
       padding:20px 40px;
       background:#9E8FE7;
    }
    div.ce-content{
        padding:40px;
    }
}
.element-wrapper{
    padding-bottom: 10px;
    border-bottom: 2px solid #EFEEF1;
    position: relative;
    margin-bottom: 10px;
    &.is_error{
        border-bottom:  2px solid #C5340B;       
    }
    .element-title{
        
        color:#73698F;
        font-size: 26px;  
        overflow: hidden;
        padding:10px 0;      
        h3{
            float:left;
        }
        .icon-exclaim{
            float:left;
            margin-left: 20px;
        }
        .icon-arrow-down{
            float:right
        }
    }
    .element-content-default{
        margin-top: 0px;
        color:#D0CDDA;
        font-size: 32px;
        height:40px;
        line-height: 40px;
    }
    .element-content{
        margin-top: 0px;
        color:#000;
        font-size: 36px;
        height:40px;
        line-height: 40px;
        display: flex;
        align-items: center;
    }
    .element-content{
        margin-top: 0px;
        color:#000;
        height:40px;
        line-height: 40px;
        font-size: 32px;
        span{
            margin-right: 20px;
        }
        input{
            display:block;
            width: 100%;
            font-size: 32px;
            border:none;
            &::placeholder{
                color:#D0CDDA;
            }
        }
    }
    .choice-container{  
        // display: none;      
        position: absolute;
        width: 505px;
        padding:0 30px;      
        background:#fff; 
        z-index:99; 
        box-shadow: 0px 0px 44px 0px rgba(9, 14, 118, 0.08);
        top:50px;
        .choice-element{            
            border-bottom: 2px solid #EFEEF1;
            padding:20px 40px;
            color:#000;
            font-size: 32px;
            &.active{
                color:#6D20D3;
            }
        }
    }
}
</style>