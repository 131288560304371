<template>
    <div class="wrapper">
        <div class="home-wrapper">
            <router-view></router-view>
        </div>
        <div class="placeholder"></div>
        <div class="bottom-nav">
            <ul class="nav-list">                
                <router-link to="/"  tags='li' class="nav-element">
                    <i class="icon icon-home"></i>
                    <p class="nav-text">Home</p>
                </router-link>
                <router-link to="/orders" class="nav-element" tag='li'>
                    <i class="icon icon-order"></i>
                    <p class="nav-text">Order</p>
                </router-link>
                <router-link to="/personal"  class="nav-element" tag='li'>
                    <i class="icon icon-personal"></i>
                    <p class="nav-text">Personal</p>
                </router-link>                
            </ul>
        </div>

    </div>
</template>
<style lang="less" scoped>
    @import "assets/less/icon.less";
    .wrapper{
        .home-wrapper{
            width: 100%;
            background:#fff;
        }
        .placeholder{
            height: 175px;
            width:750px;
            bottom:0            
        }
    }
    .bottom-nav{
        position:fixed;
        bottom:0;
        box-shadow: 0px 0px 44px 0px rgba(9, 14, 118, 0.08);
        padding:30px 0 0;
        z-index: 99;
        background:#fff;
        .nav-list{            
            .nav-element{
                width: 250px;
                height: 112px;
                float:left;
                // display:flex;
                justify-content: center;
                text-decoration:none;
                .nav-text{
                    text-align: center;
                    color:#A59BB3;
                    font-size: 24px;
                    &.active{
                        color:#6D20D3;
                        font-size: 24px;
                    }
                }
            }
            &.router-link-exact-active{
                .nav-text{
                    color:#6D20D3;
                }
            }
        }

    }
</style>