<template>
    <div class="wrapper">
        <div class="banner">
            <div class="left"></div>
            <div class="right"></div>
            <h1 class="title">
                Welcome to<br>
                Prime cash !
            </h1>
        </div>
        <ul class="list">
            <!-- <li class="element red">
                <router-link to="/download">
                    <i class="icon icon-vc"></i>
                    <p class="text">Verification Center</p>
                </router-link>
            </li> -->
            <!-- <li class="element green">
                <router-link to="/download">
                    <i class="icon icon-ba"></i>
                    <p class="text">Bank Account</p>
                </router-link>
            </li> -->
            <li class="element deepblue">
                <router-link to="/orders">
                    <i class="icon icon-lh"></i>
                    <p class="text">Loan History</p>
                </router-link>    
            </li>
            <li class="element skyblue">
                <router-link to="/personal/help">
                    <i class="icon icon-hc"></i>
                    <p class="text">Help Center</p>
                </router-link>
            </li>
            <li class="element orange">
                <router-link to="/personal/aboutus">
                    <i class="icon icon-au"></i>
                    <p class="text">About Us</p>
                </router-link>    
            </li>
            <li class="element pupple">
                <a href="javascript:void(0)" @click="quit">
                    <i class="icon icon-setting"></i>
                    <p class="text">Quit</p>
                </a>
            </li>
            <li class="element deepblue" v-if="showActivityValue == 1">
                <router-link to="/promotion/recruit">
                    <i class="icon icon-pc"></i>
                    <p class="text">Invite Friend</p>
                </router-link>    
            </li>
        </ul>
    </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { showActivity } from '@api/index.js'
export default {
    setup () {
        const showActivityValue = ref(0)
        const store = useStore()        
        const router = useRouter()
        const quit = () => {
            store.dispatch('clearToken')
            router.replace({
                path: '/login'
            })                    
        }
        onMounted( ()=>{
                showActivity().then(res => {
                    if(res.code == 0) {
                        if(res.data.showActivity) {
                            showActivityValue.value = 1    
                        }                                        
                    }
                })
            } )
        return { quit,showActivityValue }
        
    }
}
</script>
<style lang="less" scoped>
@import "assets/less/icon.less";
    .wrapper{
        .list{
            overflow:hidden;
            padding:0 35px;
            .element{
                float:left;
                width: 208px;
                height:172px;
                margin-right: 24px; 
                padding:30px 0;  
                margin-bottom: 30px;
                border-radius: 15px;                         
                display: flex;
                flex-direction: column;
                justify-content: center;
                .text{
                    text-align: center;
                    font-size: 24px;
                    color:#333333;
                    margin-top: 20px;
                }
                &:nth-child(3) {
                    margin-right: 0;
                }
                &:nth-child(6) {
                    margin-right: 0;
                }
                &.red{
                    background-color:#FDF4F5;
                }
                &.green{
                    background-color:#EFF9F8;
                }
                &.deepblue{
                    background-color:#F2F6FE;
                }
                &.skyblue{
                    background-color:#F3FBFF;
                }
                &.orange{
                    background-color:#FEFAF2;
                }
                &.pupple{
                    background-color:#F5F3FF;
                }
            }
        }
        .banner{
            position:relative;
            height:500px;
            .title{
                font-size: 56px;
                color:#000;
                position:absolute;
                top:200px;
                left:70px;
                line-height: 1.5;
            }
            .left{
                position: absolute;
                top:0;
                left:0;
                width:430px;
                height:456px;
                background:url("~assets/images/personal/banner-left.png") no-repeat;
                background-size: 100% 100%;
            }
            .right{
                position: absolute;
                right:0;
                top:85px;
                width:326px;
                height:360px;
                background:url("~assets/images/personal/banner-right.png") no-repeat;
                background-size: 100% 100%;
            }
        }
    }
</style>