<template>
    <div class="element-wrapper" @click="showMenu" :class="{'is_error': is_error}">
        <div class="element-title">
            <h3 v-text="title"></h3>
            <i class="icon" :class="{'icon-exclaim':is_error }"></i>
            <i class="icon icon-arrow-down" :class="{'icon-arrow-up':is_show}"></i>
        </div>
        <ul class="choice-container" v-show="is_show">
            <li class="choice-element" :class="{'active': selected_ind === ind}" v-for="(item, ind) in choices" :key="ind" @click="selected($event,ind)" v-text="item.dictLabel"></li>
        </ul>
        <div class="element-content-default" v-show='selected_ind == null' v-text="label"></div>
        <div class="element-content" v-show='selected_ind != null' v-text="selected_ind == null ?'':choices[selected_ind].dictLabel"></div>
    </div>
</template>
<script>
import { ref } from 'vue'
export default {
    props:{
        title:{
            type:String
        },
        label:{
            type:String,
            default:'Please Select'
        },
        choices:{
            type:Object
        },
        required:{
            type:Number
        },
        keys:{
            type:String
        },
        is_show:{
            type:Boolean
        },
        is_error:{
            type:Boolean
        },
        component_id:{
            type:Number
        }
    },
    setup (props, { emit }) {        
        const selected_ind = ref()        
        const show_error = ref(false)
        const selected = (e, ind) => {
            e.stopPropagation();                        
            selected_ind.value = ind
            emit("setValue",props.keys, props.choices[ind].dictValue)            
            emit('modify', props.component_id, false)            
            emit("modifyError", props.component_id, false)
        }
        const showMenu = () => {                 
            emit('modify', props.component_id, true)            
        }        
        const showError = () => {
            if(props.required & !selected_ind.value){
                show_error.value = true
            }
        } 
        return {
            selected_ind,
            selected,
            showMenu,            
            show_error,
            showError
        }
    }
}
</script>
<style lang="less" scoped>
@import "assets/less/icon.less";
.element-wrapper{
    padding-bottom: 30px;
    border-bottom: 2px solid #EFEEF1;
    position: relative;
    margin-bottom: 30px;
    &.is_error{
        border-bottom:  2px solid #C5340B;       
    }
    .element-title{
        
        color:#73698F;
        font-size: 26px;  
        overflow: hidden;
        padding:15px 0;      
        h3{
            float:left;
        }
        .icon-exclaim{
            float:left;
            margin-left: 20px;
        }
        .icon-arrow-down{
            float:right
        }
    }
    .element-content-default{
        margin-top: 30px;
        color:#D0CDDA;
        font-size: 36px;
        height:40px;
        line-height: 40px;
    }
    .element-content{
        margin-top: 30px;
        color:#000;
        font-size: 36px;
        height:40px;
        line-height: 40px;
    }
    .choice-container{  
        // display: none;      
        position: absolute;
        width: 575px;
        padding:0 30px;      
        background:#fff; 
        z-index:99; 
        box-shadow: 0px 0px 44px 0px rgba(9, 14, 118, 0.08);
        top:50px;
        .choice-element{            
            border-bottom: 2px solid #EFEEF1;
            padding:40px;            
            color:#000;
            font-size: 32px;
            &.active{
                color:#6D20D3;
            }
        }
    }
}
    
</style>