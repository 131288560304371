<style lang="less" scoped>
    .promotion-wrapper{
        background:#FFE1DE;        
    }
    .top-nav{

    }
    .banner{
        width: 750px;
        height: 904px;
        background: url("../../assets/images/promotion/recruit/banner.png") no-repeat;
        background-size:100% 100%;
        position: absolute;
        top:0;        
    }
    .banner-title{
        width: 660px;
        height: 76px;
        background: url("../../assets/images/promotion/recruit/banner-title.png") no-repeat;
        background-size:100% 100%;
        top:130px;
        position: absolute;
        left:50%;
        transform: translateX(-50%);
    }
    .invite-caption{
        width: 626px;
        height: 80px;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, #FFFFFF 26%, #FFFFFF 79%, rgba(255,255,255,0) 100%);
        margin:0 auto;        
        text-align: center;
        color:#FF5C5C;
        font-size: 32px;
        line-height: 80px;
        margin-top: 30px;
    }
    .wrapper-inner{
        position: relative;
        padding-top: 200px;
    }
    .explain{
        position:absolute;
        top:500px;
        right:0;
        width: 140px;
        height: 60px;
        background: linear-gradient(90deg, #B1FC93 0%, #00F9CF 100%);
        border-radius: 200px 0px 0px 200px;
        line-height: 60px;
        color:#333;
        text-align: center;
        font-size: 26px;
        z-index: 2;
    }
    .forshow{
        width: 610px;
        padding:40px;
        background:#FFF5F0;
        margin:280px auto 0 auto;
        border-radius: 50px;
        position: relative;
        .invitation-title{
                width: 240px;
                height: 44px;
                background: linear-gradient(90deg, #B1FC93 0%, #00F9CF 100%);
                border-radius: 22px;
                border-radius: 44px;
                font-size: 24px;
                text-align: center;
                line-height: 44px;
                position: absolute;
                top:340px;
                left:20px;
        }
        .forshow-title{
            font-size: 32px;
            color:#525252;
            text-align: center;
            line-height: 1.5;
        }
        .invite_link{
            width: 570px;
            height: 98px;
            padding:40px 30px 20px 30px;
            background:#FFE1DE;
            color:#525252;
            font-size: 36px;
            border-radius: 20px;
            line-height: 1.2;
            margin-bottom: 30px;
            word-break: break-all;
            position: relative;
            
        }
        .award{
            color:#FF4C54;
            font-size: 64px;
            font-weight: 700;
            text-align: center;
            margin-top: 30px;
            strong{
                font-size: 88px;
            }
        }
        .placeholder{
            background:url("../../assets/images/promotion/recruit/placeholder.png") no-repeat;
            background-size: 100%;
            width: 570px;
            height: 6px;
            margin:50px auto;
        }
        .invite-now{
            display:block;
            width: 508px;
            height: 108px;
            line-height: 108px;
            font-size: 44px;
            color:#fff;
            text-align: center;            
            background:url("../../assets/images/promotion/recruit/btn-bg.png") no-repeat;
            background-size:100%;
            margin:0 auto;
        }
    }
    .caption{
        color:#FF515A;
        text-align: center;
        font-size: 26px;
        margin:40px 0;
    }
    .history{
        width: 610px;
        height: 380px;
        padding:0 40px 40px;
        background:#FFF5F0;
        margin: 50px auto 0 auto;
        border-radius: 50px;
        .common-top{
            width: 446px;
            height: 88px;
            background: linear-gradient(180deg, #FF5B5B 0%, #FFCDCD 100%);
            border-radius: 0 0 50px 50px;
            display:flex;
            justify-content:space-evenly;
            align-items:center;
            margin:0 auto;
            .icon-fork{
                display:block;
                width: 20px;
                height: 20px;
                background:url("../../assets/images/promotion/recruit/star.png") no-repeat;
                background-size:100%;
            }
            .text{
                color:#fff;
                font-size:28px;
            }
        }
        .info{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 50px;            
            .info-placeholder{
                    width: 5px;
                    height: 124px;
                    background:#FF6B2C;
                }
            .info-left{ 
                width: 300px;               
                .il-text{
                    color:#FF0000;
                    font-size:48px;
                    text-align:center;
                    margin-bottom: 30px;
                }                
                .il-caption{
                    color:#3C3C3C;
                    font-size:24px;
                    text-align:center;
                }
            }            
        }
        .btn-records{
                display: block;
                width: 460px;
                height: 84px;
                background: linear-gradient(180deg, rgba(255,91,91,0.2) 0%, rgba(255,205,205,0) 100%);
                border-radius: 56px;
                border: 2px solid #FF5C5C;
                line-height: 84px;
                color:#FF5C5C;
                text-align: center;
                font-size: 30px;
                margin:30px auto 0 auto;
            }
    }
    .intro-text{
        font-size: 24px;
        color: #FF5C5C;
        text-align: center;
        line-height: 1.5;
        padding: 50px 0;
        strong{
            font-size: 30px;
            font-weight: 700;
        }
    }
    .invitation{
        width: 610px;
        height: 280px;
        padding:0 40px 40px;
        background:#FFF5F0;
        margin: 0 auto 0 auto;
        border-radius: 50px;
        .common-top{
            width: 446px;
            height: 88px;
            background: linear-gradient(180deg, #FF5B5B 0%, #FFCDCD 100%);
            border-radius: 0 0 50px 50px;
            display:flex;
            justify-content:space-evenly;
            align-items:center;
            margin:0 auto;
            .icon-fork{
                display:block;
                width: 20px;
                height: 20px;
                background:url("../../assets/images/promotion/recruit/star.png") no-repeat;
                background-size:100%;
            }
            .text{
                color:#fff;
                font-size:28px;
            }
        }
        .in-content{
            position: relative;
            .in-img{
                margin:50px auto;
                width: 492px;
                height: 48px;
                img{
                    width: 100%;
                    height:100%;
                }
            }
            .one{
                position: absolute;
                font-size: 24px;
                top:75px;
                color:#3C3C3C;
                left:40px;
                line-height: 1.1;
            }
            .two{
                position: absolute;
                font-size: 24px;
                top:75px;
                color:#3C3C3C;
                left:190px;
                line-height: 1.1;
            }
            .three{
                position: absolute;
                font-size: 24px;
                top:75px;
                color:#3C3C3C;
                left:340px;
                line-height: 1.1;
            }
            .four{
                position: absolute;
                font-size: 24px;
                top:75px;
                color:#3C3C3C;
                left:490px;
                line-height: 1.1;
            }
        }
    }
    .dialog-record{
        position: fixed;
        width: 100%;
        height: 100%;
        left:0;
        top:0;
        bottom:0;
        right:0;
        background:#FFE1DE;
        z-index: 10;
        .dialog-content{
            position:absolute;
            top:80px;
            left:30px;
            width: 690px;
            z-index: 11;
            .dialog-banner{
                position: relative;
                z-index: 2;
                width: 610px;
                height: 264px;
                padding:40px;
                background:url("../../assets/images/promotion/recruit/records-bg.png") no-repeat;
                background-size: 100% 100%;
                margin:0 auto;
                .db-title{
                    font-size: 64px;
                    text-align: center;
                    color:#fff;
                    margin-bottom: 15px;
                }
                .db-caption{
                    font-size: 28px;
                    color:#fff;
                    margin-bottom: 15px;
                    text-align: center;
                }
                .db-placeholder{
                    width:610px;
                    height: 1px;
                    border-bottom: 4px dashed #fff;
                    margin-bottom: 30px;
                    margin-top: 30px;
                }
                .db-intro{
                    text-align: center;
                    color:#fff;
                    line-height: 1.5;
                    font-size: 24px;
                }
            }
            .dialog-record-content{
                padding:100px 30px 80px 30px;
                background:#FFF9F6;
                border-radius: 0 0 20px 20px;
                margin-top: -50px;
                .dialog-record-header{
                    margin-bottom: 60px;
                    .drh-cell{
                        float:left;
                        width: 190px;
                        margin-right: 20px;
                        text-align: center;
                        color:#525252;
                        font-weight: 700;
                        font-size: 24px;
                        &:nth-child(3){
                            margin-right: 0;
                        }    
                    }
                    
                }
                .dialog-record-element{
                    overflow:hidden;
                    margin-top: 40px;
                    .dre-cell{
                        float:left;
                        width: 190px;
                        margin-right: 20px;
                        text-align: center;
                        color:#525252;
                        font-weight: 700;
                        font-size: 24px;
                        &:nth-child(3){
                            margin-right: 0;
                        }                           
                    }
                }
                .dialog-btn{
                    width: 590px;
                    margin: 50px auto 0 auto;
                    .invite-now{
                        display: block;
                        width: 590px;
                        height: 100px;
                        line-height: 100px;
                        text-align: center;
                        color:#fff;
                        font-size: 28px;
                        background: linear-gradient(180deg, #FF5B5B 0%, #FFCDCD 100%);
                        border-radius: 100px;
                    }
                }
            }
        }
    }
    .icon-close{
        display:block;
        background: url("~assets/images/promotion/recruit/icon-dialog-close.png") no-repeat;
        width: 50px;
        height: 50px;
        background-size:100%;
        position:fixed;
        right:30px;
        top:20px;
    }
    .marquee{
        position: absolute;
        width: 750px;
        height:50px;
        padding:20px 0;
        background:rgba(0,0,0,0.2);
        top:0;
        z-index:100;
        .m-content{
            display:block;
            height:70px;
            line-height: 50px;
            width: 750px;
            margin-bottom: 30px;
            text-align: center;
            font-size: 32px;
            color:#fff;
        }
    }
    .dialog-no-record{
        padding:150px 0;
        text-align: center;
        color:#BDB9B9;
        font-size: 24px;
        line-height: 1.5;
    }
</style>
<template>
    <div class='promotion-wrapper'>
        <div class='marquee'>
            <Vue3Marquee :duration="40"> 
                <span class='m-content' v-for="element in marquee" :key="element" v-text="`${element.phone}, just received a reward of ₹${element.amount}`"></span>                
            </Vue3Marquee>
        </div>
         
        <div class="banner"></div>
        <div class="banner-title"></div>
        <div class="explain" @click="openRuleDialog">Explain</div>
        <div class="wrapper-inner">
            <div class="invite-caption">Invite more, earn more, no limit</div>
            <div class="forshow">
                <h2 class="forshow-title">
                    For every friend you invite,<br> you'll earn cash.
                </h2>
                <p class="award">
                    ₹ <strong>100.00</strong>
                </p>
                <div class="placeholder"></div>
                <template v-if="logined == 1">
                    <div class="invite_link" v-text="url"></div>
                    <div class="invitation-title">
                        Invitation Link
                    </div>
                    <a href="javascript:void(0);" @click="copyFn" class="invite-now" v-text="btnText"></a>    
                </template>
                <template v-if="logined == 2">
                    <a href="javascript:void(0);"  class="invite-now" @click="openLoginDialog" v-text="btnText"></a>    
                </template>
            </div>
            
            <p class="caption">
                Campaign Period: 15/11/2023 - 24/11/2023
            </p>
            <div class="invitation">
                <div class="common-top">
                    <i class="icon icon-fork"></i>
                    <span class='text'>Invitation Strategy</span>
                    <i class="icon icon-fork"></i>
                </div>
                <div class="in-content">
                    <div class="in-img">
                        <img src="../../assets/images/promotion/recruit/step.png" alt="">
                    </div>
                    <div class='one'>Copy the<br> link</div>
                    <div class='two'>Send to <br>friends</div>
                    <div class='three'>Friends <br>apply and <br>get money</div>
                    <div class='four'>Earn <br>rewards</div>
                </div>
            </div>
            <div class="history">
                <div class="common-top">
                    <i class="icon icon-fork"></i>
                    <span class='text'>Reward history</span>
                    <i class="icon icon-fork"></i>
                </div>
                
                <div class="info">
                    <div class="info-left">
                        <p class="il-text" v-text="statics.userNum"></p>
                        <p class="il-caption">Number of Invites</p>
                    </div>
                    <div class="info-placeholder"></div>
                    <div class="info-left">
                        <p class="il-text" v-text="`₹${statics.totalAmount}`"></p>
                        <p class="il-caption">Total Rewards</p>
                    </div>
                </div>
                <a href="javascript:void(0);" class="btn-records" @click="recordsShow = true">
                    View Invitation Records
                </a>
            </div>
            <div class="intro-text">
                <strong>Hurry, invite friends to make money!</strong><br>
                @Instarupee has the final say.
            </div>
           
        </div>
        <div class="dialog-record" v-show="recordsShow">
            <div class="dialog-content">
                <i class="icon-close" @click="recordsShow = false"></i>
                <div class="dialog-banner">
                    <h2 class="db-title" v-text="`₹${statics.totalAmount}`"></h2>
                    <p class="db-caption">Total Rewards</p>
                    <div class="db-placeholder"></div>
                    <p class="db-intro">
                        You will only receive a reward when the invitee has funded their loan.
                    </p>
                </div>
                <div class="dialog-record-content">
                    <div class="dialog-record-header">
                        <div class="drh-cell">My Friends</div>
                        <div class="drh-cell">Friend Status</div>
                        <div class="drh-cell">Earned Rewards</div>
                    </div>
                    <div class="dialog-record-element" v-for="element in records" :key="element"  v-show="records.length">
                        <div class="dre-cell" v-text="element.phone"></div>
                        <div class="dre-cell" v-text="element.remark"></div>
                        <div class="dre-cell" v-text="element.amount"></div>
                    </div>
                    <div class="dialog-no-record"  v-show="records.length == 0">
                        Sorry, there is no invitation record.<br>
                        You can invite friends and get cash!
                    </div>
                    <div class="dialog-btn">
                        <a href="javascript:void(0);" @click="copyFn2" class="invite-now" v-text="btnText2"></a>
                    </div>
                </div>
                
            </div>
        </div>
        <dialog-rule :ruleShow="ruleShow" @closeRuleDialog="closeRuleDialog"></dialog-rule>  
        <dialog-login :loginShow="loginShow" @closeLoginDialog="closeLoginDialog"></dialog-login>
    </div>
</template>
<script>
import { reactive,ref, onMounted,getCurrentInstance } from 'vue'
import { promotionInviteLink, promotionMarquee,promotionStatic,promotionRecord } from '@api/index.js'
import DialogRule from '../../components/promotion/recruit/rule.vue'
import DialogLogin from '../../components/promotion/recruit/login.vue'
import { Vue3Marquee } from 'vue3-marquee'

export default {
    components:{
        DialogRule,
        DialogLogin,
        Vue3Marquee
    },
    setup() {
        const records = ref([])
        const marquee = ref([])
        const recordsShow = ref(false)
        const instance = getCurrentInstance().proxy
        const statics = reactive({
            "userNum":"*",
            "totalAmount": "**"
        })
        const copyFn = () => {
            console.log(url.value)
                instance.$copyText(url.value).then(function () {                    
                    btnText.value = "Copied"
                }, function () {
                })
        }
        const copyFn2 = () => {
            console.log(url.value)
                instance.$copyText(url.value).then(function () {                    
                    btnText2.value = "Copied"
                }, function () {
                })
        }
        const url = ref("")
        onMounted( async ()=>{
            let data = await promotionInviteLink()
            if(data.code == 0) {
                logined.value = 1
                url.value = data.data                
            } else {
                logined.value = 2
            }
            promotionMarquee().then((data)=>{
                if(data.code == 0) {
                    marquee.value = data.data
                }
            })
            promotionStatic().then((res)=>{
                if(res.code == 0) {
                    statics.userNum = res.data.userNum
                    statics.totalAmount = res.data.totalAmount
                }
            }),
            promotionRecord().then(res => {
                if(res.code == 0) {
                    records.value = res.data
                }
            })
        })
        const obj = reactive({
            phone:"",
            code:""
        })
        let btnText = ref("Invite Now")
        let btnText2 = ref("Copy the link, keep inviting")
        let logined = ref(2)
        // rule dialog
        const ruleShow = ref(false)
        const loginShow = ref(false)

        const closeLoginDialog = (param) => {            
            loginShow.value = param            
        }
        
        const openLoginDialog = () => {
            loginShow.value = true
        }

        const closeRuleDialog = (param) => {            
            ruleShow.value = param            
        }
        
        const openRuleDialog = () => {
            ruleShow.value = true
        }
        
        return {
            obj,
            ruleShow,
            loginShow,
            logined,
            btnText,
            btnText2,
            recordsShow,
            marquee,
            records,
            statics,
            url,
            closeRuleDialog,
            closeLoginDialog,
            openRuleDialog,
            openLoginDialog,
            copyFn,
            copyFn2
        }
    }
}




</script>