<template>
  <div id="app">
    <router-view></router-view>
    <Toast />
  </div>
</template>

<script>
import Toast from '@/components/common/toast.vue'
export default {
  name: 'App',
  inject:['$toast'],
  components:{
      Toast
  }
}
</script>
<style lang="less">
  #app{
    font-family: Helvetica, Arial, Helvetica, sans-serif;
  }
</style>