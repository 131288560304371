<template>
    <div class="wrapper">
        <div class="top-content">
            <div class="top-content-element" @click="showMenu">
                <h3 class="title">Loan Amount</h3>                
                <div class="min_max">
                    <span v-text="obj.selectAmount"></span>
                    <i class="icon" :class='{"icon-white-arrow-up":list_show , "icon-white-arrow-down":!list_show}'></i>  
                </div>
                <ul class="amount-list" v-show='list_show'>
                    <li class="amount-element" @click.prevent="selected($event, ind)" :class="{'active': ind === selected_ind}" v-for="(ele, ind) in obj.canChooseAmount" :key="ind" v-text="ele"></li>
                </ul>
            </div>    
            <div class="info-wrapper">
                <div class="info-list">
                    <div class="il-element">Repayment Amount</div>
                    <div class="il-element strong" v-text="`₹${obj.repaymentAmount}`"></div>
                </div>
                <div class="info-list">
                    <div class="il-element">Apply Date</div>
                    <div class="il-element strong" v-text="`${obj.applyTime}`"></div>
                </div>
            </div>        
            
        </div> 
        <div class="detail-info">
            <ul class="detail-list">
                <!-- <li class="detail-list-element"><span class="left-text">Processing Fee</span><span class="right-text" v-text="`₹${obj.serviceFee}`"></span></li> -->
                <!-- <li class="detail-list-element"><span class="left-text">GST18% on Processing Fee</span><span class="right-text" v-text="`₹${obj.gtsFee}`"></span></li>-->
                <li class="detail-list-element"><span class="left-text">Interest Fee</span><span class="right-text" v-text="`₹${obj.interest}`"></span></li>                                 
            </ul>
        </div> 
         <ul class="other-info">
            <li class="oi-element">
                <!-- <router-link :to="`/bank?pid=${$route.params.pid}`"> -->
                <div class="left-content">
                    <h4 class="oi-title">Bank Account</h4>
                    <div class="oi-content"  v-text="obj.bankNo"></div>                        
                </div>                    
                <!-- </router-link> -->
            </li>
         </ul>            
        <div class="btn-container">
            <a class="btn-submit" @click.prevent="getMoney">Get Money</a>
        </div>   
    </div>
</template>
<script>
import { orderDetail, confirm } from "@api/index.js";
import { onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useLoading } from 'vue-loading-overlay'
export default {
    setup () {
        const router = useRouter()
        const route = router.currentRoute.value
        const loading = useLoading()
        const list_show = ref(false)
        const selected_ind = ref(0)
        const obj = reactive({
            loanAmount:0,            
            repaymentAmount: "", 
            repaymentDate: "", 
            serviceFee: "", 
            gtsFee: "", 
            bankNo: "", 
            applyTime: "", 
            canChooseAmount:[]
        })
        onMounted(()=>{
            const loader = loading.show({
                container:null
            })
            orderDetail({
                orderId: route.params.id
            }).then((res)=>{
                loader.hide()
                if(res.code == 0) {
                    selected_ind.value = 0
                    obj.loanAmount = res.data.loanAmount
                    obj.repaymentAmount = res.data.repaymentAmount
                    obj.repaymentDate = res.data.repaymentDate
                    obj.serviceFee = res.data.serviceFee
                    obj.interest = res.data.interest
                    obj.gtsFee = res.data.gtsFee
                    obj.bankNo = res.data.bankNo
                    obj.applyTime = res.data.applyTime
                    obj.canChooseAmount = res.data.canChooseAmount
                    obj.selectAmount = res.data.canChooseAmount[0]
                }
            })
        })
        const showMenu = (e) => {
            e.stopPropagation();
            list_show.value = true
        }
        const getMoney = () => {
            const loader = loading.show({
                container:null
            })
            confirm({
                orderId:route.params.id
            }).then(res=>{
                loader.hide()
                if(res.code == 0) {
                    router.replace({
                        path:"/orders"
                    })
                }
            })
        }
        const selected = async (e, ind) => {
            e.stopPropagation()
            obj.selectAmount = obj.canChooseAmount[ind]
            selected_ind.value = ind
            list_show.value = false
        }
        return {
            obj,
            getMoney,
            selected,
            showMenu,
            list_show
        }
    }
}
</script>
<style lang="less" scoped>
@import "assets/less/icon.less";
    .amount-list{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background:rgba(255,255,255,1);
        border-radius: 15px;;
        z-index: 99999;
        padding:0 30px;
        .amount-element{
            text-align:center;
            width: 150px;
            padding:30px;
            color:#A59BB3;
            font-size: 28px;   
            border-bottom: 2px solid #e0e0e0;   
            &:last-child{
                border-bottom: none;
            }      
            &:hover{
                color:#6D20D3;
                border-bottom-color:#e0e0e0;   
            }
        }
        
    }
    .wrapper{
        padding:50px 48px 0 48px;
    }
    .oi-element{
        padding:40px;
        border-bottom: 2px solid #EFEEF1;
        overflow: hidden;        
        .left-content{
            float:left;
            .oi-title{
                color:#A59BB3;
                font-size: 26px;
            }
            .oi-content{
                font-size: 30px;
                color:#000;
                margin-top: 30px;
                &.no-choice{
                    color:#D0CDDA;
                }
            }
        }
        .right-content{
            float:right;
            position: relative;
            margin-top: 40px;
        }
    }
    .top-content{
        position:relative;
        width: 594px;
        height: 320px;
        background:url("~assets/images/product_bg.png") no-repeat;
        background-size: 100% 100%;
        padding:40px 30px;
        .title{
            text-align: center;
            font-size: 24px;
            color:#fff
        }
        .min_max{
            color:#fff;
            font-size: 56px;
            text-align: center;
            margin-top: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            .icon{
                margin: 0;
                margin-left: 20px;
            }
        }
        .fense{
            width:100%;
            margin: 50px 0 50px 0;
            border-top:2px dashed #fff
        }
        .info-wrapper{
            overflow:hidden;
            margin-top: 50px;
        }
        .info-list{            
            margin-bottom: 40px;   
            float:left;         
            width: 50%;
            .il-element{                
                font-size: 24px;
                color:#fff;
                text-align: left; 
                margin-bottom: 30px; 
                text-align: center;                              
                em{
                    font-weight: 700;
                    font-size: 26px;
                }                
                &.strong{
                    font-size: 44px;
                    font-weight: 700;
                }
            }            
        }

    }
    .detail-info{
        background:#F5F4FA;
        padding:40px;
        width: 564px;
        margin-top: -20px;
        .detail-list{
            margin-top: 60px;
            .detail-list-element{
                overflow:hidden;
                margin-bottom: 30px;
                .left-text{
                   float:left;
                   color:#A59BB3;
                   font-size: 28px; 
                }
                .right-text{
                    float:right;
                    color:#A59BB3;
                    font-size: 28px;
                }
            }
        }
    }
    .btn-container{
        position:fixed;
        bottom:50px;
        left:0;
        width: 100%;
        height: 96px;
        .btn-submit{
            display: block;
            width: 670px;
            height: 96px;
            line-height: 96px;
            text-align: center;
            border-radius: 12px;
            background:#6D20D3;
            font-size: 32px;
            margin:0 auto;
            color:#fff;
        }
    }
</style>