import { createStore } from "vuex";
import { login,promotionLogin } from '@api/index.js'
import { setToken, setTokenFromStorage, removeToken, removeTokenFromStorage } from '../utils/tool'

 const store = createStore({
    state () {
        return {
            token : '',
            toast_state: false,
            toast_txt: ''
        }
    },
    getters: {
        token (state) {
            return state.token
        },
        toast_txt (state) {
            return state.toast_txt
        }
    },
    mutations: {
        setToken(state, token) {
            state.token = token
        },
        setToast(state, show) {
            state.toast_state = show
        },
        setToastTxt(state, txt) {
            state.toast_txt = txt
        },
        clearToken(state) {
            state.token = ''
            removeToken()
            removeTokenFromStorage()
        }
    },
    actions: {
        closeToast({ commit }) {
            commit('setToast', false)
        },
        showToast({ commit }) {
            commit('setToast', true)
        },
        setToastTxt({ commit }, txt) {
            commit('setToastTxt', txt)
        },
        clearToken({ commit }) {
            commit('clearToken')
        },
        setToken( { commit }, token ) {
            commit('setToken', token)                    
            setToken(token)
            setTokenFromStorage(token)
        },
        login({ commit }, userInfo) {            
            const { phone, code,invitationCode } = userInfo
            return new Promise((resolve, reject) => {
              login({ phone: '91'+phone.trim(), code: code, invitationCode }).then(response => {
                if(response.code == 0 ){
                    const { token } = response.data
                    commit('setToken', token)                    
                    setToken(token)
                    setTokenFromStorage(token)
                    resolve()
                } else {
                    reject(response.msg)    
                }   
              }).catch(error => {
                reject(error)
              })
            })
          },
          promotionLogin({ commit }, userInfo) {            
            const { phone, code,invitationCode } = userInfo
            return new Promise((resolve, reject) => {
               promotionLogin({ phone: '91'+phone.trim(), code: code, invitationCode }).then(response => {
                if(response.code == 0 ){
                    const { token } = response.data
                    commit('setToken', token)                    
                    setToken(token)
                    setTokenFromStorage(token)
                    resolve()
                } else {
                    reject(response.msg)    
                }   
              }).catch(error => {
                reject(error)
              })
            })
          },  
    }
})
export default store