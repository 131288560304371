<template>
    <div class="home">
        <div class="top">
            <MyCarousel v-if="imgList.length > 0" :imgList="imgList"></MyCarousel>
            <div class="product-list">
                <div class="list-element" v-for="(element, ind) in list" :key="ind">
                    <h2 class="element-title">
                        <img :src="element.logo" alt="">
                        <span v-text="element.name"></span>
                    </h2>
                    <div class="info">
                        <div class="caption">Max Amount(₹)</div>
                        <div class="amount" v-text="element.maxAmount"></div>
                        <div class="other-info">
                            <div class="oi-left">
                                <div class="left-text">
                                    <span class="left">Tenure:</span>
                                    <span class="right" v-text="element.maxPeriod + 'days'"></span>
                                </div>
                                <div class="left-text">
                                    <span class="left">Interest:</span>
                                    <span class="right" v-text="`  ${element.rate}%`"></span>
                                </div>
                            </div>
                            <div class="oi-right">
                                <a v-if="element.isFull == 0" @click="before_apply(element.id)" :to="`/product/${element.id}`" href="javascript:void(0);" class="btn-apply active" v-text="element.isFull == 0 ?'Apply': 'Fulled'"></a>
                                <a href="javascript:void(0);" v-else class="btn-apply" v-text="element.isFull == 0 ?'Apply': 'Fulled'" ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                        
        </div>                
    </div>
</template>
<style lang="less" scoped>
    @import "~assets/less/icon.less";
    .top{
        padding:50px 35px;
        .product-list{
            .list-element{
                border-radius: 16px;
                overflow:hidden;
                margin-bottom: 50px;
                box-shadow: 0px 22px 80px 0px rgba(0,0,0,0.0800);                
                .element-title{
                    padding:0 50px;
                    width: 640px;
                    height:85px;
                    line-height: 65px;
                    display: flex;
                    align-items: center;
                    background:#9E8FE7;                    
                    img{
                        width: 64px;
                        height: 64px;
                        margin-right: 50px;
                    }
                    span{
                        color:#fff;
                        font-size: 30px;
                    }
                }
                .info{
                    padding: 30px 50px;
                    .caption{
                        font-size: 32px;
                        color:#8A82A1;
                        text-align:center;
                        margin-bottom: 30px;
                    }
                    .amount{
                        font-size: 96px;
                        color:#000;
                        text-align: center;
                        font-weight: 700;
                    }
                    .other-info{
                        overflow: hidden;
                        display: flex;     
                        align-items: center;
                        .oi-left{                            
                            width: 70%;
                            .left-text{
                                .left{
                                    font-size: 30px;
                                    color:#160544;
                                }
                                .right{
                                    color:#A59BB3;
                                    font-size: 30px;                                    
                                }
                            }
                        }
                        .oi-right{
                            .btn-apply{
                                display:block;
                                width: 170px;
                                height: 60px;
                                line-height: 60px;
                                color:#fff;
                                font-size: 26px;
                                text-align:center;
                                background:#A59BB3;
                                border-radius: 6px;
                                &.active{
                                    background:#6D20D3
                                }
                            }
                        }
                        
                    }
                }
            }
        }
    }
</style>
<script>

import MyCarousel from '../../carousel.vue'
import { getOrders } from '@api/index'
import { useLoading } from 'vue-loading-overlay'
import {useRouter} from 'vue-router'
export default {
    components:{
        MyCarousel
    },
    props:{
        imgList:{
            type:Array,
            default:()=>{}
        },
        money: String ,
        logined: String,
        list:[]     
    },
    setup() {
        const loading = useLoading()
        const router = useRouter()
        const before_apply = (pid) => {            
            const loader = loading.show({
                container: null
            })
            getOrders().then((resp)=>{
                loader.hide()
                const list = resp.data.filter((element)=>{
                    return pid == element.productId && ( element.orderState == '30' || element.orderState == '50' || element.orderState == '0' || element.orderState == '5' || element.orderState == '10' || element.orderState == '20' || element.orderState == '35')
                })
                if(list.length > 0) {
                    router.replace({
                        path:'/orders'
                    })
                } else {
                    router.replace({
                        path: '/verify/index/' + pid
                    })
                }
            })
        }
        return {
            before_apply
        }
        // money = ref('')
        // const money = props.money
    
        // return {
        //     money
        // }
    }    
}
</script>