<template>
    <div class="wrapper">
        <!-- <div class="top">
            <div class="logo"></div>
        </div> -->
        <div class="content">
            <h2 class="title" v-text="obj.info.name"></h2>
            <p class="txt" v-text="obj.info.content"></p>
        </div>
    </div>
</template>
<script>
import { aboutus } from '@api/index'
import { onMounted, reactive } from 'vue'
export default {
    setup () {
        const obj = reactive({
            info:{
                content:'',
                name: ''
            }
            
        })
        onMounted(()=>{
            aboutus().then((res)=>{
                obj.info = {
                    content:res.data.copywriting,
                    name:  res.data.appName
                }
            })
        })
        return {
            obj
        }
    }
    
}
</script>
<style lang="less" scoped>
    .wrapper{
        padding:80px;
        .top{
            padding:50px 0;
            border-bottom: 2px solid #EFEEF1;
            .logo{
                background:url("~assets/images/logo.png") no-repeat 100% 100%;
                width: 156px;
                height: 156px;
                margin:0 auto;
            }
        }
        .content{
            .title{
                text-align: center;
                font-size: 44px;
                color:#000;
                margin: 50px 0;
                font-weight: 700;
            }
            .txt{
                color:#A59BB3;
                font-size: 26px;
                line-height: 1.5;
                text-align: left;
                word-break: break-all;
            }
        }
    }
</style>
