<template>
    <div class="wrapper">
         <div class="top">
            <h1 class="title">Contact Information</h1> 
            <p class='guide'>Step <em>3</em> / 3</p>
        </div>       
        
        <ContactElement
            title="Urgent Contact"
            :choices="parents"
            :is_show = "data['one'].is_show"
            :data = "data['one']"
            key_name="one"
            @setValue="setValue"
        ></ContactElement>
        <ContactElement
            title="Urgent Contact"
            :choices="brothers"
            :is_show = "data['two'].is_show"
            :data = "data['two']"
            key_name="two"
            @setValue="setValue"
        ></ContactElement>
        <ContactElement
            title="Urgent Contact"
            :choices="friends"
            :is_show = "data['third'].is_show"
            :data = "data['third']"
            key_name="third"
            @setValue="setValue"
        ></ContactElement>
        <div class="placeholder"></div>
        <div class="btn-container">            
            <a class='btn-submit' @click.prevent="submit">CONFIRM TO NEXT</a>
        </div>
    </div>
    
</template>
<script>
import ContactElement from '@/components/business/kyc/contact_element.vue'
import { contact_relation, submit_contact } from '@api/index'

export default {
    components:{
        ContactElement
    },
    data () {
        return {
            data:{
                "one":{
                    "name":{
                        value:"",
                        error:false
                    },
                    "mobile":{
                        value:"",
                        error:false
                    },
                    "relation":{
                        value:"",
                        error:false
                    },
                    "is_show":false
                },
                "two":{
                    "name":{
                        value:"",
                        error:false
                    },
                    "mobile":{
                        value:"",
                        error:false
                    },
                    "relation":{
                        value:"",
                        error:false
                    },
                    "is_show":false
                },
                "third":{
                    "name":{
                        value:"",
                        error:false
                    },
                    "mobile":{
                        value:"",
                        error:false
                    },
                    "relation":{
                        value:"",
                        error:false
                    },
                    "is_show":false
                }
            },
            parents:[],
            brothers:[],
            friends:[],
            clicked:false
        }
    },
    methods: {
        setValue(obj_name,key_name,v) {
            if(typeof (this.data[obj_name][key_name].value) != 'undefined'){
                this.data[obj_name][key_name].value = v 
                this.data[obj_name][key_name].error = false
            } else {
                this.data[obj_name][key_name] = v
            }
            
        },
        validate () {
            let res = true
            for(let x in this.data) {
                for(let y  in this.data[x]) {
                    if(y=='is_show')continue
                    if(this.data[x][y].value === '') {
                        this.data[x][y].error = true
                        res = false
                    }
                }
            }            
            return res
        },
        submit () {
            if(this.validate() && !this.clicked) {
                this.clicked = true
                const loader = this.$loading.show({
                    container: null
                })
                submit_contact({
                    "oneName":this.data['one'].name.value,
                    "oneMobile": this.data['one'].mobile.value,
                    "oneRelation":this.data['one'].relation.value,
                    "twoName":this.data['two'].name.value,
                    "twoMobile": this.data['two'].mobile.value,
                    "twoRelation":this.data['two'].relation.value,
                    "thirdName":this.data['third'].name.value,
                    "thirdMobile": this.data['third'].mobile.value,
                    "thirdRelation":this.data['third'].relation.value,
                }).then((res)=>{                    
                    if(res.code == 0) {
                        this.$router.replace({
                            "path":"/",                            
                        })
                    } else {
                        // let error_text = ""
                        // for(let i = 0 ; i<res.data.errorColumn.length;i++) {
                        //     error_text += `${i+1}.  ${res.data.errorColumn[i].msg} \n`
                        // }
                        this.$toast.show(res.msg)
                    }
                    loader.hide()
                    this.clicked = false
                })    
            }
        }
    },
    mounted () {
        document.body.style.background = "#F7F7F7"
        document.documentElement.scrollTop = 0
        
        contact_relation().then((data)=>{
            this.parents = data.data[0].dictionaryVoList
            this.brothers = data.data[2].dictionaryVoList
            this.friends = data.data[1].dictionaryVoList
        })
        
    }
}
</script>
<style lang="less" scoped>
    .wrapper{
        padding:20px 40px 40px;
        .top{
            display:flex;
            justify-content: space-between;
            align-items: center;
        }
        .guide{
            color:#A59BB3;
            font-size: 30px;
            em{
                color:#8960e8;
                font-weight: 700;
            }
        }
        .title{
            color:#8960e8;
            font-size: 40px;
            margin: 30px 0;  
        }
    }
    .caption{
        background:#E3D4FC;
        font-size: 26px;
        padding:15px;
        margin-bottom: 30px;
        line-height: 1.5;
        color:#8960e8;
        border-radius: 18px;
    }
    .btn-container{
        position: fixed;
        left:0;
        bottom: 0;        
        background:#fff;
        padding:40px;
        // padding:40px;
        .btn-submit{
            display: block;
            width: 670px;
            height: 96px;
            line-height: 96px;
            text-align: center;
            border-radius: 12px;
            background:#8960e8;
            font-size: 32px;
            margin:0 auto;
            color:#fff;
        }
    }
    .placeholder{
        height: 176px;        
    }
</style>