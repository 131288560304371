<template>
    <div class="wrapper" @click="capture">
        <div class="top-content">
            <h3 class="title">Amount</h3>
            <div class="min_max" @click="list_show = !list_show" >
                ₹{{maxAmount}}
            </div>
            <ul class="amount-list" v-show='list_show'>
                <li class="amount-element" v-for="(ele, ind) in obj.amountList" :key="ind" v-text="ele"></li>
            </ul>
            <div class="fense"></div>
            <div class="info-list">
                <div class="il-element">Lending time: <em v-text="obj.loanProcessingTime"></em></div>
                <div class="il-element">Period: <em v-text="obj.periodList[0]"></em></div>
            </div>
            <div class="info-list">
                <div class="il-element">Overall rating: <em v-text="obj.score"></em></div>
                <div class="il-element">Interest: <em v-text="obj.interestRate + '%/day'"></em></div>
            </div>
        </div>
        <div class="requirement">
            <h3 class="section-title">Requirement</h3>
            <ul class="req-list">
                <li class="req-element" v-for="(item, ind) in requirements.list" :key="ind">
                    <span v-text="`${ind+1}、${item}`"></span>
                </li>
            </ul>
        </div>
        <div class="desciption">
            <h3 class="section-title">Product description</h3>
            <p class='dc' v-text="obj.slogan">

            </p>
        </div>
        <div class="btn-container">
            <a class="btn-submit" @click.prevent="submit">APPLY NOW</a>
        </div>
    </div>
</template>
<style lang="less" scoped>
.wrapper{
    padding: 50px;
    .amount-list{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background:rgba(255,255,255,0.9);
        border-radius: 15px;;
        .amount-element{
            text-align:center;
            width: 150px;
            padding:30px;
            color:#A59BB3;
            font-size: 28px;            
            &:hover{
                color:#6D20D3
            }
        }
        
    }
    .top-content{
        position:relative;
        width: 594px;
        height: 320px;
        background:url("~assets/images/product_bg.png") no-repeat;
        background-size: 100% 100%;
        padding:40px 30px;
        .title{
            text-align: center;
            font-size: 24px;
            color:#fff
        }
        .min_max{
            color:#fff;
            font-size: 56px;
            text-align: center;
            margin-top: 25px;
        }
        .fense{
            width:100%;
            margin: 50px 0 50px 0;
            border-top:2px dashed #fff
        }
        .info-list{
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;            
            .il-element{
                &:last-child{
                    float:right;
                }
                font-size: 24px;
                color:#fff;
                text-align: left;
                width: 50%;
                float:left;
                em{
                    font-weight: 700;
                    font-size: 26px;
                }                
            }            
        }

    }
 
    .requirement{
        margin-top: 50px;
        padding-bottom: 30px;
        border-bottom: 2px solid #EFEEF1;
        .section-title{
            color:#160544;
            font-size: 36px;
        }
        .req-list{
            background:#F9F9FB;
            padding: 25px;
            margin-top: 20px;
            border-radius: 15px;
            .req-element{
                color:#A59BB3;
                font-size: 30px;
                margin-bottom: 20px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    .desciption{
        padding-top: 30px;
        .section-title{
            color:#160544;
            font-size: 36px;
            margin-bottom: 30px;
        }
        .dc{
            background:#F9F9FB;
            padding: 25px; 
            color:#A59BB3;
            font-size: 30px;
        }
    }
    .btn-container{
        position:fixed;
        bottom:50px;
        left:0;
        width: 100%;
        height: 96px;
        .btn-submit{
            display: block;
            width: 670px;
            height: 96px;
            line-height: 96px;
            text-align: center;
            border-radius: 12px;
            background:#6D20D3;
            font-size: 32px;
            margin:0 auto;
            color:#fff;
        }
    }
}
</style>
<script>
import { productInfo, getAuthInfo, gps, faceConfig } from '@api/index'
import { onMounted,reactive,ref } from 'vue'
import {useRouter} from 'vue-router'
import { useLoading } from 'vue-loading-overlay'
export default {
    setup () {                
        const router = useRouter()
        const route = router.currentRoute
        const minAmount = ref(0)
        const maxAmount = ref(0)
        const list_show = ref()
        const $loader = useLoading()
        const clicked = ref(false)
        let obj = reactive({
            loanProcessingTime:'',
            period: '',
            serviceRate:'',
            interestRate: '',
            score: '',
            slogan: '',
            periodList:['']
        })
        const requirements = reactive({
            list:[]
        })
        onMounted(async ()=>{
            const data = await productInfo({
                id: route.value.params.pid
            }) 
            requirements.list = data.data.detail[1].content            
            obj = Object.assign(obj, data.data) 
            minAmount.value = data.data.amountList[0]       
            maxAmount.value = data.data.amountList[0]  
            if(navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position)=>{                    
                    gps({
                        "gps": {
                            "address": "",
                            "latitude": position.coords.latitude,
                            "longitude": position.coords.longitude
                        },
                        "deviceInfo":{
                            'user_agent': navigator.userAgent
                        }
                    })
                }, ()=>{
                    gps({
                        "gps": {
                            "address": "",
                            "latitude": "",
                            "longitude": "",                            
                        },
                        "deviceInfo":{
                            'user_agent': navigator.userAgent
                        }
                    })
                })
            }                             
        })
        const submit = async () => {
            if(clicked.value)return
            clicked.value = true
            const loader = $loader.show({
                container: null
            })
            const data_2 = await getAuthInfo({
                productId:route.value.params.pid
            })
            loader.hide()
            clicked.value = false
            if (data_2.data.kyc === 0) {
                const config = await faceConfig()
                if(config.data['living-config'] == 1) {
                    router.push({
                        path:'/verify/face'
                    })
                } else {
                    router.push({
                        path:'/verify/kyc',
                        query:{
                            face_type:config.data['living-config']
                        }
                    })
                }
                
            } else if (data_2.data.basic === 0 ) {
                router.push({
                    path:'/verify/base'
                })                
            } else if (data_2.data.contact === 0) {
                router.push({
                    path:'/verify/contact'
                })
            } else {
                router.push({
                    path:`/product/${route.value.params.pid}/detail`
                })
            }
        }
        return {
            obj,
            requirements,minAmount, maxAmount,list_show,submit
        }
    }
}
</script>