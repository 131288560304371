<template>
    <div>
        <div class="promotion-dialog-wrapper" v-show="props.ruleShow">
            <div class="dialog">
                <div class="dialog-head">
                    <i class="icon icon-close" @click="close"></i>
                </div>
                <div class="dialog-content">
                    
                    <div class="dialog-text">

                    <h2 class="dc-title">Activity Rules:</h2>                    
                    <p class='dc-text'>1. Old users of the Instarupee platform are eligible to participate in the event. Old users on the platform invite new users to register and download the app and complete a loan (of any amount). After the new user successfully makes a loan, they can receive a cash reward of 100Rs, with more invitations and more rewards, and there is no ceiling on the loan;</p>

                    <p class='dc-text'>2. New users who complete registration through the exclusive activity link shared by old users are considered to have reached the invitation relationship;</p>

                    <p class='dc-text'>3. The cash reward will be credited to the old user's personal account after the new user successfully disburses the money, and automatically withdrawn to the bank card bound to the old user (automatically withdrawn after the activity ends);</p>

                    <p class='dc-text'>4. Users are not allowed to participate in this activity through any robot software, spider software, crawler software, ticketing software, or other automatic means of access or other cheating behavior. Once it is verified that the invited user is not a real user, no rewards will be issued; Have the right to demand users to return rewards that have already been issued;</p>

                    <p class='dc-text'>5. The same device number, mobile phone number, ID number and bank card number are regarded as the same user;</p>

                    <p class='dc-text'>6. Within the scope permitted by law, this platform has the right to make changes or adjustments to the event rules based on the actual situation of the event. The latest rules will be displayed in this rule, and users' continued participation in the event will be deemed to have agreed to the aforementioned changes or adjusted event rules;</p>

                    <p class='dc-text'>7. If the event cannot continue due to force majeure or changes in circumstances (including but not limited to major natural disasters, government regulatory orders to stop or adjust the event, serious network attacks or system failures, etc.), the platform may decide to suspend or terminate the event, and may claim exemption from liability in accordance with relevant laws and regulations;</p>

                    <p class='dc-text'>8. If you have any questions, please contact customer service.</p>

                    <p class='dc-text'>Service Phone: 9289458622</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:['ruleShow'],
    setup(props, context) {
        const close = () => {
            console.log(123)
            context.emit("closeRuleDialog", false)
        }
        console.log(props)
        return {
            close,
            props
        }
    }
}
</script>
<style lang="less" scoped>
    .promotion-dialog-wrapper{
        width: 100%;
        height: 100%;
        position: fixed;
        top:0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background:rgba(0,0,0,0.6);
        z-index: 3;
        .dialog{
            width: 590px;
            height: 956px;
            background:#fff;
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%);
            border-radius: 30px;
            padding:40px;
            .dialog-head{
                overflow: hidden;
                margin-bottom: 20px;
                .icon-close{
                    display:block;
                    background: url("~assets/images/promotion/recruit/icon-dialog-close.png") no-repeat;
                    width: 30px;
                    height: 30px;
                    background-size:100%;
                    float:right
                }
            }
            .dialog-content{
                .dialog-text{
                    height:900px;
                    overflow: auto;
                }
                .dc-title{
                    font-size: 30px;
                    color:#525252;
                    margin-bottom: 30px;
                }
                .dc-text{
                    font-size: 24px;
                    color:#7A7A7A;
                    line-height:1.5;
                    margin-bottom: 20px;
                }
            }
        }
    }
</style>