<template>
    <div class="wrapper">
        <p class="guide">
            Step <em>{{ route.query.step }}</em> / {{ route.query.as }}
        </p>
        <!-- <Dropdonw 
            title="Name"
            :choics=[]
        >

        </Dropdonw> -->
        <h1 class="title">Work Information</h1>
        <div class="content">
            <div class="element-container" v-for="(item, ind) in obj.select_types" :key="ind">
                <template v-if="item.dataType=='0'  && item.canHidden == false ">
                    <Dropdonw
                        :title="item.key"
                        :required="item.checkReq"
                        :choices="item.dictionaryVoList"
                        :keys="item.value"    
                        :is_show="item.is_show" 
                        :is_error="item.is_error"
                        :label="item.label"              
                        :component_id = "ind"                    
                        @modify="modify_normal"
                        @modifyError="modify_error"
                        @setValue="setValue"                    
                    >
                    </Dropdonw>        
                </template>
                <template v-if="item.dataType=='3'  && item.canHidden == false ">
                    <Dropdonw
                        :title="item.key"
                        :required="item.checkReq"
                        :choices="type3Arr"
                        :keys="item.value"    
                        :is_show="item.is_show" 
                        :is_error="item.is_error"
                        :label="item.label"              
                        :component_id = "ind"                    
                        @modify="modify_normal"
                        @modifyError="modify_error"
                        @setValue="setValue"                    
                    >
                    </Dropdonw>        
                </template>
                <template  v-if="item.dataType=='1'  && item.canHidden == false ">
                    <MyInput
                        :title="item.key"
                        :component_id = ind
                        :required="item.checkReq"
                        :keys="item.value"
                        :label="item.label"                        
                        :is_error="item.is_error"
                        :checkType="item.checkType"
                        :maxlength="item.lengthLimitMax"
                        @modifyError="modify_error"
                        @setValue="setValue"
                    >
                    </MyInput>
                </template>
                <template  v-if="item.dataType=='4'  && item.canHidden == false ">
                    <State
                        :title="item.key"
                        :required="item.checkReq"
                        :component_id = ind
                        :keys="item.value"
                        :is_error="item.is_error"
                        :is_show="item.is_show"
                        :label="item.label"
                        @setValue="setValue"
                        @modify="modify_normal"
                        @modifyError="modify_error"
                    >
                    </State>
                </template>
                <template  v-if="item.dataType=='5'  && item.canHidden == false ">
                    <Location
                        :title="item.key"
                        :required="item.checkReq"
                        :component_id = ind
                        :keys="item.value"   
                        :label="item.label"   
                        :is_error="item.is_error"                  
                        :is_show="item.is_show"
                        :state_id = "state_id"
                        @setValue="setValue"
                        @modify="modify_normal" 
                        @modifyError="modify_error"                   
                    >
                    </Location>
                </template>
                
            </div>            
        </div>        
        <div class="placeholder"></div>
        <div class="btn-container">            
            <a class='btn-submit' :class='{"unactive":!btnStatus}' @click="submit">CONFIRM TO NEXT</a>
        </div>
    </div>
</template>
<style lang="less" scoped>
    .wrapper{
        padding:40px;
        .guide{
            color:#A59BB3;
            font-size: 30px;
            em{
                color:#8960e8;
                font-weight: 700;
            }
        }
        .title{
            color:#8960e8;
            font-size: 40px;
            margin: 50px 0;  
        }
    }
    .btn-container{
        position: fixed;
        left:0;
        bottom: 0;        
        background:#fff;
        padding:40px;
        // padding:40px;
        .btn-submit{
            display: block;
            width: 670px;
            height: 96px;
            line-height: 96px;
            text-align: center;
            border-radius: 12px;
            background:#8960e8;
            font-size: 32px;
            margin:0 auto;
            color:#fff;
            &.unactive{
                background:#f0f0f0;
                color:#999
            }
        }
    }
    .placeholder{
        height: 176px;        
    }
</style>
<script>

import { reactive, onMounted, computed, ref , getCurrentInstance} from 'vue'
import { work_info, work_submit } from '@api/index'
import Dropdonw from '@/components/business/kyc/dropdown.vue'
import MyInput from '@/components/business/kyc/input.vue'
import State from '@/components/business/kyc/state.vue'
import Location from '@/components/business/kyc/location.vue'
import { useRouter } from 'vue-router'
import { useLoading } from 'vue-loading-overlay'
export default {
    components:{
        Dropdonw,
        MyInput,
        State,
        Location
    },    
    setup () {
        const router = useRouter()
        const route = router.currentRoute
        const clicked = ref(false)
        const btnStatus = ref(false)
        const proxy  = getCurrentInstance().proxy
        const type3Arr = ref([{"dictLabel":1,"dictValue":1},{"dictLabel":2,"dictValue":2},{"dictLabel":3,"dictValue":3},{"dictLabel":4,"dictValue":4},{"dictLabel":5,"dictValue":5},{"dictLabel":6,"dictValue":6},{"dictLabel":7,"dictValue":7},{"dictLabel":8,"dictValue":8},{"dictLabel":9,"dictValue":9},{"dictLabel":10,"dictValue":10},{"dictLabel":11,"dictValue":11},{"dictLabel":12,"dictValue":12},{"dictLabel":13,"dictValue":13},{"dictLabel":14,"dictValue":14},{"dictLabel":15,"dictValue":15},{"dictLabel":16,"dictValue":16},{"dictLabel":17,"dictValue":17},{"dictLabel":18,"dictValue":18},{"dictLabel":19,"dictValue":19},{"dictLabel":20,"dictValue":20},{"dictLabel":21,"dictValue":21},{"dictLabel":22,"dictValue":22},{"dictLabel":23,"dictValue":23},{"dictLabel":24,"dictValue":24},{"dictLabel":25,"dictValue":25},{"dictLabel":26,"dictValue":26},{"dictLabel":27,"dictValue":27},{"dictLabel":28,"dictValue":28},{"dictLabel":29,"dictValue":29},{"dictLabel":30,"dictValue":30},{"dictLabel":31,"dictValue":31}])
        let obj = reactive({
            select_types:[],
            input_types:[],
            state_types:[],
            district_types:[],            
            params:{}
        })
        const state_id = computed(()=>{
            return obj.params['liveState'].value
        })
        const canHiddenShow = computed(()=>{            
            const ws = obj.params['workState'].value
            if(ws == 3) {
                return true
            } else {
                return false
            }            
        })
        const canHiddenRequired = computed(()=>{
            return canHiddenShow.value ? 0 : 1
        })
        const validate = () => {
            let res = true
            for(let x in obj.params){
                if (obj.params[x].value == '' & obj.params[x].required == 1) {
                    obj.select_types[obj.params[x].ind].is_error = true
                    res = false
                }
            }
            btnStatus.value = res
            return res
        }
        const $loading = useLoading()
        const submit = () => {            
            if(validate() && !clicked.value){
                clicked.value = true
                let loader = $loading.show({
                    container:null
                })
                let newParams = {}
                for(let i in obj.params) {
                    newParams[i] = obj.params[i].value
                }
                work_submit(newParams).then((res)=>{
                    if(res.code == 0) {
                        router.push({
                            "path":"/verify/index/"+route.value.query.pid,                            
                        })
                    } else {
                        // let error_text = ""
                        // for(let i = 0 ; i<res.data.errorColumn.length;i++) {
                        //     error_text += `${i+1}.  ${res.data.errorColumn[i].msg} \n`
                        // }
                        proxy.$toast.show(res.msg)
                    }
                    loader.hide()
                    clicked.value = false
                }).catch(()=>{
                    clicked.value = false
                })
            }
        }        
        onMounted(async () => {
            let loader = $loading.show({
                    container:null
            })
            let data = await work_info()
            loader.hide()
            let all_data = data.data.canHidden
            let select_type = all_data.map((ele, ind)=>{
                ele.is_show = false
                ele.is_error = false
                ele.canHidden = canHiddenShow
                if(ele.dataType == '0'|ele.dataType == '4'|ele.dataType == '5') {
                    ele.label = ele.label?ele.label:"Please select"
                } else if (ele.dataType == '1') {
                    ele.label = ele.label?ele.label:"Please input"
                } 
                if(ele.checkReq == 0) {
                    obj.params[ele.value] = {
                        "value":'',
                        "ind": ind,
                        "required":canHiddenRequired
                    }
                } else {
                    obj.params[ele.value] = {
                        "value":'',
                        "ind": ind,
                        "required":0
                    }
                }
                
                return ele
            })
            let select_type2 = data.data.cannotHidden.map((ele, ind)=>{
                ele.is_show = false
                ele.is_error = false         
                ele.canHidden = false
                if(ele.dataType == '0'|ele.dataType == '4'|ele.dataType == '5') {
                    ele.label = ele.label?ele.label:"Please select"
                } else if (ele.dataType == '1') {
                    ele.label = ele.label?ele.label:"Please input"
                } 
                if(ele.checkReq == 0) {
                    obj.params[ele.value] = {
                        "value":'',
                        "ind": ind,
                        "required":1
                    }
                } else {
                    obj.params[ele.value] = {
                        "value":'',
                        "ind": ind,
                        "required":0
                    }
                }
                
                return ele
            })
            obj.select_types = select_type2.concat(select_type)
        })
        const modify_normal = (k, v) => {
            for (let x in obj.select_types){                
                obj.select_types[x].is_show = false
            }
            obj.select_types[k]['is_show'] = v            
        }
        const modify_error = (k, v) =>{
            obj.select_types[k]['is_error'] = v
        }
        const setValue = (k, v) => {               
            obj.params[k].value = v
            validate()
        }                
        return {
            obj, setValue ,modify_normal,state_id,submit,modify_error,btnStatus,type3Arr,route,canHiddenShow
        }
    }
}
</script>
