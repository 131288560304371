<template>
    <div class="element-wrapper" :class="{'is_error': is_error}">
        <div class="element-title">
                <h3 v-text="title"></h3>
                <i class="icon" :class="{'icon-exclaim':is_error }"></i>                
            </div>
        <div class="element-content">
            <input v-if="checkType != 1" type="text" @input="textInput" @focus="bootomFocus" :placeholder="label">
            <input v-else-if="checkType == 1" type="tel" @input="numberTextInput" @focus="bootomFocus"  :maxlength="maxlength" :placeholder="label">                        
        </div>
    </div>
</template>
<script>
import { ref } from 'vue'
export default {
    props:{
        title:{
            type:String
        },
        keys:{
            type:String
        },
        label:{
            type:String
        },
        is_error:{
            type:Boolean
        },
        checkType:{
            type:String
        },
        component_id:{
            type:Number
        },
        maxlength:{
            type:Number
        }
    },
    setup (props, { emit }) {
        const selected_ind = ref(0)
        const selected = (ind) => {
            selected_ind.value = ind
        }
        const textInput = (e) => {
            emit("setValue", props.keys, e.target.value )
            if(e.target.value) {
                emit("modifyError", props.component_id, false)
            }
        }
        const numberTextInput = (e) => {
            let v = e.target.value 
            const reg = /\D+/
            if(reg.test(v)) {
                e.target.value = e.target.value.substring(0, e.target.value.length - 1)                
                return 
            }
            emit("setValue", props.keys, e.target.value)
            if(e.target.value) {
                emit("modifyError", props.component_id, false)
            }
        }
        return {
            selected_ind,
            selected,
            textInput,
            numberTextInput
        }
    }
}
</script>
<style lang="less" scoped>
.element-wrapper{
    padding-bottom: 30px;
    border-bottom: 2px solid #EFEEF1;
    position: relative;
    margin-bottom: 30px;
    &.is_error{
        border-bottom:  2px solid #C5340B;       
    }
    .element-title{
        color:#73698F;
        font-size: 26px;  
        overflow: hidden;
        padding:15px 0;      
        h3{
            float:left;
        }
        .icon-exclaim{
            float:left;
            margin-left: 20px;
        }
        .icon-arrow-down{
            float:right
        }
    }
    .element-content{
        margin-top: 30px;
        color:#000;
        font-size: 36px;
        height:40px;
        line-height: 40px;
        input{
            display:block;
            width: 100%;
            font-size: 32px;
            border:none;
            &::placeholder{
                color:#D0CDDA;
            }
        }
    }
}
</style>