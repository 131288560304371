<template>
    <div>
        <h1 class="title">
                <img src="../../src/assets/images/logo.png" alt="">
                <p class="title-text">Instarupee</p >
        </h1>
        <First                                    
            :is_error = 'obj.is_error'
            :state = 'obj.state'
            :error_text = 'obj.error_text'                        
            @setPhone = "setPhone"
        ></First>
        <Second 
            ref = 'secondRef'
            :state='obj.state'
            :phone='obj.phone'                   
            @sendCode = "sendCodeFn"
            @setCode = 'setCode'            
        >

        </Second>
        <div class="check-container">
            <label class='check-container' @click="toggleCheck" for="">
                <i class="icon" :class="{'icon-check': obj.checked, 'icon-uncheck': !obj.checked}"></i>
                <p class="label-caption">
                    By Login you agree to our <a href="https://api.instarupee.in:4433/page/termService?packageName=com.xiaomi.instarupee" target="_blank"><em>Terms of Service</em></a> & <a href="https://api.instarupee.in:4433/page/privacyPolicy?packageName=com.xiaomi.instarupee"  target="_blank"><em>Privacy policy</em></a>
                </p>
            </label>
        </div>        
        <div class="btn-container">
            <button class="btn-submit" :class="{'active':btn_active}" @click="submit">{{ btn_text }}</button>
            <!-- <a v-show="obj.state == 1" href="javascript:void(0);" class="back" @click="back">back</a> -->
        </div>
    </div>    
</template>
<style lang="less" scoped>
@import "assets/less/icon.less";
.title{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px;
    img{
        width: 90px;
        height: 90px;
        margin-right:20px;        
    }
    font-size:56px;
}
    .check-container{
                    display: flex;
                    margin-top: 80px;
                    padding:0 30px;
                    .icon{
                        flex:1 0 auto;
                        margin-right: 20px;
                    }
                    .label-caption{
                        font-size: 24px;
                        color:#777777;
                        line-height: 1.5;
                        em{
                            color:#6D20D3;
                        }
                    }
                }
    .btn-container{
            margin: 80px 0 0 ;
            .btn-submit{
                display: block;                
                width: 670px;
                height: 96px;
                line-height: 96px;
                text-align: center;
                color:#fff;
                font-size: 32px;
                border-radius: 15px;
                border: none;
                background:#A59BB3;
                margin: 0 auto;
                &.active{
                    background:#6D20D3;
                }
            }
        }
        .back{
            color:#6D20D3;
            display: block;
            padding:50px 0 30px 0;
            font-size: 32px;
            text-align: center;
        }
</style>
<script>
import { reactive, computed, ref,  getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { sendCode } from "@api/index"
import First from '@/components/business/login/first.vue'
import Second from '@/components/business/login/second.vue'
export default { 
    components:{
        First,
        Second
    },   
    setup() {
        const router = useRouter()
        const route = router.currentRoute
        const store = useStore()
        const secondRef = ref()
        const proxy  = getCurrentInstance().proxy
        const obj = reactive({
            checked: true,
            btn_text: "Send OTP",
            is_error: false,
            phone: '',
            error_text: '',            
            state: 0   ,
            code: '',
            clicked: false
        })        
        const btn_text = computed(()=>{
            if(obj.state == 0) {
                return 'Send OTP'
            } else {
                return 'Next'
            }
        })
        const btn_active = computed(()=>{
            return (obj.phone.length > 0 & obj.checked & obj.state == 0 ) | (obj.code.length > 0 & obj.state === 1)
        })        
        const sendCodeFn = async () => {
            if (!obj.checked) {
                obj.is_error = true
                obj.error_text = 'Please agree with Loan Guarantee Argreement.'
            } else {
                let data = await sendCode({
                    phone: obj.phone
                })
                if(data.data.code == 0) {
                    obj.secs = 60
                } else {
                    obj.is_error = true
                    obj.error_text = data.data.msg
                }
            }
        }
        const submit = async () => {
            if(obj.clicked) return 
            obj.clicked = true
            if(obj.state === 0) {
                // const starts = obj.phone.substring(0,1)
                if(obj.phone.length < 10) {
                    obj.is_error = true
                    obj.error_text = 'Please input the correct mobile number.'
                } else if (!obj.checked) {
                    obj.is_error = true
                    obj.error_text = 'Please agree with Loan Guarantee Argreement.'
                }  else {                    
                    let data = await sendCode({
                        phone: '91' + obj.phone
                    })
                    if(data.code == 0) { 
                        secondRef.value.resetCode()                       
                        obj.state = 1
                        secondRef.value.startCountDonw()
                    } else {
                        obj.is_error = true
                        obj.error_text = data.msg
                    }
                }
                obj.clicked = false
            } else {
                obj.clicked = false
                store.dispatch('login',{
                    phone:obj.phone,
                    code:obj.code,
                    invitationCode:route.value.query.invitationCode || ""
                }).then(()=>{
                    router.replace({
                        path:'/'
                    })
                }).catch((msg)=>{                    
                    proxy.$toast.show(msg)
                })
            }
            
        }
        
        const toggleCheck = () => {
            obj.checked = !obj.checked            
        }       
        const setPhone = (phone) => {
            obj.phone = phone
        }
        const setCode = (code) => {
            obj.code = code
        }
        const changeState = (state) => {
            obj.state = state
        }
        const back = () => {
           changeState(0)
        }
        return {
            obj, submit,sendCodeFn, toggleCheck, btn_active,setPhone,secondRef,btn_text,setCode, back
        }
    }    
}
</script>