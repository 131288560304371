<template>
    <div class="wrapper">
        <!-- <div class="banner"></div> -->
        <h2 class="title">
           In Auditing...
        </h2>
        <p class="caption">
            Your application has been submitted and is being reviewed. Please wait patiently. We will notify you by SMS in time after the approval. You can also click the "Refresh" button below at any time to view the latest review progress.
        </p>
        <div class="btn-container">
            <a class="btn-submit" @click.prevent="update">Refresh</a>
            <div class="btn-caption">
                <i class="icon-safe"></i>
                <span>Based on your credit history and repayment performance, we will gradually increase your loan amount.</span>
            </div>
        </div>
        <div class="addtional">
            <p class="addtional-text">
                If you like our products and services, please give us 5 stars, this will help you get the audit results faster. Thank you very much for your support!
            </p>
            <button type="button" class='evaluate' @click='evaluate'>To Evaluate</button>
            <div class="pic"></div>
        </div>
    </div>
</template>
<script>
import { orderDetail, bury } from "@api/index";
import { ref, onMounted,getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { useLoading } from 'vue-loading-overlay'
import { useStore } from 'vuex'
export default {
    setup () {
        const store = useStore()
        const router = useRouter()
        const route = router.currentRoute.value
        const loading = useLoading()
        const clicked = ref(false)
        const proxy  = getCurrentInstance().proxy        
        onMounted(()=>{                        
            if(route.query.token){ 
                store.dispatch("setToken", route.query.token)                
            }     
            bury({
               "projectName":"instarupee", 
               "pageName":"rating",
               "behavior":"enter",
               "value":route.query.orderId,
               "eventTime":""
            })   
        })
        const evaluate = () => {
            bury({
               "projectName":"instarupee", 
               "pageName":"rating",
               "behavior":"click",
               "value":route.query.orderId,
               "eventTime":""
            })
            window.JavascriptChannel.postMessage('{"action": "launch", "url":"https://apps.apple.com/in/app/instarupee-loan/id1633399943?action=write-review"}')            
            // window.location.href = 'https://apps.apple.com/in/app/instarupee-loan/id1633399943#ember9'
        }
        const update = () => {    
            if(clicked.value)return
            clicked.value = true
            const loader = loading.show({
                container: null
            })
            orderDetail({
                orderId:route.query.orderId
            }).then((res)=>{
                loader.hide()
                clicked.value = false
                if(res.code == 0) {                    
                    if(res.data.orderState == 10) {
                        window.JavascriptChannel.postMessage('{"action": "finish"}')
                    }
                } else {
                    proxy.$toast.show(res.msg)
                }
            })
        }
        return {
            evaluate,
            update
        }
    }
}
</script>
<style lang="less" scoped>
    .wrapper{
        padding-top: 100px;
        .banner{
            width: 240px;
            height: 240px;
            background:url("~assets/images/orders/audit-banner.png") no-repeat;
            background-size: 100% 100%;
            margin: 0 auto
        }
        .title{
            text-align: center;
            font-size: 72px;
            color:#6979F8;
            margin: 60px 0 30px 0;
        }
        .caption{
            padding:30px 50px;
            color:#848D93;
            font-size: 28px;
            line-height: 1.5;
        }
        .btn-container {
            margin-top: 50px;            
            width: 100%;
            height: 96px;
            .btn-submit {
                display: block;
                width: 670px;
                height: 96px;
                line-height: 96px;
                text-align: center;
                border-radius: 12px;            
                background: linear-gradient(270deg, #B2BAF9 0%, #6979F8 100%);
                font-size: 32px;
                margin: 0 auto;
                color: #fff;            
            }
            .btn-caption{
                display:flex;
                align-items: center;
                width: 670px;
                margin:0 auto;
                margin-top: 20px;
                .icon-safe{
                    display:block;
                    background:url("~assets/images/orders/icon-safe.png") no-repeat;
                    width: 36px;
                    height: 36px;
                    background-size:100% 100%;
                    margin-right: 15px;     
                    flex:1 0 auto;               
                }
                span{
                    color:#666;
                    font-size: 24px;
                }
            }
        }
        .addtional{
            width: 610px;
            height:240px;
            padding:30px;
            margin:100px auto 0 auto;
            background:#F7F8FF;
            position:relative;
            .addtional-text{
                color:#333;
                width: 450px;
                line-height: 1.5;
                margin-bottom: 30px;
                font-size: 24px;
            }
            .evaluate{
                display:block;
                border:none;
                width: 186px;
                height: 62px;
                background: linear-gradient(270deg, #B2BAF9 0%, #6979F8 100%);
                text-align: center;
                line-height: 62px;
                color:#fff;
            }
            .pic{
                width: 260px;
                height: 240px;
                background:url("~assets/images/orders/addtional-pic.png") no-repeat;
                background-size:100% 100%;
                position: absolute;
                right:0 ;
                bottom:0;
            }
        }
    }
</style>