<template>
    <div class="home">
        <div class="top">
            <MyCarousel v-if="imgList.length > 0" :imgList="imgList"></MyCarousel>
            <div class="banner" v-else></div>
            
            <div class="money-container">
                <div class="letter-content">
                    <h2 class='lc-title'>Available Credit Upto</h2>
                    <p class="money">
                        ₹ <strong>{{ money }}</strong>
                    </p>
                </div>
                <p class="caption">
                    <i class="icon icon-shield"></i>
                    We will protect your data through 256 bit SSL.
                </p>
            </div>
        </div>
        <div class="process-container">
            <div class="pc-list">
                <div class="pc-element">
                    <i class="icon icon-info"></i>
                    <p class="pc-text">Submit info</p>
                </div>
                <div class="pc-element">
                    <i class="icon icon-progress"></i>
                </div>
                <div class="pc-element">
                    <i class="icon icon-credit"></i>
                    <p class="pc-text">Get Credit</p>
                </div>
                <div class="pc-element">
                    <i class="icon icon-progress"></i>
                </div>
                <div class="pc-element">
                    <i class="icon icon-ballet"></i>
                    <p class="pc-text">Get money</p>
                </div>
            </div>
        </div>
        <div class="btn-container">
            
            <router-link v-if='!logined' class="btn btn-submit" to="/login">
                Apply Now
            </router-link>
            <router-link v-else class="btn btn-submit" :to="`/verify/index/${product.id}`">
                Apply Now
            </router-link>
        </div>
    </div>
</template>
<style lang="less" scoped>
    @import "~assets/less/icon.less";
    .home{
        padding-top: 50px;
        .top{
            width: 680px;
            margin: 0 auto;
            border-radius: 15px;
            overflow: hidden;
            .banner{
                width: 680px;
                height: 310px;
                background:url("~assets/images/banner.png") no-repeat;
                background-size: 100% 100%;
            }
            .money-container{
                background:#fff;
                padding: 80px 30px 80px 30px;
                .letter-content{
                    width: 560px;
                    height: 210px;
                    padding-top: 30px;
                    margin-bottom: 30px;
                    margin:0 auto;
                    background:url("~assets/images/banner_1.png") no-repeat;
                    background-size: 100% 100%;
                    .lc-title{
                        text-align: center;
                        color:rgba(165, 155, 179, 1);
                        font-size: 30px;
                        margin-bottom: 30px;
                    }
                    .money{
                        color:rgba(252, 104, 0, 1);
                        font-size: 70px;
                        text-align: center;
                        strong{
                            font-size: 112px;
                        }
                    }
                }
                
                .caption{
                    color:#A59BB3;
                    font-size: 26px;
                    display: flex;
                    justify-content: center;
                    margin-top: 50px;
                    .icon{
                        width: 28px;
                        height: 32px;
                    }
                }
            }
        }
        .process-container{
            padding-top: 80px;
            .pc-list{
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 40px;
               .pc-element{
                    margin-right: 40px;
                    .icon{                     
                        margin: 0 auto 15px auto;
                    }
                    .pc-text{
                        text-align: center;
                        color:#A59BB3;
                        font-size: 24px;
                    }

               } 
            }
        }
        .btn-container{
            .btn-submit{    
                margin:100px auto;            
                display:block;
                background:#6D20D3;
                text-align: center;
                width: 670px;
                height: 96px;
                line-height: 96px;
                color:#fff;
                font-size: 36px;
                border:none;
            }
        }
    }
</style>
<script>

import MyCarousel from '../../carousel.vue'
export default {
    components:{
        MyCarousel
    },
    props:{
        imgList:{
            type:Array,
            default:()=>{}
        },
        product:{
            type:Object,
            default:()=>{}
        },
        money: String ,
        logined: String      
    },
    setup() {
        // money = ref('')
        // const money = props.money
    
        // return {
        //     money
        // }
    }    
}
</script>