<template>
    <div class="wrapper">
        <p class="guide">
            Step <em>{{ route.query.step }}</em> / {{ route.query.as }}
        </p>
        <h1 class="title">KYC Documents</h1>
        <Upload 
            :needShowList='obj["ada_front"]'
            title= "Aadhaar Front"
            invoke_type = '1'
            type_txt = 'ada_front'
            @set_value=set_value
        ></Upload>
        <!-- <Upload 
            :needShowList='obj["ada_back"]'
            title= "Aadhaar Back"
            invoke_type = '2'
            type_txt = 'ada_back'
            @set_value=set_value
        ></Upload> -->
        <Upload 
            :needShowList='obj["pan"]'
            title= "PAN"
            invoke_type = '3'
            type_txt = 'pan'
            @set_value=set_value
        ></Upload>
        <!-- <Upload 
            :needShowList='obj["hih"]'
            title= "HOST IN HAND"
            invoke_type = '4'
            type_txt = 'hih'
            @set_value=set_value
            
        ></Upload> -->
        <Upload
            v-if="config.living_type == 2" 
            :needShowList='obj["face"]'
            title= "Face Recognition"
            invoke_type = '5'
            type_txt = 'face'
            @set_value=set_value
            
        ></Upload>
            <!-- <div class="hih-caption">
                Please use Aadhaar card for handheld photo shooting
            </div>         -->
        <div class="placeholder"></div>
        <div class="btn-container">            
            <a class='btn-submit' @click="submit">CONFIRM TO NEXT</a>
        </div>
    </div>
</template>
<style lang="less" scoped>
    .btn-container{
        position: fixed;
        left:0;
        bottom: 0;        
        background:#fff;
        padding:40px;
        // padding:40px;
        .btn-submit{
            display: block;
            width: 670px;
            height: 96px;
            line-height: 96px;
            text-align: center;
            border-radius: 12px;
            background:#6D20D3;
            font-size: 32px;
            margin:0 auto;
            color:#fff;
        }
    }
    .placeholder{
        height: 176px;        
    }
    .hih-caption{
        color:#A59BB3;
        font-size:28px;
        margin-bottom: 30px;
        line-height: 1.5;
    }
    .wrapper{
        padding:40px;
        .guide{
            color:#A59BB3;
            font-size: 30px;
            em{
                color:#6D20D3;
                font-weight: 700;
            }
        }
        .title{
            color:#6D20D3;
            font-size: 40px;
            margin: 50px 0;  
        }
    }
</style>
<script>
import Upload from '@/components/business/kyc/upload.vue'
import { onMounted, reactive, getCurrentInstance,ref } from 'vue'
import { useRouter } from 'vue-router'
import { key_submit, getAuthInfo } from '@api/index'
import { useLoading } from 'vue-loading-overlay'
import { getActiveResult, faceConfig } from '@api/index';
export default {
    components:{
        Upload
    },
    setup() {
        const $loading = useLoading()
        const clicked = ref(false)
        const router = useRouter()
        const route = router.currentRoute
        const proxy = getCurrentInstance().proxy
        const obj = reactive({
            "ada_front": [{"keys":"Name", "value": "", "value_keys":"name"},{"keys":"Adahaar No.", "value": "", "value_keys":"idNumber"}],
            "ada_back": [],
            "pan": [{"keys":"PAN NO", "value": "", "value_keys":"panNo"}],
            "hih": [],
            "face": []
        })  
        const config = reactive({
            living_type:null
        })      
        onMounted(async () => {
            document.body.style.background = '#F8F8F8'
            const data = await faceConfig()            
            config.living_type = data.data['living-config']
            if (config.living_type == 1) {
                getActiveResult()
            }
            
        })
        const validate = () => {
            let res = true
            if(obj['ada_front'][0]['value'] == '' | obj['ada_front'][1]['value'] == '') {
                proxy.$toast.show('Please Upload Aadhaar front')
                res =false
            } 
            // else if (obj['ada_back'].length == 0) {
            //     proxy.$toast.show('Please Upload Aadhaar Back ')
            //     res =false
                
            // }
             else if ( obj['pan'][0]['value'] == '' ){
                proxy.$toast.show('Please Upload PAN ')
                res =false
            } 
            // else if (obj['hih'].length == 0 ) {
            //     proxy.$toast.show('Please Upload HOST IN HAND ')
            //     res =false
            // } 
            else if (obj['face'].length == 0 && config.living_type == 2) {
                proxy.$toast.show('Please Upload Face Recognition Image ')
                res =false
            }
            return res
        }
        const submit = async () => {
            if(!validate()) return
            if(clicked.value)return 
            clicked.value = true
            const loader = $loading.show({
                container: null
            })
            let data = await key_submit({
                realName:obj['ada_front'][0]['value'],
                idNo:obj['ada_front'][1]['value'],
                panNo:obj['pan'][0]['value']
            })
            loader.hide()
            clicked.value = false
            if(data.code == 0) {
                getAuthInfo().then((data)=>{
                    loader.hide()
                    if(data.code == 0) {
                        if (data.data.basic === 0) {
                            router.replace({
                                path:'/verify/base'
                            })                
                        } else if (data.data.contact === 0) {
                            router.replace({
                                path:'/verify/contact'
                            })
                        } else {
                            router.replace({
                                path:'/'
                            })
                        }
                    } else {
                        proxy.$toast.show(data.msg)
                    }
                })                            
            } else {
                proxy.$toast.show(data.msg)
            }
        }

        const set_value = (key, v) => {
            obj[key] = v
        }


        return {
            obj,
            set_value,
            submit,
            validate,
            config,
            route
        }
        
    }
}
</script>