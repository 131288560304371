<template>
    <div v-show="state == 1" class="code-container" ref='secondRef'>
        <div class="intro">
            Enter the OTP sent to your mobile phone <br>
            +91 - {{ phone }}
        </div>
        <div class="operation">            
            <div class="op-list">
                <div class="op-element" v-for="(item,ind) in phones" :class="{'active': index == ind}" :key=ind>
                    <div class="input-container">
                        <input :ref='`code${ind}`' type="tel" v-model="phones[ind]" @input="codeInput($event, ind)" @focus="inputFocus(ind)" @keyup.delete="keydownDelete">
                    </div>
                </div>                
            </div>
            <div class="countdonw">
                <span v-show='secs > 0'>Resend after {{ secs }}s</span>                                
            </div>
        </div>
    </div>
</template>
<style lang="less" scoped>
    .back{
        color:#6D20D3;
    }
    .code-container{
        padding:45px;
        .intro{
            text-align: center;
            color:#A59BB3;
            font-size: 30px;
            line-height: 1.5;
        }
        .operation{
            margin-top: 30px;
            .op-list{
                display: flex;
                justify-content: center;
                .op-element{                    
                    margin-right: 20px;
                    &.active{
                      .input-container{
                        border-color:#6D20D3;
                      }  
                    }
                    .input-container{
                        width: 74px;
                        height: 74px;
                        border:2px solid #000;
                        border-radius: 15px;
                        padding:10px 10px 18px 10px;
                        input{
                            display:block;
                            width: 40px;
                            height: 100%;
                            color:#051644;
                            border:none;
                            font-size: 32px;
                            margin:0 auto;
                            text-align: center;
                        }
                    }
                }
            }
            .countdonw{
                color:#A59BB3;
                font-size: 32px;
                text-align: center;
                margin-top: 30px;
            }
        }
    }
</style>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
    props:{
            state: Number,            
            phone: String
    },
    data () {        
        return {
            phones:['','','',''],
            index: 0,
            timeout:0,
            secs:0
        }        
    },
    methods:{
        startCountDonw () {
            clearInterval(this.timeout)
            this.secs = 60
            this.timeout = setInterval(()=>{
                this.secs -= 1
                if(this.secs == 0) {
                    clearInterval(this.timeout)
                }
            },1000)
        },
        keydownDelete (e) {
            e.preventDefault();
                       
            // if(e.target.value == '') {
            if(this.index >= 1) {
                this.index -= 1                
                this.$refs[`code${this.index}`][0].focus()    
            }                
            // }
        },
        inputFocus (ind) {
            this.index = ind
        },
        sendCode () {
            this.$emit("sendCode")
        },        
        resetCode () {
            this.phones = ['','','','']
        },
        codeInput (e, ind) { 
            let v = e.target.value           
            if(/\D+/.test(v)) {
                this.phones[ind] = ''
            } else {                
                if (v.length > 0) {
                    this.phones[ind] = e.data
                } else {
                    this.phones[ind] = v[0]
                }
                
                if(this.index < 3 & v!= '') {
                    this.$refs[`code${this.index}`][0].blur()
                    this.index += 1
                    this.$refs[`code${this.index}`][0].focus()
                }
            }
            this.$emit('setCode', this.phones.join(''))
        }
    }
})
</script>