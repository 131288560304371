import store from '@store/index.js'
const $toast = {
    show (txt) {
        store.dispatch('setToastTxt', txt)
        store.dispatch('showToast')
    },
    hide () {
        store.dispatch('closeToast')
    }
}
export default $toast