<template>
    <div></div>
</template>
<script>
import { getAuthInfo, gps } from '@api/index'
import { onMounted , getCurrentInstance } from 'vue'
import {useRouter} from 'vue-router'
import { useLoading } from 'vue-loading-overlay'
export default {
    setup () {
        const router = useRouter()
        const route = router.currentRoute
        const loading = useLoading()
        const proxy = getCurrentInstance().proxy
        onMounted(async ()=>{
            const loader = loading.show({
                container: null
            })
            const  reflect = {
                "basic":"base",
                "work" : "work",
                "kyc": "kyc",                
                "contact":"contact"
            }
            if(navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position)=>{                    
                    gps({
                        "gps": {
                            "address": "",
                            "latitude": position.coords.latitude,
                            "longitude": position.coords.longitude
                        },
                        "deviceInfo":{
                            'user_agent': navigator.userAgent
                        }
                    })
                }, ()=>{
                    gps({
                        "gps": {
                            "address": "",
                            "latitude": "",
                            "longitude": "",                            
                        },
                        "deviceInfo":{
                            'user_agent': navigator.userAgent
                        }
                    })
                })
            }                             
            const data = await getAuthInfo({
                productId:route.value.params.pid
            })
            loader.hide()
            if(data.code == 0){
                let verifyArray = data.data.userAuthConfigVoList
                loader.hide()
                let compelete = true
                for(let i = 0; i< verifyArray.length;i++){
                    let target = verifyArray[i]
                    let ky = target['title'].toLowerCase()
                    let rl = reflect[ky]
                    if(data.data[ky] == 0){
                        console.log(1)
                        router.push({
                            "path":`/verify/${rl}`,
                            "query":{
                                "pid": route.value.params.pid,
                                "step": i + 1,
                                "as": verifyArray.length
                            }
                        })
                        compelete = false
                        break
                    }
                }
                if(compelete) {
                    router.replace({
                        "path":`/product/${route.value.params.pid}/detail`
                    })
                }
            } else {
                loader.hide()
                proxy.$toast.show(data.msg)
            }
        })
    }
}
</script>