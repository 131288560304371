<template>
    <div>
        <Carousel v-if="imgList" :autoplay="2000" :wrap-around="true">
            <Slide v-for="(item,ind) in imgList" :key="ind" @click="jumpTo(item.link)">
                <div class="carousel__item">                
                    <!-- <router-link> -->  
                        <img :src="item.cover" alt="">                        
                    <!-- </router-link> -->
                </div>
            </Slide>
            <template #addons>
                <!-- <Navigation /> -->
                <Pagination />
            </template>
        </Carousel>
        <div class="banner" v-else></div>
    </div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Pagination, Slide } from 'vue3-carousel'
export default {
    // name:'Carousel',
    components: {
        Carousel, 
        Slide,
        Pagination,
        // Navigation
    },
    props:{
        imgList:{
            type:Array            
        }
    },
    methods : {
        jumpTo (url) {
            window.location.href=  url
        }
    }    
}
</script>
<style>
    .carousel__item {
        min-height: 200px;
        width: 100%;
        background-color: var(--vc-clr-primary);
        color:  var(--vc-clr-white);
        font-size: 20px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        height:300px;
        border-radius: 15px;
        overflow: hidden;
        
    }
    .carousel__item img{
        width: 100%;
        height: 100%;
    }


    .carousel__slide {
    padding: 10px;
    }

    .carousel__prev,
    .carousel__next {
    box-sizing: content-box;
    border: 5px solid white;
    }
</style>