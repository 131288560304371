<template>
    <div class="contact-element" :class="key_name">
        <div class="img-container"></div>
        <!-- <div class="ce-title" v-text="title"></div> -->
        <div class="ce-content">
            <div class="element-wrapper" @click="showMenu" :class="{'error': data['relation'].error}">
                <div class="element-title">
                    <h3>Relationship</h3>                    
                </div>
                <ul class="choice-container">
                    <li class="choice-element" :class="{'active': selected_ind === ind}" v-for="(item, ind) in choices" :key="ind" @click="selected($event,ind)" v-text="item.dictLabel"></li>
                </ul>
            </div>
            <div class="element-wrapper" :class="{'is_error': data['name'].error}">
                    <!-- <div class="element-title">
                        <h3>Name</h3>
                        <i class="icon" :class="{'icon-exclaim':data['name'].error }"></i>                
                    </div> -->
                <div class="element-content">
                    <input type="text" @input="textInput"  @focus="bootomFocus" placeholder="Please Input Name">
                </div>
            </div>
            <div class="element-wrapper" :class="{'is_error': data['mobile'].error}">
                <!-- <div class="element-title">
                        <h3>Telephone</h3>
                        <i class="icon" :class="{'icon-exclaim':data['mobile'].error }"></i>                
                    </div> -->
                <div class="element-content">
                    <span>91</span>
                    <input type="tel" @input="textPhone"  @focus="bootomFocus" placeholder="Please Input Phone Number">
                </div>
            </div>
        </div>    
    </div>
</template>
<style lang="less" scoped>
.contact-element{
    // width: 654px;
    border-radius: 15px;
    overflow: hidden;
    background: #fff;;
    margin-bottom: 25px;
    position: relative;
    &.one{
        .img-container{
            position: absolute;
            top: 10px;
            right: 10px;
            width: 140px;
            height: 120px;
            background:url("~assets/images/c1.png") no-repeat;
            background-size:100%;
            z-index:2
        }
    }
    &.two{
        .img-container{
            position: absolute;
            top: 10px;
            right: 10px;
            width: 140px;
            height: 120px;
            background:url("~assets/images/c2.png") no-repeat;
            background-size:100%;
            z-index:2
        }
    }
    &.third{
        .img-container{
            position: absolute;
            top: 10px;
            right: 10px;
            width: 140px;
            height: 120px;
            background:url("~assets/images/c3.png") no-repeat;
            background-size:100%;
            z-index:2
        }
    }
    .ce-title{
       height:34px;
       line-height: 34px;
       color:#fff;
       font-size: 32px;
       font-weight: 700;
       padding:25px 40px;
       background:#9E8FE7;
    }
    div.ce-content{
        padding:20px 40px;
    }
}
.element-wrapper{
    padding-bottom: 30px;
    border-bottom: 2px solid #EFEEF1;
    position: relative;
    margin-bottom: 30px;
    &.is_error{
        border-bottom:  2px solid #C5340B;       
    }
    .element-title{
        
        color:#73698F;
        font-size: 26px;  
        overflow: hidden;
        padding:15px 0;      
        h3{
            float:left;
        }
        .icon-exclaim{
            float:left;
            margin-left: 20px;
        }
        .icon-arrow-down{
            float:right
        }
    }
    .element-content-default{
        margin-top: 30px;
        color:#D0CDDA;
        font-size: 36px;
        height:40px;
        line-height: 40px;
    }
    .element-content{
        margin-top: 30px;
        color:#000;
        font-size: 36px;
        height:40px;
        line-height: 40px;
        display: flex;
        align-items: center;
    }
    .element-content{
        margin-top: 30px;
        color:#000;
        font-size: 36px;
        height:40px;
        line-height: 40px;
        font-size: 32px;
        span{
            margin-right: 20px;
        }
        input{
            display:block;
            width: 100%;
            font-size: 32px;
            border:none;
            &::placeholder{
                color:#D0CDDA;
            }
        }
    }
    .choice-container{  
        // display: none;      
        // position: absolute;
        // width: 505px;
        background:#fff; 
        z-index:99; 
        overflow:hidden;
        margin-top: 20px;
        .choice-element{
            float:left;
            margin-right: 25px;
            border: 2px solid transparent;
            padding:20px;
            color:#999;
            font-size: 32px;
            background:#e0e0e0;
            border-radius: 10px;
            &.active{
                color:#9E8FE7;
                border: 2px solid #9E8FE7;
                background: #fff;
            }
        }
    }
}
</style>
<script>
export default {
    props:{
        title:{
            type:String,
            default:''
        },
        is_show:{
            type:Boolean,
            default:false
        },
        key_name:{
            type:String,
            default: ''
        },
        choices:{
            type: Object
        },
        data:{
            type: Object
        }
    },
    data () {
        return {
            selected_ind:null
        }
    },
    methods:{
        showMenu () {
            this.$emit("setValue", this.key_name, 'is_show', true)
        }
        ,
        selected (e, ind) {
            e.stopPropagation();        
            this.selected_ind = ind
            this.$emit("setValue", this.key_name, 'relation', ind)
            this.$emit("setValue", this.key_name, 'is_show', false)            
        },
        textInput (e) {
            let v = e.target.value
            if((/([^a-z'.])+/.test(e.target.value))) {
                e.target.value = v.substring(0,v.length - 1)
            } else {
                this.$emit("setValue",this.key_name,'name', e.target.value )
            }
        },
        textPhone (e) {
            let v = e.target.value
            if(/\D+/.test(v)) {
                e.target.value = v.substring(0, v.length - 1)
            } else if (v.length > 10) {
                e.target.value = v.substring(0, v.length - 1)
            } else {
                this.$emit("setValue",this.key_name,'mobile', e.target.value )
            }

        },
        bootomFocus (e) {
            setTimeout(()=>{                
                e.target.scrollIntoView({ block: "center" })
                // e.target.scrollIntoViewIfNeeded()            
            },300)            
        }
    }
}
</script>