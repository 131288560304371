import { createApp } from 'vue'
import router from '../routes/index'
import store from '../store/index'
import 'vue-loading-overlay/dist/vue-loading.css'
import VueLoading from 'vue-loading-overlay'
import VueClipboard from 'vue-clipboard2'
import "./assets/reset.css"
import toast from '@/components/common/toast.js'
import App from '@/App.vue'
import Vue3Marquee from 'vue3-marquee'
let app = createApp(App)
app.config.globalProperties.$toast = toast
app.use(router).use(store).use(VueLoading).use(VueClipboard).use(Vue3Marquee)
app.provide('$toast', toast)
app.mount('#app')
