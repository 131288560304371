import Cookies from 'js-cookie'

const TokenKey = 'token'

export function getToken() {
  return Cookies.get(TokenKey)
}
export function getTokenFromStorage() {
  return window.localStorage.getItem("token")
}
export function setToken(token) {
  return Cookies.set(TokenKey, token)
}
export function setTokenFromStorage(token) {
  return window.localStorage.setItem("token", token)
}
export function removeTokenFromStorage() {
  return window.localStorage.removeItem("token")
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
export function getOs () {
  const ua = window.navigator.userAgent
  if(ua.match(/iPhone/i)){
    return "ios-h5"
  } else if (ua.match(/Mac OS X/i)) {
    return "ios-h5"
  } else {
    return "android-h5"
  }
}