<template>
    <div class="dialog-wrapper" v-show="$store.state.toast_state">
        <div class="dialog">
            <div class="dialog-title">
                <h3 class='dt-txt'>Info</h3>
            </div>
            <div class="dialog-content">
                <p class="toast-text" v-text="$store.state.toast_txt"></p>
            </div>
            <div class="dialog-foot">
                <a href="javascript:void(0);" class="btn-close" @click="$store.dispatch('closeToast')">OK</a>
            </div>
        </div>
    </div>
</template>
<style lang="less">
    .dialog-wrapper{
        position: fixed;
        top:0;
        right:0;
        left:0;
        bottom:0;
        width: 100%;
        height: 100%;
        background:rgba(0,0,0,0.9);
        z-index: 10;
        .dialog{
            position:absolute;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%);
            padding:40px;
            background:#fff;
            width: 590px;
            border-radius: 15px;
            .dialog-title{
                text-align:center;
                font-size: 36px;
                font-weight: 700;
                color:#000
            }
            .dialog-content{
                margin:50px 0;
                .toast-text{
                    color:#A59BB3;
                    font-size: 32px;
                    line-height: 1.5;
                }
            }
        }
        .btn-close{
            display:block;
            background:#6D20D3;
            text-align: center;
            width: 570px;
            height: 96px;
            line-height: 96px;
            color:#fff;
            font-size: 36px;
            border:none;
        }
    }
</style>