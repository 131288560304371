<template>
    <div class="wrapper">
        <p class="guide">
            Step <em>1</em> / 3
        </p>
        <h1 class="title">KYC Documents</h1>
        <div class="upload-wrapper">
            <div class="uw-title">Face Recognition</div>
            <slot />
            <div class="upload-operation">
                <a :href="jump_url">
                    <div class="no-img">
                        <i class="icon icon-add"></i>
                    </div>
                </a>
            </div>        
            <input ref='uploadInstance' type="file" id="face_recongnition" @change="change" accept="image/*" style='display:none'>
        </div>
        <div class="placeholder"></div>
        <div class="btn-container">            
            <a class='btn-submit' @click="submit">CONFIRM TO NEXT</a>
        </div>
    </div>    
</template>
<script>
import { ref, onMounted, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { getActiveUrl,faceConfig } from '@api/index'
import { useLoading } from 'vue-loading-overlay'
export default {
    props:{
        title:{
            type:String
        },
        needShowList:{
            type:Object
        },
        invoke_type:{
            type:String
        },
        type_txt:{
            type:String
        }
    },
    setup(props) {      
        const router = useRouter()
        const isShow = ref(false)        
        const uploadInstance = ref()
        const jump_url = ref('')
        const $loader = useLoading()
        const proxy = getCurrentInstance().proxy
        const change = async (e) => {
            if (e.target.files[0].type.indexOf('image') > -1) {
                let file = e.target.files[0]
                const size = file.size / 1024 / 1024
                if(size > 2) {
                    return 
                } else {
                    let formData = new FormData()
                    formData.append('type', props.invoke_type)
                    formData.append('image', e.target.files[0],  e.target.files[0].name)
                    formData.append('notNeed', true)                                        
                }

            }
        }
        onMounted(async ()=>{
            const config = await faceConfig()
            if(config.data['living-config'] == 2) {
                router.replace({
                    path:"/verify/kyc"
                })
            }
            const loader = $loader.show({
                container: null
            })
            getActiveUrl().then((data)=>{
                loader.hide()
                jump_url.value = data.data.url
            })
        })
        const uploadClick = () => {
            uploadInstance.value.click()
        }
        const submit = () => {
            proxy.$toast.show('Please Face Recognition')
        }
        return {
            change,
            uploadClick,
            jump_url,
            isShow,
            uploadInstance,
            submit
        }
    }
}
</script>
<style lang="less" scoped>
@import "assets/less/icon.less";
.wrapper{
        padding:40px;
        .guide{
            color:#A59BB3;
            font-size: 30px;
            em{
                color:#6D20D3;
            font-weight: 700;
            }
        }
        .title{
            color:#6D20D3;
            font-size: 40px;
            margin: 50px 0;  
        }
    }
    .btn-container{
        position: fixed;
        left:0;
        bottom: 0;        
        background:#fff;
        padding:40px;
        // padding:40px;
        .btn-submit{
            display: block;
            width: 670px;
            height: 96px;
            line-height: 96px;
            text-align: center;
            border-radius: 12px;
            background:#6D20D3;
            font-size: 32px;
            margin:0 auto;
            color:#fff;
        }
    }
    .placeholder{
        height: 176px;        
    }
    .upload-wrapper{
        width: 574px;
        margin:0 auto 50px auto;
        border-radius: 15px;
        background:#fff;
        padding:25px 40px;
        .uw-title{
            font-size: 32px;
            color:#000;
            margin-bottom: 30px;
        }
        .upload-operation{
            .no-img{
                width: 276px;
                height:164px;
                background:#F5F3FF;
                display:flex;
                justify-content: center;
                align-items: center;
                border-radius: 15px;
            }
            .have-img{
                width: 276px;
                height:114px;
                background:#6D20D3;
                padding-top: 50px;                                
                border-radius: 15px;
                .img-cap{
                    color:#fff;
                    text-align: center;
                    margin-top: 10px;
                    font-size: 24px;
                }
            }
        }
        .img-info{
            margin-top: 30px;
            .img-info-ele{
                padding:25px 0;
                border-bottom:2px solid #EFEEF1;
                .img-info-title{
                    color:#73698F;
                    font-size: 26px;
                }
                .img-info-content{
                    color:#000;
                    font-size: 30px;
                    margin-top: 30px;
                }
            }
        }
    }
</style>