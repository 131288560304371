<template>
    <div class="wrapper">
        <div class="banner"></div>
        <h2 class="title">
            Auditing...
        </h2>
        <p class="caption">
            <!-- It may take some time, depend on your bank. We will notify you via SMS. -->
        </p>
        <div class="btn-container">
            <a class="btn-submit" @click.prevent="update">Update Status</a>
        </div>
    </div>
</template>
<script>
import { orderDetail } from "@api/index";
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useLoading } from 'vue-loading-overlay'
export default {
    setup () {
        const router = useRouter()
        const route = router.currentRoute.value
        const loading = useLoading()
        const clicked = ref(false)
        const update = () => {    
            if(clicked.value)return
            clicked.value = true
            const loader = loading.show({
                container: null
            })
            orderDetail({
                orderId:route.params.id
            }).then((res)=>{
                loader.hide()
                clicked.value = false
                if(res.code == 0) {                    
                    if(res.data.orderState == 10) {
                        router.replace({
                            path:`/orders/${res.data.id}/confirm`
                        })
                    } else if (res.data.orderState == 15) {
                        router.replace({
                            path:'/orders'
                        })
                    }
                }
            })
        }
        return {
            update
        }
    }
}
</script>
<style lang="less" scoped>
    .wrapper{
        padding-top: 100px;
        .banner{
            width: 406px;
            height: 458px;
            background:url("~assets/images/orders/audit-banner.png") no-repeat;
            background-size: 100% 100%;
            margin: 0 auto
        }
        .title{
            text-align: center;
            font-size: 44px;
            color:#000;
            margin: 60px 0 30px 0;
        }
        .caption{
            padding:30px 50px;
            color:#A59BB3;
            font-size: 32px;
            line-height: 1.5;
        }
        .btn-container {
            margin-top: 100px;            
            width: 100%;
            height: 96px;
            .btn-submit {
            display: block;
            width: 670px;
            height: 96px;
            line-height: 96px;
            text-align: center;
            border-radius: 12px;
            background: #6d20d3;
            font-size: 32px;
            margin: 0 auto;
            color: #fff;
            &.red{
                background:#C5340B;
            }
            }
        }
    }
</style>