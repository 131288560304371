<template>
    <div class="wrapper">
        <ul class="list">
            <li class="element" v-for="(element,ind) in obj.list" :key="ind" @click.prevent="toggle(ind)">
                <div class="title">
                    <span v-text="element.title"></span>
                    <i class="icon icon-arrow-down" :class="{'icon-arrow-up':element.show}"></i>
                </div>
                <div class="content" v-text="element.content" v-show="element.show"></div>
            </li>
        </ul>
    </div>
</template>
<style lang="less" scoped>
@import "assets/less/icon.less";
    .wrapper{
        padding:30px;
        .list{
            .element{
                padding:30px 0;
                .title{
                    display:flex;
                    align-items: center;
                    justify-content: space-around;
                    padding-bottom: 30px;
                    border-bottom:2px solid #e7e7e7;
                    span{
                        font-size: 36px;
                        font-weight: 700;
                        line-height: 1.5;
                        color:#333;
                        flex: 1 1 auto
                    }
                    .icon{
                        flex: 0 0 auto;
                    }
                }
                .content{
                    margin-top: 30px;
                    padding: 30px;
                    background:#F9F9FB;
                    font-size: 30px;
                    line-height: 1.5;
                    color:#A59BB3;
                    border-radius: 15px;
                }
            }
        }
    }
</style>
<script>
import { onMounted,reactive } from 'vue';
import { qa } from '@api/index.js'
export default {
    setup () {
        const obj = reactive({
            list:[]
        })
        
        const toggle = (ind) => {
            obj.list[ind].show = !obj.list[ind].show
        }
        onMounted(()=>{
            qa().then(res => {
                if(res.code == 0) {
                    obj.list = res.data.map((element,ind) => {
                        if(ind == 0) {
                            element.show = true
                        } else {
                            element.show = false
                        }
                        return element
                    })
                }
            })
        })
        return {
            obj,
            toggle
        }
    }
}
</script>